import { isSignalPath } from 'util/url';
import { useEffect } from 'react';
import { sendNativeMessage } from 'hooks/useNativeListener';
import styled from 'styled-components';
import { getCurrentLanguage } from 'i18n/language';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsTrainingMode } from 'store/trainingMode/selectors';
import RoutesContainer from '../Routes';
import Navbar from './Navbar/Navbar';
import Header from './Header/Header';
import SubHeader from './SubHeader/SubHeader';
import SmartBannerContainer from './SmartBanner/SmartBannerContainer';
import Footer from './Footer/Footer';

const Layout = () => {
  useEffect(() => {
    sendNativeMessage({
      type: 'languageChanged',
      payload: getCurrentLanguage(),
    });
  }, []);

  const location = useLocation();
  const isTrainingMode = useSelector(selectIsTrainingMode);

  const isSignalHeadlessView = isSignalPath(location) ?? false;
  return (
    <TrainingModeWrapper $isTrainingMode={isTrainingMode}>
      {!isSignalHeadlessView ? (
        <Navbar>
          <SmartBannerContainer />
          <Header />
          <SubHeader />
          <MainContent id="main-content">
            <Toaster position="top-center" />
            <RoutesContainer />
          </MainContent>
          <Footer />
        </Navbar>
      ) : (
        <MainContent id="main-content">
          <Toaster position="top-center" />
          <RoutesContainer />
        </MainContent>
      )}
    </TrainingModeWrapper>
  );
};

const TrainingModeWrapper = styled.div`
  ${({ $isTrainingMode, theme }) =>
    $isTrainingMode &&
    `
    height: 100%;
    border: 8px solid ${theme.primaryPalette.teal};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: auto;
  `}
`;

const MainContent = styled.div`
  width: 100%;
  height: auto;
  max-width: 1152px;
  flex-grow: 1;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export default Layout;
