import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox, Typography } from 'cfa-react-components';

const CheckboxFilterSection = ({
  title,
  labels,
  onChange,
  'data-testid': dataTestId,
  className,
}) => {
  const handleOnChange = label => {
    onChange(label);
  };

  return (
    <div className={className} data-testid={dataTestId}>
      <CheckboxSectionTitle fontWeight="bold" variant="body1">
        {title}
      </CheckboxSectionTitle>
      <CheckboxContainer>
        {Object.keys(labels).map(label => (
          <Checkbox
            checked={labels[label].value}
            key={label}
            label={labels[label].translationString}
            onChange={() => handleOnChange(label)}
          />
        ))}
      </CheckboxContainer>
    </div>
  );
};

const CheckboxSectionTitle = styled(Typography)`
  margin-bottom: 8px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  margin-left: 4px;
`;

CheckboxFilterSection.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.objectOf(
    PropTypes.shape({
      translationString: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
};

CheckboxFilterSection.defaultProps = {
  className: '',
  'data-testid': 'CheckboxFilterSection',
};

export default CheckboxFilterSection;
