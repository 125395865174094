import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LoadingIndicator } from 'cfa-react-components';

function LoadingOverlay({ className, isOpen, children }) {
  return (
    <StyledOverlay
      className={className}
      data-testid="LoadingOverlay"
      isOpen={isOpen}
    >
      <LoadingIndicator size="lg" variant="page" />
      {children}
    </StyledOverlay>
  );
}

const StyledOverlay = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: auto;
  background-color: ${props => props.theme.primaryPalette.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
`;

LoadingOverlay.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

LoadingOverlay.defaultProps = {
  className: '',
  isOpen: false,
  children: null,
};

export default LoadingOverlay;
