import PropTypes from 'prop-types';
import Document from './Document';

const SubtitleDocuments = ({
  subtitle,
  openDocumentInNewTab,
  contentToRenderAtDocumentEnd,
  userIsAdmin,
  showAdminFunctionality,
  handleDocumentVisibilityToggleForSubtitle,
  handleDocumentDelete,
  disableSixDotMenuHandles,
}) => {
  const documentVisibilityCheck = (document, documentSubtitle) => {
    const { visibleDocuments: visibleDocumentIds, documents: allDocumentIds } =
      documentSubtitle;
    const visibleDocumentIndex = visibleDocumentIds?.indexOf(document?.id);
    const hiddenDocumentIndex = allDocumentIds?.indexOf(document?.id);
    if (visibleDocumentIndex >= 0) {
      return true;
    }
    if (visibleDocumentIndex < 0 && hiddenDocumentIndex >= 0) {
      return false;
    }
  };
  return (
    <>
      {subtitle?.documents?.map((document, documentIndex) => {
        const documentIsCurrentlyVisible = documentVisibilityCheck(
          document,
          subtitle,
        );
        return (
          <Document
            disableSixDotMenuHandles={disableSixDotMenuHandles}
            document={document}
            documentIsCurrentlyVisible={documentIsCurrentlyVisible}
            handleDocumentDeleteButton={() => {
              handleDocumentDelete(
                document,
                documentIsCurrentlyVisible,
                documentIndex,
              );
            }}
            handleDocumentVisibilityToggle={() => {
              handleDocumentVisibilityToggleForSubtitle(
                document,
                documentIsCurrentlyVisible,
              );
            }}
            index={documentIndex}
            inSectionPreview={true}
            key={documentIndex}
            openInNewTab={openDocumentInNewTab}
            RenderDocumentEndContent={contentToRenderAtDocumentEnd}
            showAdminFunctionality={showAdminFunctionality}
            userIsAdmin={userIsAdmin}
          />
        );
      })}
    </>
  );
};

SubtitleDocuments.propTypes = {
  subtitle: PropTypes.object,
  openDocumentInNewTab: PropTypes.bool,
  contentToRenderAtDocumentEnd: PropTypes.any,
  userIsAdmin: PropTypes.bool,
  showAdminFunctionality: PropTypes.bool,
  handleDocumentVisibilityToggleForSubtitle: PropTypes.func,
  handleDocumentDelete: PropTypes.func,
  disableSixDotMenuHandles: PropTypes.bool,
};

SubtitleDocuments.defaultProps = {
  subtitle: { documents: [], visibleDocuments: [] },
  openDocumentInNewTab: false,
  contentToRenderAtDocumentEnd: null,
  userIsAdmin: false,
  showAdminFunctionality: false,
  handleDocumentVisibilityToggleForSubtitle: () => {},
  handleDocumentDelete: () => {},
  disableSixDotMenuHandles: false,
};

export default SubtitleDocuments;
