import Constants from 'constants/index';
import { createBrowserHistory } from 'history';
// import { matchPath } from 'react-router-dom';
// import store from '../store';

const history = createBrowserHistory({
  basename: Constants.BASE_PATH,
});

history.listen(location => {
  if (Constants.ROUTE_LOGGING_ACTIVE) {
    console.log('router path changed:', location.pathname);
  }

  /*  const homePath = !matchPath(location.pathname, {
    path: Constants.BASE_PATH,
  });*/
});

export default history;
