import { getNameFromLanguage } from 'util/language';
import Constants from 'constants';
import styled from 'styled-components';
import * as Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from '@bugsnag/browser';
import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Card,
  CardContent,
  Icon,
  RadioButton,
  RadioGroup,
  Typography,
  useBreakpoints,
  useMediaQuery,
  ModalFooter,
} from 'cfa-react-components';
import GenericError from 'sharedComponents/app/GenericError';
import { IconCircleCheckFilled, IconArrowLeft } from '@tabler/icons-react';
import { formatBugsnagErrorMessage } from 'bugsnag';
import {
  useGetQuizQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';

const QuizPreviewCard = ({ quiz }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));

  if (!quiz) {
    return <GenericError />;
  }
  if (!quiz.questions?.length) {
    return (
      <Card elevation={2}>
        <CardContent>
          <StyledNoQuestions>
            {t('TrainingPlans.previewQuiz.noQuestions')}
          </StyledNoQuestions>
          <StyledNoQuestionsInstructions>
            {t('TrainingPlans.previewQuiz.noQuestionsHelpText')}
          </StyledNoQuestionsInstructions>
        </CardContent>
      </Card>
    );
  }
  return quiz?.questions?.map((questionData, questionIndex) => (
    <StyledQuizQuestionCard
      $index={questionIndex}
      elevation={2}
      key={questionIndex}
    >
      <CardContent>
        <StyledQuestionName>
          {getNameFromLanguage(questionData.question)}
        </StyledQuestionName>
        {questionData?.answers &&
          questionData.answers.map((answerData, answerIndex) => (
            <StyledAnswersWrapper key={answerIndex}>
              <RadioGroup orientation="vertical">
                <StyledAnswerWrapper $correctAnswer={answerData.correct}>
                  <StyledRadioButton
                    $isCorrect={answerData.correct}
                    disabled={!answerData.correct}
                    label={getNameFromLanguage(answerData.answer)}
                    value={answerData.correct}
                  />
                  {answerData.correct && (
                    <StyledCorrectText $isSmAndUp={isSmAndUp}>
                      {t('TrainingPlans.previewQuiz.correctAnswer')}
                      <StyledCircleCheckIcon icon={IconCircleCheckFilled} />
                    </StyledCorrectText>
                  )}
                </StyledAnswerWrapper>
              </RadioGroup>
            </StyledAnswersWrapper>
          ))}
      </CardContent>
    </StyledQuizQuestionCard>
  ));
};

const QuizPreviewPopUp = ({
  handleQuizBackButton,
  isOpen,
  onClose,
  quizToPreview,
  trainingPlan,
  sectionId,
  refetch,
}) => {
  const { t } = useTranslation();
  const { data: quiz, isFetching: isFetchingQuiz } = useGetQuizQuery(
    quizToPreview?.id,
    {
      skip: !quizToPreview?.id,
    },
  );
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();

  const stepType = Constants.STEP_TYPES.QUIZ;

  const withinSection = !!sectionId?.length;

  const quizName = quizToPreview?.name
    ? getNameFromLanguage(quizToPreview?.name)
    : '';

  const getQuizStep = () => ({
    id: uuidv4(),
    name: {
      en: getNameFromLanguage(
        quizToPreview?.name,
        Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE,
      ),
      es: getNameFromLanguage(
        quizToPreview?.name,
        Constants.LANGUAGE.SPANISH_LANGUAGE_CODE,
      ),
    },
    type: stepType,
    quizId: quizToPreview?.typeFormId,
    reference: null,
    note: null,
    urls: [],
    available: true,
  });

  const updatePlanBasedOnPreviewAndRefetchThenRunOnClose = payload => {
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const buildPayloadForPlanUpdateWithQuiz = ({ quizStep, newSteps }) => ({
    locations: trainingPlan.locations,
    checklist: withinSection
      ? {
          ...trainingPlan,
          sections: [
            ...trainingPlan.sections.map(section =>
              section.id === sectionId
                ? {
                    ...section,
                    steps: [...section.steps, quizStep],
                  }
                : section,
            ),
          ],
        }
      : {
          ...trainingPlan,
          steps: newSteps,
        },
  });

  const onAddQuizFromPreview = () => {
    const quizStep = getQuizStep(quizToPreview);
    const newSteps = [...trainingPlan.steps, quizStep];
    const payload = buildPayloadForPlanUpdateWithQuiz({ quizStep, newSteps });
    updatePlanBasedOnPreviewAndRefetchThenRunOnClose(payload);
  };

  return (
    <>
      <LoadingOverlay isOpen={isFetchingQuiz} />
      <StyledModal
        onClose={onClose}
        scrollMode="modal-body"
        show={isOpen}
        size="lg"
      >
        <StyledModalHeader>
          <StyledHeaderContentWrapper>
            <BackButtonWrapper>
              <StyledBackButton
                color="secondary"
                onClick={handleQuizBackButton}
                size="sm"
                variant="text"
              >
                <StyledIconArrowLeft />
              </StyledBackButton>
            </BackButtonWrapper>
            <div>
              <StyledOverlineTypography variant="overline1">
                {t('TrainingPlans.preview')}
              </StyledOverlineTypography>
              {quizName}
            </div>
            <StyledEmptyDiv />
          </StyledHeaderContentWrapper>
        </StyledModalHeader>
        <ModalBody>
          <QuizPreviewCard quiz={quiz} />
        </ModalBody>
        <StyledModalFooter>
          <StyledAddButton
            color="secondary"
            data-testid="addQuizFromPreviewButton"
            onClick={onAddQuizFromPreview}
            variant="filled"
          >
            {t('Button.add')}
          </StyledAddButton>
        </StyledModalFooter>
      </StyledModal>
    </>
  );
};

const StyledQuizQuestionCard = styled(Card)`
  margin-top: ${({ $index }) => ($index ? '18px' : null)};
`;

const StyledCorrectText = styled(Typography)`
  display: flex;
  font-weight: 500;
  color: ${({ theme }) => theme.semanticColors.success};
  align-items: center;
  gap: 8px;
  padding-left: ${({ $isSmAndUp }) => ($isSmAndUp ? '0' : '36px')};
  margin: 4px;
`;

const StyledAnswersWrapper = styled.div`
  justify-content: center;
  align-items: baseline;
  flex-direction: 'row'};
`;

const StyledAnswerWrapper = styled.div`
  background: ${({ $correctAnswer, theme }) =>
    $correctAnswer && theme.designSystem.successBackground};
  border: ${({ $correctAnswer, theme }) =>
    $correctAnswer
      ? `1px solid ${theme.semanticColors.success}`
      : '1px solid transparent'};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 8px;
`;

const StyledCircleCheckIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

const StyledRadioButton = styled(RadioButton)`
  padding-left: 8px;
  margin-top: 8px;
  .radio {
    border-color: ${({ $isCorrect, theme }) =>
      $isCorrect ? `${theme.semanticColors.success}` : null} !important;
  }
  .cfa-radiobutton-inner-circle {
    border: ${({ $isCorrect, theme }) =>
      $isCorrect
        ? `6px solid ${theme.semanticColors.success}`
        : null} !important;
  }
`;

const StyledNoQuestions = styled(Typography)`
  text-align: center;
  padding: 16px 0;
  color: ${({ theme }) => theme.semanticColors.information};
`;

const StyledNoQuestionsInstructions = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.grayScale.gray4};
`;

const StyledQuestionName = styled(Typography)`
  text-align: left;
  font-weight: bold;
  padding: 16px 0;
  color: ${({ theme }) => theme.grayScale.gray6};
`;

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center;
`;

const StyledHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledOverlineTypography = styled(Typography)`
  margin-bottom: 0.5rem;
`;

const StyledEmptyDiv = styled.div`
  flex: 1;
  min-width: 155px;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  white-space: nowrap;
`;

const StyledBackButton = styled(Button)`
  padding-left: 0;
  transform: translateX(-10px);
`;

const StyledIconArrowLeft = styled(IconArrowLeft)`
  padding-right: 4px;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledAddButton = styled(Button)`
  min-width: unset;
`;

QuizPreviewCard.defaultProps = {
  quiz: null,
};

QuizPreviewPopUp.defaultProps = {
  quizToPreview: null,
  trainingPlan: null,
  sectionId: '',
};

QuizPreviewCard.propTypes = {
  quiz: Proptypes.object,
};

QuizPreviewPopUp.propTypes = {
  handleQuizBackButton: Proptypes.func.isRequired,
  isOpen: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
  refetch: Proptypes.func.isRequired,
  quizToPreview: Proptypes.object,
  trainingPlan: Proptypes.object,
  sectionId: Proptypes.string,
};

export default QuizPreviewPopUp;
