import { createSelector } from 'reselect';
import { isEqual } from 'lodash/lang';

export const manageTranslationsState = state => state.manageTranslations;

export const manageTranslations = createSelector(
  manageTranslationsState,
  state => state.mutable,
);

export const areTranslationsEdited = createSelector(
  manageTranslationsState,
  state => !isEqual(state.mutable, state.initial),
);
