import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';

const ConfirmationModal = ({
  bodyText,
  bodyTextColor,
  children,
  headerText,
  isDisabled,
  isError,
  isOpen,
  onClose,
  secondaryButtonColor,
  secondaryButtonHandler,
  secondaryButtonText,
  showCloseButton,
  primaryButtonColor,
  primaryButtonHandler,
  primaryButtonText,
  primaryButtonVariant,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    <Modal onClose={onClose} show={isOpen} size="md">
      <>
        <StyledModalHeader showCloseButton={showCloseButton}>
          {headerText}
        </StyledModalHeader>
        <StyledModalBody
          $bodyTextColor={bodyTextColor}
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
        {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
        <ModalFooter>
          {secondaryButtonHandler && secondaryButtonText && (
            <DialogButton
              $isDesktop={!isSmAndDown}
              color={secondaryButtonColor}
              data-testid="ConfirmationPopUpSecondaryButton"
              onClick={secondaryButtonHandler}
              variant="outlined"
            >
              {secondaryButtonText}
            </DialogButton>
          )}
          <DialogButton
            $isDesktop={!isSmAndDown}
            $isSingleButton={!(secondaryButtonHandler && secondaryButtonText)}
            color={primaryButtonColor}
            data-testid="ConfirmationPopUpPrimaryButton"
            disabled={isDisabled}
            onClick={primaryButtonHandler}
            variant={isError ? 'destructive' : primaryButtonVariant}
          >
            {primaryButtonText}
          </DialogButton>
        </ModalFooter>
      </>
    </Modal>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  font-size: 24px;
`;

const StyledModalBody = styled(ModalBody)`
  color: ${props =>
    props.$bodyTextColor === 'success'
      ? props.theme?.semanticColors?.success
      : 'default'};
`;

const ChildrenWrapper = styled.div`
  margin: 0 auto;
`;

const DialogButton = styled(Button)`
  ${({ $isDesktop, $isSingleButton }) =>
    $isDesktop && !$isSingleButton && 'flex: 1;'}
`;

ConfirmationModal.propTypes = {
  bodyText: PropTypes.string,
  bodyTextColor: PropTypes.string,
  children: PropTypes.node,
  headerText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonHandler: PropTypes.func,
  primaryButtonText: PropTypes.string.isRequired,
  primaryButtonVariant: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonColor: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  bodyText: '',
  bodyTextColor: '',
  children: null,
  isDisabled: false,
  isError: false,
  onClose: () => {},
  primaryButtonColor: 'secondary',
  primaryButtonHandler: () => {},
  primaryButtonVariant: 'filled',
  secondaryButtonColor: 'secondary',
  secondaryButtonHandler: () => {},
  secondaryButtonText: '',
  showCloseButton: true,
};

export default ConfirmationModal;
