import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'subHeader',
  initialState: {
    hasDropShadow: true,
  },
  reducers: {
    setSubHeaderDropShadow: (state, action) => {
      state.hasDropShadow = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSubHeaderDropShadow } = slice.actions;

export default slice.reducer;
