export const useIsNative = () => {
  const iOSToken = window.accessToken;
  const iOSUser = window.userFromToken;
  const reactNativeWebView = window.ReactNativeWebView;
  let androidToken;
  let androidUser;
  if (
    reactNativeWebView &&
    typeof reactNativeWebView.injectedObjectJson === 'function'
  ) {
    const androidString = reactNativeWebView.injectedObjectJson();
    androidToken = JSON.parse(androidString).accessToken;
    androidUser = JSON.parse(androidString).userFromToken;
  }
  return (!!iOSToken && !!iOSUser) || (!!androidToken && !!androidUser);
};
