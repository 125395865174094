import { createSlice } from '@reduxjs/toolkit';

const applyPagination = (quizzes, page, pageSize) => {
  const total = quizzes.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedQuizzes = quizzes.slice(0, showing);
  return { loadedQuizzes, showing, total };
};

export const slice = createSlice({
  name: 'quizzes',
  initialState: {
    quizzes: [],
    paginatedQuizzes: [],
    page: 1,
    pageSize: 12,
    showing: 0,
  },
  reducers: {
    setBuildQuizzes: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.quizzes = action.payload.quizzes;
      ({
        loadedQuizzes: state.paginatedQuizzes,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.quizzes, state.page, state.pageSize));
    },
    loadMoreQuizzes: state => {
      state.page += 1;
      ({
        loadedQuizzes: state.paginatedQuizzes,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.quizzes, state.page, state.pageSize));
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBuildQuizzes, loadMoreQuizzes } = slice.actions;

export default slice.reducer;
