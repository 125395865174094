import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'scrollPosition',
  initialState: {
    id: '',
    data: [],
    pageNumber: 1,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
  },
});

export const { setData, setId, setPageNumber } = slice.actions;

export default slice.reducer;
