import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectUserPermissions } from '../../store/user/selectors';
import ConfirmationModal from './popups/ConfirmationModal';

export const withRoles = (Component, requiredRoles) => props => {
  let hasRoles = false;
  const userPermissions = useSelector(selectUserPermissions);
  const { t } = useTranslation();
  const history = useHistory();

  // eslint-disable-next-line array-callback-return
  requiredRoles.map(role => {
    if (userPermissions[role]) {
      hasRoles = true;
    }
  });

  return hasRoles ? (
    <Component {...props} />
  ) : (
    <ConfirmationModal
      bodyText={t('UserNotAssignedToRoleError.errorParagraph')}
      headerText={t('UserNotAssignedToRoleError.errorHeader')}
      isError={true}
      isOpen={true}
      onClose={() => history.push('/')}
      primaryButtonHandler={() => history.push('/')}
      primaryButtonText={t('Button.returnToHomepage')}
      primaryButtonVariant="danger"
    />
  );
};
