import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'nativeOptions',
  initialState: {
    menu: true,
  },
  reducers: {
    setNativeOptions: (state, action) => {
      state.menu = action.payload.menu;
    },
  },
});

export const { setNativeOptions } = slice.actions;

export default slice.reducer;
