import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import { TemplateCard } from './TemplateCard';

const TemplateModal = ({
  isOpen,
  onClose,
  onCopy,
  onPreviewTemplateClick,
  templates,
}) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      onClose={onClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ModalHeader>{t('TrainingPlans.templates.templateModal')}</ModalHeader>
      <ModalBody>
        {templates.map(template => (
          <TemplateCard
            key={template.id}
            onCopy={() => onCopy(template.id)}
            onPreview={id => {
              onPreviewTemplateClick(template);
            }}
            plan={template}
          />
        ))}
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

TemplateModal.defaultProps = {
  templates: [],
};

TemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onPreviewTemplateClick: PropTypes.func.isRequired,
  templates: PropTypes.array,
};
export default TemplateModal;
