import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash/lang';

const filterSection = (sections, sectionId) =>
  sections.find(section => section.id === sectionId);

const filterStep = (steps, id) => steps.find(step => step.id === id);

const initializeMissingProperties = payload => {
  const initializedState = cloneDeep(payload);

  if (!initializedState?.name?.es) {
    if (initializedState.name) {
      initializedState.name.es = '';
    }
  }
  // eslint-disable-next-line array-callback-return
  initializedState.steps?.map(step => {
    if (step.type === 'task') {
      if (!step.name.es) {
        // eslint-disable-next-line no-param-reassign
        step.name.es = '';
      }
      if (step.note) {
        if (!step.note.es) {
          // eslint-disable-next-line no-param-reassign
          step.note.es = '';
        }
      }
    }
  });

  // eslint-disable-next-line array-callback-return
  initializedState.sections?.map(section => {
    if (!section.name.es) {
      // eslint-disable-next-line no-param-reassign
      section.name.es = '';
    }
    // eslint-disable-next-line array-callback-return
    section.steps.map(step => {
      if (step.type === 'task') {
        if (!step.name.es) {
          // eslint-disable-next-line no-param-reassign
          step.name.es = '';
        }
        if (step.note) {
          if (!step.note.es) {
            // eslint-disable-next-line no-param-reassign
            step.note.es = '';
          }
        }
      }
    });
  });
  return initializedState;
};

export const slice = createSlice({
  name: 'manageTranslations',
  initialState: { mutable: {}, initial: {} },
  reducers: {
    initializeTrainingPlanTranslations: (state, action) => {
      const initializedState = initializeMissingProperties(action.payload);
      return {
        mutable: { ...initializedState },
        initial: { ...initializedState },
      };
    },
    updatePlanNameTranslation: (state, action) => {
      const { id, value, targetLanguage } = action.payload;
      if (state.mutable.id === id) {
        state.mutable.name[targetLanguage] = value;
      }
    },
    updateSectionTranslation: (state, action) => {
      const { id, value, targetLanguage } = action.payload;
      const section = filterSection(state.mutable.sections, id);
      if (section) {
        section.name[targetLanguage] = value;
      }
    },
    updateTaskNameTranslation: (state, action) => {
      const { id, value, sectionId, targetLanguage } = action.payload;
      let steps = state.mutable.steps;
      if (sectionId) {
        steps = filterSection(state.mutable.sections, sectionId)?.steps;
      }
      const step = filterStep(steps, id);
      if (step) {
        step.name[targetLanguage] = value;
      }
    },
    updateTaskNotesTranslation: (state, action) => {
      const { id, value, sectionId, targetLanguage } = action.payload;
      let steps = state.mutable.steps;
      if (sectionId) {
        steps = filterSection(state.mutable.sections, sectionId)?.steps;
      }
      const step = filterStep(steps, id);
      if (step) {
        step.note[targetLanguage] = value;
      }
    },
    updateTaskUrlTranslation: (state, action) => {
      const { id, value, sectionId, index, targetLanguage } = action.payload;
      let steps = state.mutable.steps;
      if (sectionId) {
        steps = filterSection(state.mutable.sections, sectionId)?.steps;
      }
      const step = filterStep(steps, id);
      if (step) {
        const url = step.urls[index]?.urlSet;
        if (url) {
          url[targetLanguage] = value;
        }
      }
    },
    updateQuizNameTranslation: (state, action) => {
      const { id, value, targetLanguage } = action.payload;
      if (state.mutable.quizId === id) {
        state.mutable.name[targetLanguage] = value;
      }
    },
    updateQuestionTranslation: (state, action) => {
      const { value, questionIndex, targetLanguage } = action.payload;
      const questions = state.mutable.questions;
      questions[questionIndex].question[targetLanguage] = value;
    },
    updateAnswerTranslation: (state, action) => {
      const { value, questionIndex, answerIndex, targetLanguage } =
        action.payload;
      const answers = state.mutable.questions[questionIndex].answers;
      answers[answerIndex].answer[targetLanguage] = value;
    },
    updateAllTranslations: (state, action) => {},
  },
});

// Action creators are generated for each case reducer function
export const {
  initializeTrainingPlanTranslations,
  updatePlanNameTranslation,
  updateSectionTranslation,
  updateTaskNameTranslation,
  updateTaskNotesTranslation,
  updateTaskUrlTranslation,
  updateQuizNameTranslation,
  updateQuestionTranslation,
  updateAnswerTranslation,
} = slice.actions;

export default slice.reducer;
