import Constants from 'constants/index';
import Bugsnag from '@bugsnag/js';
import { formatBugsnagErrorMessage } from 'bugsnag';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateChecklistStepStatusMutation } from 'services/pathwayApi';

const useTrainingPlanTimer = (planId, stepId, userId, stepStatus) => {
  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [roundUpTimer, setRoundUpTimer] = useState(false);

  const logTimeViewed = useCallback(
    milliSeconds => {
      const seconds = milliSeconds / 1000;
      updateChecklistStepStatus({
        checklistId: planId,
        stepId: stepId,
        status: stepStatus,
        userIds: userId,
        body: { incrementalTime: seconds },
      })
        .unwrap()
        .then()
        .catch(err => {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planId, stepId, stepStatus],
  );

  useEffect(() => {
    let logIntervalId = null;
    let roundIntervalId = null;

    if (timerIsRunning && stepStatus) {
      logIntervalId = setInterval(() => {
        setRoundUpTimer(prevState => !prevState);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_ROUNDUP);
      roundIntervalId = setInterval(() => {
        logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
    }
    return () => {
      clearInterval(logIntervalId);
      clearInterval(roundIntervalId);
    };
  }, [logTimeViewed, stepStatus, timerIsRunning]);

  useEffect(() => {
    if (
      roundUpTimer &&
      !timerIsRunning &&
      stepId &&
      planId &&
      userId &&
      stepStatus
    ) {
      logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      setRoundUpTimer(false);
    }
  }, [
    logTimeViewed,
    planId,
    roundUpTimer,
    stepId,
    stepStatus,
    timerIsRunning,
    userId,
  ]);

  useEffect(() => {
    return () => {
      setTimerIsRunning(false);
    };
  }, []);

  const startTimer = useCallback(() => {
    setTimerIsRunning(true);
  }, []);

  const stopTimer = useCallback(() => {
    setTimerIsRunning(false);
  }, []);

  return [startTimer, stopTimer];
};

export default useTrainingPlanTimer;
