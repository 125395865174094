import Constants from 'constants';
import { isApiError } from 'util/request';
import { Button } from 'cfa-react-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useUpdateChecklistStepStatusMutation } from 'services/pathwayApi';
import Bugsnag from '@bugsnag/js';
import { formatBugsnagErrorMessage } from 'bugsnag';
import GenericError from 'sharedComponents/app/GenericError';
import { useTranslation } from 'react-i18next';

const DocumentCompleteButton = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();

  const onCompleteButtonClick = () => {
    updateChecklistStepStatus({
      checklistId: location.state.planId,
      stepId: location.state.step.id,
      status: Constants.TRAINING_PLANS.COMPLETE,
      userIds: location.state.userIds,
    })
      .unwrap()
      .then(() => {
        !location.state.userIds
          ? history.push({
              pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${location.state.planId}`,
            })
          : history.goBack();
      })
      .catch(error => {
        if (isApiError(error)) {
          Constants.BUGSNAG_ENABLED &&
            Bugsnag.notify(formatBugsnagErrorMessage(error));
          return <GenericError />;
        }
        Bugsnag.notify(formatBugsnagErrorMessage(error));
      });
  };

  return (
    <Button color="secondary" onClick={onCompleteButtonClick}>
      {t('Button.complete')}
    </Button>
  );
};

export default DocumentCompleteButton;
