import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ChickenSpinner } from 'sharedComponents/universal';

const PdfDownload = ({ url }) => {
  const handleDownload = useCallback(
    name => {
      var link = document.createElement('a');
      link.download = name;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [url],
  );

  useEffect(() => {
    handleDownload();
  }, [handleDownload]);

  return (
    <StyledPdfContainer>
      <ChickenSpinner />
    </StyledPdfContainer>
  );
};

const StyledPdfContainer = styled.div`
  height: 100vh;
  witdh: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

PdfDownload.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PdfDownload;
