import { useSelector } from 'react-redux';
import { selectIsInitialized } from 'store/app/selectors';
import Routes from 'Routes';
import { memo } from 'react';

// Anything to do with the session cannot be selected here!!
// It will tear down the entire Routes component and cause problems
const RoutesContainer = () => {
  const isInitialized = useSelector(selectIsInitialized);
  if (!isInitialized) {
    return null; // overlay will be shown
  }
  return <Routes />;
};

export default memo(RoutesContainer);
