import { Typography } from 'cfa-react-components';
import styled from 'styled-components';

const Footer = () => (
  <CopyrightFooter>
    <CopyrightFooterText variant="caption1">
      ©2024 Chick-fil-A, Inc. All rights reserved.
    </CopyrightFooterText>
  </CopyrightFooter>
);

const CopyrightFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CopyrightFooterText = styled(Typography)`
  margin: 72px 0 24px 0;
`;

export default Footer;
