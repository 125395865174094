import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'header',
  initialState: {
    headerLabel: '',
    headerName: '',
    headerSubtext: '',
    historyStackCount: -1,
  },
  reducers: {
    setHeaderLabel: (state, action) => {
      state.headerLabel = action.payload;
    },
    setHeader: (state, action) => {
      state.headerName = action.payload;
    },
    setHeaderSubtext: (state, action) => {
      state.headerSubtext = action.payload;
    },
    incrementHistoryStackCount: state => {
      state.historyStackCount = state.historyStackCount - 1;
    },
    resetHistoryStackCount: state => {
      state.historyStackCount = -1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setHeaderLabel,
  setHeader,
  setHeaderSubtext,
  incrementHistoryStackCount,
  resetHistoryStackCount,
} = slice.actions;

export default slice.reducer;
