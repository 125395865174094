import styled from 'styled-components';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  DatePicker,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
} from 'cfa-react-components';

const DueDatePopUp = ({ isOpen, onClose, onSave, triggerDueDateReset }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [disableDatePicker, setDisableDatePicker] = useState(false);

  useEffect(() => {
    setSelectedDate(null);
    setCheckboxClicked(false);
    setDisableDatePicker(false);
  }, [isOpen]);

  useEffect(() => {
    setSelectedDate(null);
    setCheckboxClicked(false);
    setDisableDatePicker(false);
  }, [triggerDueDateReset]);

  const onDateChange = date => {
    !isNaN(date) && !date.target
      ? setSelectedDate(date)
      : setSelectedDate(null);
  };

  const onCheckboxClick = () => {
    setCheckboxClicked(prev => !prev);
    setSelectedDate(null);
    setDisableDatePicker(prev => !prev);
  };

  return (
    <>
      <Modal
        data-testid="DueDatePopUp"
        onClose={onClose}
        show={isOpen}
        size="md"
      >
        <ModalHeader>
          {t('TrainingPlans.accountability.setDueDate')}
        </ModalHeader>
        <ModalBody>
          <StyledDatePickerOuterWrapper>
            <StyledDatePickerInnerWrapper>
              <DatePicker
                disabled={disableDatePicker}
                label={t('TrainingPlans.accountability.enterDueDate')}
                onChange={date => onDateChange(date)}
                required
                value={selectedDate}
              />
              <Checkbox
                data-testid="DueDateCheckbox"
                label={t('TrainingPlans.accountability.due.noDueDate')}
                onChange={onCheckboxClick}
                style={{ marginTop: '2em' }}
              />
            </StyledDatePickerInnerWrapper>
          </StyledDatePickerOuterWrapper>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            data-testid="ButtonSave"
            disabled={!(selectedDate || checkboxClicked)}
            onClick={() => onSave(selectedDate)}
          >
            {t('Button.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const StyledDatePickerOuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledDatePickerInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
`;

DueDatePopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  triggerDueDateReset: PropTypes.bool,
};

DueDatePopUp.defaultProps = {
  triggerDueDateReset: false,
};

export default DueDatePopUp;
