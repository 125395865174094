import { List, ListItem, Surface } from 'cfa-react-components';
import { IconX } from '@tabler/icons-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SearchbarSuggestionsDropdown = ({
  suggestions,
  onSetSuggestion,
  onRemoveSuggestion,
}) => {
  return (
    <SearchbarDropdown data-testid="DesktopSearchHistory" elevation={1}>
      <SuggestionList divider="inset" variant="default">
        {suggestions?.map((suggestion, index) => (
          <SuggestionItem
            data-testid={`SearchTermHistory${index}`}
            endItem={
              <IconX
                onClick={e => {
                  e.stopPropagation();
                  onRemoveSuggestion(suggestion);
                }}
              />
            }
            key={suggestion}
            onClick={e => {
              e.stopPropagation();
              onSetSuggestion(suggestion);
            }}
          >
            {suggestion}
          </SuggestionItem>
        ))}
      </SuggestionList>
    </SearchbarDropdown>
  );
};

SearchbarSuggestionsDropdown.defaultProps = {
  suggestions: [],
};

SearchbarSuggestionsDropdown.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onSetSuggestion: PropTypes.func.isRequired,
  onRemoveSuggestion: PropTypes.func.isRequired,
};

const SearchbarDropdown = styled(Surface)`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
`;

const SuggestionList = styled(List)`
  padding: 0 !important;
`;

const SuggestionItem = styled(ListItem)`
  cursor: pointer;
`;

export default SearchbarSuggestionsDropdown;
