import { isAndroidDevice } from 'util/device';
import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'services/pathwayApi';
import { ChickenSpinner } from 'sharedComponents/universal';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { PathwayLogo } from 'icons';
import { useTranslation } from 'react-i18next';
import {
  selectUser,
  selectUsersHighestPermissionLevel,
} from 'store/user/selectors';
import { Button } from 'cfa-react-components';
import { useSelector } from 'react-redux';
import { useGoogleAnalytics } from 'googleAnalytics/useGoogleAnalytics';
import Constants from '../../constants/index';
import PdfDocument from './PdfDocument';
import PdfDownload from './PdfDownload';

const PrintView = () => {
  const params = useParams();
  const { documentId } = params;
  const ref = useRef();
  const { t } = useTranslation();
  const gtag = useGoogleAnalytics();
  const user = useSelector(selectUser);
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  // we have to fetch this to get an auth cookie from the backend, we dont actually use the reponse data
  const { isFetching, error } = useGetDocumentQuery(documentId);

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (gtag !== null) {
      // ensure we have initialized gtag scripts
      gtag('event', 'print_document', {
        document_id: documentId ?? 'empty',
        user_perms: userPermissionLevel ?? 'empty',
        cfa_user_id: user.userId ?? 'empty',
        user_type: user.userType ?? 'empty',
        restaurants: user.locations?.toString() ?? 'empty',
        datetime: Date().toLocaleString() ?? 'empty',
        env: Constants.ENV ?? 'empty',
      });
    }
  }, [
    gtag,
    documentId,
    user.locations,
    user.type,
    user.userId,
    user.userType,
    userPermissionLevel,
  ]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <>
      <StyledFullScreen>
        {!isFetching ? (
          <>
            {!isAndroidDevice ? (
              <PdfDownload
                url={`${Constants.PATHWAY_API_URL}/${Constants.PATHWAY_API.BINARY_PATH_NAME}/${documentId}`}
              ></PdfDownload>
            ) : (
              <StyledPrintContainer>
                <StyledPrintHeader>
                  <StyledLogo />
                  <Button color="secondary" onClick={handlePrint}>
                    {t('Generic.printDocument')}
                  </Button>
                </StyledPrintHeader>
                <div ref={ref}>
                  <PdfDocument
                    url={`${Constants.PATHWAY_API_URL}/${Constants.PATHWAY_API.BINARY_PATH_NAME}/${documentId}`}
                  />
                </div>
              </StyledPrintContainer>
            )}
          </>
        ) : (
          <StyledChickenSpinnerContainer>
            <ChickenSpinner />
          </StyledChickenSpinnerContainer>
        )}
      </StyledFullScreen>
    </>
  );
};

const StyledLogo = styled(PathwayLogo)`
  height: 34px;
  width: 30px;
`;

const StyledPrintHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  max-width: 800px;
  background: ${props => props.theme.primaryPalette.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${props => `${props.theme.boxShadow.elevation1}`};
  padding: 15px 6%;
  // 6% scales left/right padding to match document side padding
`;

const StyledFullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 2;
  min-height: 100vh;
  width: 100vw;
  background: ${props => props.theme.surfaceColors.gray};
`;

const StyledPrintContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  position: relative;
`;

const StyledChickenSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;

  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PrintView;
