import Constants from 'constants';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useClickOutside } from 'hooks/useClickOutside';
import { useGetIconsQuery } from 'services/pathwayApi';
import { Button, LoadingIndicator, Typography } from 'cfa-react-components';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import Searchbar from 'components/Searchbar/Searchbar';

export const AddIcon = ({ onAddIcon, onClose }) => {
  const [iconsList, setIconsList] = useState([]);
  const pageOffset = 15;
  const [sliceAmount, setSliceAmount] = useState({
    start: 0,
    end: pageOffset,
  });
  const [iconsSearchTermDisplayed, setIconsSearchTermDisplayed] = useState('');
  const { t } = useTranslation();
  const AddIconRef = useRef(null);
  useClickOutside(AddIconRef, onClose);

  const { data: icons, isFetching: isFetchingIcons } = useGetIconsQuery();

  useEffect(() => {
    if (icons) {
      setIconsList(
        icons.map(icon => {
          return {
            name: icon,
            isSelected: false,
          };
        }),
      );
    }
  }, [icons]);

  const onIconClick = name => {
    setIconsList(
      iconsList.map(icon =>
        icon.name === name
          ? { ...icon, isSelected: true }
          : { ...icon, isSelected: false },
      ),
    );
  };

  const onNext = () => {
    if (sliceAmount.end >= iconsList.length) {
      return;
    }
    setSliceAmount(prev => ({
      start: prev.start + pageOffset,
      end: prev.end + pageOffset,
    }));
  };

  const onPrevious = () => {
    if (sliceAmount.start === 0) {
      return;
    }
    setSliceAmount(prev => ({
      start: prev.start - pageOffset,
      end: prev.end - pageOffset,
    }));
  };

  const onIconsSearchTermSelect = () => {
    const searchTerm = iconsSearchTermDisplayed.toLowerCase()?.trim();
    setIconsList(
      icons
        ?.map(icon => {
          return {
            name: icon,
            isSelected: false,
          };
        })
        ?.filter(({ name }) => name.toLowerCase().startsWith(searchTerm)),
    );
  };

  const onIconsSearchInputChange = e => {
    const searchTerm = e.target.value;
    searchTerm.length
      ? setIconsSearchTermDisplayed(searchTerm)
      : setIconsSearchTermDisplayed('');
  };

  const initializeIconsForSearch = () => {
    setIconsList(
      icons?.map(icon => {
        return {
          name: icon,
          isSelected: false,
        };
      }),
    );
  };

  const onClearIconsSearchClick = () => {
    initializeIconsForSearch();
    setIconsSearchTermDisplayed('');
  };

  return (
    <StyledAddIconWrapper ref={AddIconRef}>
      <StyledTopRow>
        <Typography fontWeight="bold" variant="body1">
          {t('Admin.selectIcon')}
        </Typography>
        <StyledAddButton
          $isDisabled={iconsList.every(icon => !icon.isSelected)}
          data-testid="AddButton"
          disabled={iconsList.every(icon => !icon.isSelected)}
          onClick={() => {
            onAddIcon(iconsList.find(icon => icon.isSelected).name);
          }}
          size="sm"
        >
          {t('Button.add')}
        </StyledAddButton>
      </StyledTopRow>
      <IconSearchbar
        onChange={onIconsSearchInputChange}
        onClear={onClearIconsSearchClick}
        onSubmit={onIconsSearchTermSelect}
        placeholder={t('Admin.searchForIcons')}
        searchValue={iconsSearchTermDisplayed}
      />
      <StyledIcons>
        <>
          {iconsList &&
            iconsList
              ?.slice(sliceAmount.start, sliceAmount.end)
              .map((icon, index) => (
                <StyledIcon
                  $isSelected={icon.isSelected}
                  data-testid="Icon"
                  key={index}
                  onClick={() => onIconClick(icon.name)}
                  src={`${Constants.PATHWAY_CDN_IMG.CATEGORIES}${icon.name}.svg`}
                />
              ))}
          {isFetchingIcons && <LoadingIndicator />}
        </>
      </StyledIcons>
      {iconsList.length > pageOffset && (
        <StyledBottomRow>
          <StyledLeftIcon onClick={onPrevious} />
          <StyledRightIcon onClick={onNext} />
        </StyledBottomRow>
      )}
    </StyledAddIconWrapper>
  );
};

AddIcon.propTypes = {
  onAddIcon: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const StyledAddIconWrapper = styled.div`
  width: 296px;
  border: 1px solid ${({ theme }) => theme.grayScale.gray2};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background: white;
  transform: translate(1.5em, -15.5em);
  box-shadow: ${({ theme }) => theme.boxShadow.elevation4};
`;

const IconSearchbar = styled(Searchbar)`
  margin: 1em 0;
`;

const StyledTopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 296px;
  flex-wrap: wrap;
`;

const StyledAddButton = styled(Button)`
  background: ${({ theme, $isDisabled }) =>
    !$isDisabled && `${theme.primaryPalette.navyBlue} !important`};
`;

const StyledIcons = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  height: 182px;
`;

const StyledIcon = styled.img`
  width: 40px;
  height: 40px;
  margin: 0.2em;
  border-radius: 4px;
  background: ${({ $isSelected, theme }) =>
    $isSelected && theme.semanticColors.success};
  &:hover {
    background: ${({ theme }) => theme.semanticColors.success};
    cursor: pointer;
  }
`;

const StyledBottomRow = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;

const StyledLeftIcon = styled(IconChevronLeft)`
  cursor: pointer;
  margin-right: 0.5em;
`;

const StyledRightIcon = styled(IconChevronRight)`
  cursor: pointer;
  margin-left: 0.5em;
`;

export default AddIcon;
