import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';

const DeleteQuizPopup = ({
  showDeleteQuizPopup,
  setShowDeleteQuizPopup,
  onDeleteQuizAndClosePopup,
  quizName,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      bodyText={`${t('TrainingPlans.buildQuizzes.deleteQuiz', {
        quizName: quizName,
      })} ${t('Generic.cantUndoAction')}`}
      headerText={t('Button.deleteQuiz')}
      isOpen={showDeleteQuizPopup}
      onClose={() => setShowDeleteQuizPopup(false)}
      primaryButtonColor={'primary'}
      primaryButtonHandler={onDeleteQuizAndClosePopup}
      primaryButtonText={`${t('Button.yes')}, ${t('Button.deleteQuiz')}`}
      secondaryButtonHandler={() => setShowDeleteQuizPopup(false)}
      secondaryButtonText={t('Button.cancel')}
    />
  );
};
DeleteQuizPopup.propTypes = {
  onDeleteQuizAndClosePopup: PropTypes.func.isRequired,
  quizName: PropTypes.string.isRequired,
  setShowDeleteQuizPopup: PropTypes.func.isRequired,
  showDeleteQuizPopup: PropTypes.bool.isRequired,
};

export default DeleteQuizPopup;
