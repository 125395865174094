import { getNameFromLanguage } from 'util/language';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconCircleDisabled, IconCircleCheckDisabled, Play } from 'icons';
import { IconCircle, IconCircleCheck } from '@tabler/icons-react';
import { Typography } from 'cfa-react-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import GenericError from 'sharedComponents/app/GenericError';

const PlanCardBrightcoveVideo = ({
  disabled,
  isActive,
  isComplete,
  onClick,
  onCompleteToggle,
  section,
  statuses,
  step,
  users,
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [isCompleted, setIsCompleted] = useState(isComplete);

  const userStatuses = statuses?.filter(status =>
    users?.find(id => status.userId === id),
  );

  let activeVideoUserStatuses = userStatuses?.filter(userStatus =>
    userStatus?.steps?.find(stepStatus => stepStatus.stepId === step.id),
  );
  if (!activeVideoUserStatuses?.length) {
    activeVideoUserStatuses = userStatuses;
  }

  const currentUserStatus = activeVideoUserStatuses?.find(
    userStatus => userStatus.userId === users[0],
  );

  const videoStatus = currentUserStatus?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  );

  const stepDuration = Math.round((videoStatus?.stepDuration ?? 0) / 60);

  const handleSuccessVideoLoad = () => {};

  const languageSelected = state?.languageSelected;

  const videoReference = getNameFromLanguage(
    step?.reference?.references,
    languageSelected,
  );

  if (!videoReference) {
    return <GenericError />;
  }

  const brightcoveSrcUrl = new URL(videoReference.src);
  const brightcoveAccountId = brightcoveSrcUrl.pathname.match(/\d+/);
  const brightcoveVideoId = brightcoveSrcUrl.searchParams.get('videoId');

  return (
    <>
      <div>
        <TaskOrProcedureBody
          $isActive={isActive}
          $isDisabled={disabled}
          $isInSection={!!section}
        >
          {isCompleted ? (
            <IconWrapper
              $isDisabled={disabled}
              data-testid="StepStatusIcon"
              onClick={
                !disabled
                  ? () => {
                      setIsCompleted(prevState => !prevState);
                      onCompleteToggle();
                    }
                  : null
              }
            >
              {!disabled ? (
                <StyledIconCircleCheck />
              ) : (
                <IconCircleCheckDisabled />
              )}
            </IconWrapper>
          ) : (
            <IconWrapper
              $isDisabled={disabled}
              data-testid="StepStatusIcon"
              onClick={
                !disabled
                  ? () => {
                      setIsCompleted(prevState => !prevState);
                      onCompleteToggle();
                    }
                  : null
              }
            >
              {!disabled ? <StyledIconCircle /> : <IconCircleDisabled />}
            </IconWrapper>
          )}
          <TypeAndNameWrapper $isDisabled={disabled} onClick={onClick}>
            <Typography data-testid="TaskTitle" variant="overline3">
              {t('Generic.video')}
              {isCompleted &&
                ` - ${t('TrainingPlans.completedIn')} ${stepDuration} ${t(
                  'Generic.mins',
                )}`}
            </Typography>
            <Typography data-testid="VideoName" variant="body1">
              {getNameFromLanguage(step?.name, languageSelected)}
            </Typography>
          </TypeAndNameWrapper>
          <PlayIconWrapper $canExpand={!isCompleted} onClick={onClick}>
            <Play />
          </PlayIconWrapper>
        </TaskOrProcedureBody>
      </div>
      {isActive && !isCompleted && (
        <StyledVideoWrapper>
          <ReactPlayerLoader
            accountId={brightcoveAccountId}
            onSuccess={handleSuccessVideoLoad}
            options={{
              autoplay: false,
              controls: false,
              fluid: false,
              loop: false,
              muted: false,
            }}
            videoId={brightcoveVideoId}
          />
        </StyledVideoWrapper>
      )}
    </>
  );
};

const StyledIconCircle = styled(IconCircle)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${({ theme }) => theme.semanticColors.success};
`;

const TaskOrProcedureBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};
  padding-bottom: ${({ $isActive }) => $isActive && '10px'};
`;

const TypeAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};
  margin-left: 8px;
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: ${({ $isPlanComplete }) =>
    !$isPlanComplete ? 'pointer' : 'default'};
`;

const PlayIconWrapper = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
  display: flex;
  width: 25px;
  margin: 0 5px;
  cursor: ${({ $canExpand }) => $canExpand && 'pointer'};
`;

const StyledVideoWrapper = styled.div`
  .brightcove-react-player-loader {
    display: flex;
    justify-content: center;
    .video-js {
      width: 394px !important;
    }
  }
`;

PlanCardBrightcoveVideo.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onCompleteToggle: PropTypes.func.isRequired,
  section: PropTypes.object,
  statuses: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

PlanCardBrightcoveVideo.defaultProps = {
  disabled: false,
  section: null,
};

export default PlanCardBrightcoveVideo;
