import { setWindowObjectToConnectAppToCypress } from 'util/environment';
import Constants from 'constants/index';
import oktaAuth from 'okta/oktaConfig';

export const getRefreshTokenWithRedirect = () => {
  if (Constants.IS_IN_CYPRESS_TEST) {
    setWindowObjectToConnectAppToCypress('signInWithRedirect', true);
    return;
  }
  return oktaAuth.signInWithRedirect();
};

export const isUnAuthorizedError = error => error && error?.status === 401;
