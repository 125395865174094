import styled from 'styled-components';
import { Button, Typography } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { IconPlus } from '@tabler/icons-react';

const AddResourceToSubtitleButton = ({ onAdminAddResourceToSubtitle }) => {
  const { t } = useTranslation();
  return (
    <Button
      color="secondary"
      onClick={onAdminAddResourceToSubtitle}
      variant="text"
    >
      <StyledIconPlus />
      <Typography
        color="secondary"
        data-testid="AddResourceToNewSection"
        variant="body1"
      >
        {t('Admin.addResource')}
      </Typography>
    </Button>
  );
};

const StyledIconPlus = styled(IconPlus)`
  margin-right: 8px;
`;

AddResourceToSubtitleButton.propTypes = {
  onAdminAddResourceToSubtitle: PropTypes.func.isRequired,
};

export default AddResourceToSubtitleButton;
