import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'search',
  initialState: {
    terms: [],
  },
  reducers: {
    saveSearchTerm: (state, action) => {
      const termExistsIndex = state.terms.findIndex(
        term => action.payload === term,
      );
      if (termExistsIndex >= 0) {
        if (termExistsIndex === 0) {
          return;
        } else {
          const termsWithExistingTermRemoved = [...state.terms].filter(
            (term, idx) => idx !== termExistsIndex,
          );
          state.terms = [action.payload, ...termsWithExistingTermRemoved];
        }
      } else {
        state.terms = [action.payload, ...state.terms].slice(0, 5);
      }
    },
    removeSearchTerm: (state, action) => {
      state.terms = state.terms.filter(st => st !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveSearchTerm, removeSearchTerm } = slice.actions;

export default slice.reducer;
