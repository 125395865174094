import { useEffect, useState } from 'react';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.min';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PdfPage from './PdfPage';

const PdfDocument = ({ url }) => {
  const [pdfContent, setPdfContent] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(data => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          var base64data = reader.result;
          setPdfContent(base64data);
        };
      });
  }, [url]);

  useEffect(() => {
    if (pdfContent) {
      const loadingTask = pdfjs.getDocument(pdfContent);
      loadingTask.promise.then(loadedPdf => {
        setPdf(loadedPdf);
        setNumPages(loadedPdf.numPages);
      });
    }
  }, [pdfContent]);

  return (
    <StyledPdfContainer>
      {pdf &&
        numPages &&
        Array(numPages)
          .fill(1)
          .map((x, y) => x + y)
          .map(pageNum => (
            <PdfPage key={pageNum} pageNum={pageNum} pdf={pdf} />
          ))}
    </StyledPdfContainer>
  );
};

const StyledPdfContainer = styled.div``;

PdfDocument.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PdfDocument;
