import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'chooseLocations',
  initialState: {
    isOpen: false,
  },
  reducers: {
    hideChooseLocations: state => {
      state.isOpen = false;
    },
    showChooseLocations: state => {
      state.isOpen = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { hideChooseLocations, showChooseLocations } = slice.actions;

export default slice.reducer;
