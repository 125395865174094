import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card, Typography } from 'cfa-react-components';

const CategoryCard = ({
  categoryId,
  categoryName,
  categoryIcon,
  onClick,
  'data-testid': dataTestId,
  className,
}) => {
  return (
    <CategoryCardContainer
      className={className}
      data-testid={dataTestId || `${categoryId}-card`}
      elevation={1}
      onClick={() => onClick(categoryId)}
    >
      <CategoryIcon
        alt={`${getNameFromLanguage(categoryName)} icon`}
        src={Constants.PATHWAY_CDN_IMG.CATEGORIES + categoryIcon + '.svg'}
      />
      <CategoryName variant="body1">
        {getNameFromLanguage(categoryName)}
      </CategoryName>
    </CategoryCardContainer>
  );
};

CategoryCard.propTypes = {
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }).isRequired,
  categoryIcon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
};

CategoryCard.defaultProps = {
  onClick: () => {},
  className: '',
  'data-testid': '',
};

const CategoryName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CategoryCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border: 1px solid #cccfd0;
`;

const CategoryIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

export default CategoryCard;
