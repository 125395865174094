import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'user',
  initialState: {
    audience: '',
    email: '',
    firstName: '',
    fullName: '',
    language: '',
    country: {},
    locations: [],
    permissions: {},
    userId: '',
    userType: '',
  },
  reducers: {
    initializeUser: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { initializeUser } = slice.actions;

export default slice.reducer;
