import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dropdown,
  Link,
  Switch,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useTranslation } from 'react-i18next';

const SortFilterHeader = ({
  text,
  label,
  value,
  options,
  onChange,
  onClear,
  showClear,
  'data-testid': dataTestId,
  className,
  showCompletedPlansOption,
  showMyCompletedPlansOnlySwitchValue,
  handleOnCompletedPlansOnlyToggle,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <SortFilterContainer className={className} data-testid={dataTestId}>
      <SortFilterLeftContainer>
        <Typography fontWeight="bold" variant="body1">{`${text}`}</Typography>
        {!!showClear && (
          <ClearButton onClick={onClear} size="body2" variant="standalone">
            {t('Button.clearAll')}
          </ClearButton>
        )}
      </SortFilterLeftContainer>
      {!!showCompletedPlansOption && (
        <div>
          <StyledLeftSideSwitchLabel>
            <ToggleLabel variant="body1">
              {t('TrainingPlans.filtering.includeCompleted')}
            </ToggleLabel>
            <StyledCompletedOnlySwitch
              checked={showMyCompletedPlansOnlySwitchValue}
              data-testid="Switch-Completed"
              onChange={handleOnCompletedPlansOnlyToggle}
            />
          </StyledLeftSideSwitchLabel>
        </div>
      )}
      {!isSmAndDown && (
        <FilterDropdownContainer>
          <FilterLabel variant="body1">{label}</FilterLabel>
          <FilterDropdown
            getOptionId={option => option.id}
            getOptionText={option => `${option.translationString}`}
            onChange={onChange}
            options={options}
            renderOption={option => `${option.translationString}`}
            value={value}
          />
        </FilterDropdownContainer>
      )}
    </SortFilterContainer>
  );
};

const StyledLeftSideSwitchLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray7};
`;

const ClearButton = styled(Link)`
  text-decoration: underline !important;
  text-wrap: nowrap;
`;

const SortFilterLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const SortFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

const FilterDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const FilterLabel = styled(Typography)`
  text-wrap: nowrap;
`;

const FilterDropdown = styled(Dropdown)`
  width: 160px;
  & > label {
    display: none;
  }
`;

const StyledCompletedOnlySwitch = styled(Switch)`
  margin-left: 16px;
`;

SortFilterHeader.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  onClear: PropTypes.func.isRequired,
  showClear: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      translationString: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  showCompletedPlansOption: PropTypes.bool,
  showMyCompletedPlansOnlySwitchValue: PropTypes.bool,
  handleOnCompletedPlansOnlyToggle: PropTypes.func,
};

SortFilterHeader.defaultProps = {
  value: {},
  showClear: false,
  className: '',
  'data-testid': 'SearchFilterHeader',
  showCompletedPlansOption: false,
  showMyCompletedPlansOnlySwitchValue: false,
  handleOnCompletedPlansOnlyToggle: () => {},
};

export default SortFilterHeader;
