export default {
  Countries: {
    US: 'Estados Unidos de América',
    CA: 'Canadá',
    PR: 'Puerto Rico',
    iconLabel: {
      PR: 'PR',
      CA: 'Canadá',
    },
  },
  Admin: {
    addCategory: 'Add Category',
    addIcon: 'Add Icon',
    addRecommended: 'Add Recommended Category',
    addSubcategory: 'Add Subcategory',
    addSection: 'Add Section',
    addResource: 'Add Resource',
    addSectionNamePlaceholder: 'Enter Name',
    addSectionTranslationPlaceholder: 'Enter Translation',
    browse: 'Browse',
    CA: 'Canada',
    cancelEditCategoryModalBody:
      'Are you sure you want to cancel? All unsaved changes will be lost.',
    categories: 'Categories',
    category: 'Category',
    categoryName: 'Category Name',
    categoryIsVisible: 'Visible',
    categoryHidden: 'Hidden',
    deleteCategoryModalBody:
      'Are you sure you want to delete the <strong>{{categoryName}}</strong> category?',
    deleteCategoryModalHeader: 'Delete Category',
    deleteCategoryToast: '{{categoryName}} has been successfully deleted.',
    deleteRecommendedCategoryModalBody:
      'Are you sure you want to delete the <strong>{{categoryName}}</strong> recommended category?',
    deleteRecommendedCategoryModalHeader: 'Delete Recommended Category',
    deleteResource:
      'Are you sure you want to delete the <strong>{{documentName}}</strong> resource?',
    deleteResourceHeader: 'Delete Resource',
    deleteSectionModalHeader: 'Delete Section',
    deleteSubcategoryModalBody:
      'Are you sure you want to delete the <strong>{{subcategoryName}}</strong> subcategory?',
    deleteSubcategoryModalHeader: 'Delete Subcategory',
    deleteSubcategoryToast:
      '{{subcategoryName}} has been successfully deleted.',
    deleteSectionModalBody:
      'Are you sure you want to delete the <strong>{{sectionName}}</strong> section?',
    editIcon: 'Edit Icon',
    makeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category from all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category immediately from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenHeader: 'Make Hidden',
    makeHiddenSubcategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subcategory from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenSubtitleBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subtitle from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category available to all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category immediately available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleHeader: 'Make Visible',
    makeVisibleSubcategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subcategory available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleSubtitleBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subtitle available to all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenDocumentBody:
      'Are you sure you want to hide the <strong>{{documentName}}</strong> resource from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleDocumentBody:
      'Are you sure you want to make the <strong>{{documentName}}</strong> resource available to all <strong>{{countryName}}</strong> Team Members?',
    managingCountry: 'Managing Country',
    PR: 'Puerto Rico',
    recommended: 'Recommended',
    recommendedCategory: 'Recommended Category',
    searchIcons: 'Search Icons',
    searchForIcons: 'Search for icons',
    sectionName: 'Section Name',
    selectIcon: 'Select Icon',
    spanishTranslation: 'Spanish Translation',
    subcategory: 'Subcategory',
    subcategories: 'Subcategories',
    updateCategoryToast: '{{categoryName}} has been successfully updated.',
    US: 'United States of America',
    warning: 'Warning',
  },
  Browse: {
    categories: 'Categorías',
    recommended: 'Recomendado',
  },
  Button: {
    add: 'Agregar',
    addEllipsis: 'Agregar...',
    addOption: 'Añadir Opción',
    addQuestion: 'Añadir Pregunta',
    addTeamMembers: '+ Agregar Miembro del Equipo',
    back: 'Volver',
    cancel: 'Cancelar',
    clearAll: 'Borrar Todo',
    close: 'Cerrar',
    complete: 'Completar',
    completed: 'Completado',
    complianceTraining: 'Capacitación sobre Cumplimiento en Pathway 1.0',
    contactSupport: 'Contacte a CFA HELP',
    continue: 'Continuar',
    copy: 'Copiar',
    create: 'Crear',
    createPlan: 'Crear Plan',
    delete: 'Eliminar',
    deleteQuiz: 'Eliminar Cuestionario',
    done: 'Hecho',
    duplicate: 'Duplicar',
    edit: 'Editar',
    enablePlan: 'Habilitar Plan',
    exit: 'Salir',
    exitTrainingMode: 'Salir del Modo de Capacitación',
    iUnderstand: 'Comprendo',
    loadMore: 'Cargar Más',
    markCompleted: 'Marcar como Completado',
    next: 'Siguiente',
    no: 'No',
    ok: 'Aceptar',
    okay: 'Muy Bien',
    preview: 'Vista Previa',
    print: 'Imprimir',
    printReport: 'Imprimir Reporte',
    reloadThePage: 'Volver a cargar la página',
    retake: 'Volver a Realizar el Cuestionario',
    returnToCfa: 'Volver a @CFA',
    returnToHomepage: 'Volver a la Página de Inicio',
    returnToPreviousPage: 'Volver a la Página Anterior',
    restartTraining: 'Reiniciar la Capacitación',
    save: 'Guardar',
    saveQuiz: 'Guardar Cuestionario',
    select: 'Seleccionar',
    selectAll: 'Seleccionar Todo',
    stay: 'Permanecer Aquí',
    submit: 'Enviar',
    trainingMode: 'Modo de Capacitación',
    translateAll: 'Traducir Todo',
    unassign: 'Desasignar',
    update: 'Actualización',
    updateDueDate: 'Actualizar fecha de vencimiento',
    wait: 'Esperar',
    yes: 'Sí',
    yesCancelChanges: 'Sí, Cancelar Cambios',
    yesClose: 'Sí, Cerrar',
    yesDelete: 'Sí, Eliminar',
    yesMakeHidden: 'Sí, Ocultar',
    yesMakeVisible: 'Sí, Hacer Visible',
  },
  Generic: {
    admin: 'Admin',
    answer: 'Respuesta',
    around: 'Alrededor',
    appName: 'Pathway',
    ascend: 'Ascender',
    assign: 'Asignar Plan',
    cantUndoAction: 'No puede deshacer esta acción.',
    chooseLocation: 'Elegir Ubicación/Ubicaciones',
    clearSearch: 'Borrar Búsqueda',
    comingSoon: 'Próximamente...',
    edit: 'editar',
    enablePlan: 'Activar Plan',
    game: 'juego',
    comingSoon2: 'Próximamente',
    hour: 'hora',
    inProgress: 'En curso',
    items: 'Elementos',
    itemsCompleted: 'Elementos Completados',
    of: 'de',
    link: 'Enlace',
    locations: 'Ubicaciones',
    markCompleted: 'Marcar como Completado',
    editTranslations: 'Editar Traducciones',
    leadership: 'Liderazgo',
    marketTest: 'Prueba',
    mins: 'minutos',
    menu: 'Menú',
    na: 'N/A',
    name: 'Nombre',
    notStarted: 'No iniciado',
    note: 'Nota',
    notes: 'Notas',
    operations: 'Operaciones',
    plan: 'Nombre del Plan',
    planCompleted: 'Plan Completado',
    popupArials: {
      labelledby: 'PopUpLabel',
      describedby: 'PopUpDesc',
    },
    printDocument: 'Imprimir Documento',
    resource: 'Recurso',
    restartTraining: 'Reiniciar Capacitación',
    percentCompleted: '{{percent}}% Completado',
    profile: 'Perfil',
    procedure: 'Procedimiento',
    question: 'Pregunta',
    quiz: 'Cuestionario',
    quizName: 'Nombre de Cuestionario',
    search: 'Buscar',
    seeAll: 'Ver Todo',
    section: 'Sección',
    settings: 'Configuración',
    task: 'Tarea',
    teamMember: 'Miembro del Equipo',
    teamMembers: 'Miembros del Equipo',
    teamMembersCompleted: 'Miembros del Equipo Completados',
    this: 'Este',
    trainingMode: 'Modo de Capacitación',
    unassign: 'Desasignar',
    url: 'URL',
    video: 'Video',
  },
  GenericError: {
    cfaLogo: 'Logo de Chick-fil-A',
    contactSupport: 'comuníquese con el Servicio al Cliente',
    errorHeader: 'Error',
    ifPersist: 'Si el problema persiste,',
    oops: 'Lo sentimos, algo salió mal',
    or: 'o',
    reloadPage: 'Volver a cargar página',
    reloadThisPage: 'Volver a cargar esta página',
    startOrder: 'Iniciar un nuevo pedido',
    tryReload: 'Intente volver a cargar la página.',
    problemCompletingAction:
      'Hubo un problema al completar esta acción. (Código de error: 403)',
  },
  Input: {
    requiredMessage: 'es un campo obligatorio.',
    Errors: {
      task: 'El nombre de la tarea no puede estar vacìo.',
      url: 'Por favor introduzca un URL válido.',
    },
  },
  InvalidPlanName: {
    errorHeader: 'Nombre de Plan no Válido',
    paragraphText: 'Los planes deben tener un nombre.',
  },
  InvalidQuizName: {
    errorHeader: 'Nombre de Cuestionario no Válido',
    paragraphText: 'Los cuestionarios deben tener un nombre.',
  },
  Language: {
    language: 'Idioma',
    english_language_code: 'en',
    spanish_language_code: 'es',
    english: 'Inglés',
    spanish: 'Español',
  },
  LoadingResourceError: {
    errorHeader: 'Error',
    errorParagraph: 'Hubo un problema al cargar el recurso que está buscando.',
    unauthorizedHeader: 'No Autorizado',
    unauthorizedParagraph:
      'No tiene autorización para ver este recurso. Consulte con su operador o líder para obtener más información.',
  },
  Leadership: {
    classLength: 'Largo',
    hi: 'Hola',
    headerText: '¿Qué le gustaría aprender hoy?',
    learnMore: 'Más Información',
    goThere: 'Ir allí',
    episodes: 'Habilidades',
    cohortHeader: 'Grupos de Aprendizaje Virtual',
    cohortText:
      'Aprendizaje grupal en línea para líderes de restaurantes que explora las habilidades operativas y de liderazgo',
    continueWatching: 'Continuar Viendo',
    season: 'Colección',
    operator: 'Operador',
    pick: 'Elegir',
  },
  NotFound: {
    button: 'Volver al Inicio',
    error: '404',
    message: 'No se encontró la página',
  },
  Settings: {
    complianceDisable:
      '¿Está seguro de que desea desactivar el <strong>{{name}}</strong>? <br /> <br /> Se desactivará para todas las ubicaciones bajo su Operador. <br /> <br /> Revise todas las regulaciones locales y estatales para asegurarse de que su Restaurante cumpla con las mismas.',
    complianceEnable:
      '¿Está seguro de que desea activar el <strong>{{name}}</strong>?<br /><br /> Se activará para todas las ubicaciones bajo su Operador.',
    compliancePlans: 'Planes de Cumplimiento',
    disabled: 'Desactivado',
    disablePlan: 'Desactivar plan',
    enabled: 'Activado',
    enabledPlan: 'Activar plan',
    logout: 'Cerrar sesión',
    status: 'Estado: ',
    needAssistance: '¿Necesita asistencia?',
    setCountry: 'Configurar País',
  },
  ResourceNotFoundError: {
    errorHeader: 'Error',
    errorParagraph:
      'No se encontró este recurso. Comuníquese con Chick-fil-A HELP en línea o llame al <a href="tel:+1-800-232-2677">1-800-232-2677</a>.',
  },
  Search: {
    filterBy: 'Filtrar:',
    historyRemove: 'Eliminar del historial de búsqueda',
    historySearchFor: 'Buscar',
    noResults: 'No se encontraron resultados',
    numberOfResultsFor: 'resultados para',
    rateResult: 'Califique este resultado de búsqueda',
    restaurantContentTypes: {
      lbm: 'LBM',
      stc: 'STC',
    },
    resultText: 'resultado',
    resultsText: 'resultados',
    searchForResources: 'Buscar recursos',
    showingResultsFooter:
      'Mostrando {{showingResults}} de {{numberOfResults}} {{resultsText}}',
    showingResultsHeader: 'Resultados de Búsqueda',
    showingResultsSuggestion: '¿Quiere decir?',
    submitFeedback: '¡Gracias por su ayuda!',
    teamMemberText: 'Miembro del Equipo',
    teamMembersText: 'Miembros del Equipo',
  },
  TrainingPlans: {
    accountability: {
      due: {
        completedDueDate: 'Completado: {{dueDate}}',
        dueDate: '{{dueDate}}',
        noDueDate: 'Sin fecha de vencimiento',
        oneDay: 'Vence en 1 día',
        oneWeek: 'Vence en 1 semana',
        overdue: 'Vencido',
        xDays: 'Vence en {{daysLeft}} días',
        xWeeks: 'Vence en {{weeksLeft}} semanas',
      },
      enterDueDate: 'Ingrese la Fecha de Vencimiento',
      setDueDate: 'Establecer Fecha de Vencimiento',
      timeSpentOnPlan: 'Tiempo Empleado en el Plan',
      itemsCompleted: 'Elementos Completados',
      itemsCompletedIn: 'Elementos Completados en',
      percentCompleted: '% completado',
      planCompletedIn: 'Plan Completado en',
      toComplete: 'para completar',
      timeEstimationTooltip: 'El tiempo debe introducirse en minutos',
      enterTime: 'Introducir hora',
      estimatedTimeToComplete: 'Tiempo Estimado Para Completar El Plan',
    },
    addPlanMenu: {
      chooseOperator: 'Elegir Operador',
      customPlan: 'Plan Personalizado',
      templatePlan: 'Plan de la Biblioteca de Plantillas',
      chooseLocations: 'Elegir Ubicación/Ubicaciones',
      whichOperator: '¿Para qué operador es el plan de capacitación?',
    },
    addResource: {
      clearSearch: 'Añadir Recurso borrar búsqueda',
      placeholder: 'Buscar cualquier cosa',
      title: 'Recursos',
    },
    addQuiz: {
      title: 'Biblioteca de Cuestionarios',
    },
    previewQuiz: {
      title: 'Vista previa: {{quizName}}',
      correctAnswer: 'Correcto',
      noQuestions: 'No se encontraron preguntas en el cuestionario.',
      noQuestionsHelpText:
        'Todavía puede agregar este cuestionario a un plan y agregar preguntas más tarde.',
    },
    addTaskFormPlaceholderText: {
      task: 'Escriba la tarea aquí',
      notes: 'Introducir Nota',
      link: 'Introducir URL',
    },
    assigned: 'Asignado',
    buildQuizzes: {
      addToTrainingPlan:
        'Todos los cuestionarios deben formar parte de un Plan de capacitación existente. ¿Desea agregar este cuestionario a un Plan de capacitación ahora?',
      buildQuestionMultipleChoice:
        'Ingrese las opciones y elija la respuesta correcta.',
      buildQuestionTrueFalse: 'Elija la respuesta correcta.',
      cancelBuilding: 'Cancelar Armado de Cuestionario',
      cancelBuildText1:
        '¿Está seguro de que desea cancelar la preparación del <strong>{{quizName}}</strong> Cuestionario?',
      cancelBuildText2: 'Se perderán todos los datos.',
      cancelEditText1:
        '¿Está seguro de que desea cancelar la edición del <strong>{{quizName}}</strong> Cuestionario?',
      cancelEditText2: 'Se perdern todos los datos no guardados.',
      changeQuizName: 'Asigne un nuevo nombre a su cuestionario',
      chooseOperator: 'Elegir Operador',
      correctAnswer: 'Respuesta correcta',
      correctQuestionsThreshold:
        '¿Cuántas preguntas deben responderse correctamente para aprobar el cuestionario?',
      createQuiz: 'Haga clic en + para crear el cuestionario.',
      createQuizHover: 'Crear Cuestionario',
      deleteQuestion: 'Eliminar Pregunta',
      deleteQuestionText:
        '¿Está seguro de que desea eliminar esta pregunta permanentemente?',
      deleteQuiz:
        'Est seguro de que desea eliminar el cuestionario <strong>{{quizName}}</strong>? Eliminar este cuestionario tambièn lo eliminar de cualquier plan de entrenamiento del que forme parte.',
      enterAnswer: 'Ingresar Respuesta',
      enterQuestion: 'Ingresar Pregunta',
      errorAnswer: 'Ingrese la opción de respuesta',
      errorSelectCorrectAnswer: 'Seleccione la respuesta correcta',
      errorSelectPassingGrade: 'Seleccione',
      errorQuestion: 'Ingrese la opción de respuesta',
      giveQuizName: 'Asigne un nombre a su cuestionario',
      manageQuiz: 'Gestionar Cuestionario',
      missingRequiredFields: 'Faltan Campos Obligatorios',
      multipleChoice: 'Opción Múltiple',
      noQuizzes: 'Aún no hay cuestionarios.',
      option: 'Opción',
      pleaseNote: 'Tenga en cuenta lo siguiente',
      question: 'Pregunta',
      quizNamePlaceholder: 'Ingrese el nombre del cuestionario',
      saveBeforeRenamingQuiz:
        'Aseg`rese de que todos los campos obligatorios estèn completos y guardados antes de cambiar el nombre de su cuestionario.',
      saveBeforeTranslatingQuiz:
        'Aseg`rese de que todos los campos obligatorios estèn completos y guardados antes de traducir su cuestionario.',
      trueOrFalse: 'Verdadero o Falso',
      validateRequiredFields:
        'Asegúrese de que todos los campos obligatorios estén completos antes de guardar su cuestionario.',
      whichOperators: '¿Para qué Operador es este cuestionario?',
    },
    changePlanName: 'Dar un nuevo nombre a su plan',
    completedIn: 'Completado En',
    completedPlans: 'Planes Completados',
    compliancePlans: 'Planes de Cumplimiento',
    compliancePlansTooltip:
      'Para tomar un plan de cumplimiento, su Operador o Líder necesita habilitar el/los plan(es) en Configuración.',
    createDuplicate: 'Crear Duplicado',
    createPlan: 'Haga clic en el signo + para crear su primer plan',
    createPlanPlaceholderText: 'Mi Plan de Capacitación 1',
    currentPlans: 'Planes Actuales',
    deleteButton: 'Eliminar {{type}}',
    deletePlanText: '¿Está seguro de que desea eliminar el',
    deletePlanText2: 'plan?',
    deletePlanTextNew:
      '¿Está seguro de que desea eliminar el Plan <strong>{{planName}}</strong>? No puede deshacer esta acción.',
    deleteSectionItems: 'Se eliminarán todos los elementos de esta sección.',
    deleteSectionText:
      '¿Está seguro de que desea eliminar toda la sección <strong>{{activeSectionName}}</strong>?<br/> Se eliminarán todos los elementos de la sección.',
    deleteTaskItems: 'Este elemento de tarea se eliminará.',
    deleteTaskText:
      '¿Está seguro de que desea eliminar permanentemente el <strong>{{activeDeleteTaskName}}</strong> {{type}}?',
    deleteTaskToast: '{{taskName}} se eliminó correctamente.',
    duplicatePlanInputCopy: '- Copiar',
    filtering: {
      filters: 'Filtros',
      plans: 'Planes',
      sortBy: 'Ordenar por:',
      searchBuildPlans: 'Buscar Armar Planes',
      searchManagePlans: 'Buscar Gestionar Planes',
      searchMyPlans: 'Buscar Mis Planes',
      filterAndSort: 'Filtrar y Ordenar',
      show: 'Mostrar',
      results: 'Resultados',
      aToZ: 'A-Z',
      zToA: 'Z-A',
      newest: 'Más reciente',
      oldest: 'Más antiguo',
      dueDate: 'Fecha Límite',
      teamMembers: 'Miembros del Equipo',
      includeCompleted: 'Incluir completado:',
    },
    givePlanName: 'Dar un nombre a su Plan',
    managePlan: 'Gestionar Plan',
    manageThreeDotMenu: {
      delete: 'Eliminar Plan',
      deleteResource: 'Eliminar Recurso',
      deleteQuiz: 'Eliminar Cuestionario',
      deleteSection: 'Eliminar sección',
      deleteTask: 'Eliminar Tarea',
      duplicate: 'Dupilcar Plan',
      editResource: 'Editar Recurso',
      editSection: 'Editar sección',
      editTask: 'Editar Tarea',
      locations: 'Elegir Ubicación',
      rename: 'Cambiar Nombre del Plan',
      renameQuiz: 'Cambiar Nombre del Cuestionario',
      renameSection: 'Cambiar Nombre de la Sección',
      trainingMode: 'Modo de Capacitación',
    },
    manageTranslations: {
      answerTranslationPlaceholder: 'Respuesta Traducida',
      notesTranslationPlaceholder: 'Notas Traducidas',
      nameTranslationPlaceholder: 'Nombre Traducido',
      noExistingTranslation: 'Sin traducción al inglés',
      questionTranslationPlaceholder: 'Pregunta Traducida',
      sectionTranslationPlaceholder: 'Sección Traducida',
      translateTocurrentLanguage: 'Traducir al Inglés',
      currentLanguageTranslation: 'Traducción al Inglés',
      taskTranslationPlaceholder: 'Tarea Traducida',
      translateAllTooltip:
        'Se traducirán todos los elementos y se sobrescribirá TODO el texto existente',
      urlTranslationPlaceholder: 'Introducir URL Alternativa',
      closeEditTranslations: 'Cerrar Editar Traducciones',
      confirmCloseParagraph:
        '¿Está seguro de que desea cerrar <strong>Editar Traducciones</strong>? Se perderán todas las actualizaciones.',
    },
    markCompleted1: '¿Está seguro de que desea marcar el',
    markCompleted2: 'plan como completado?',
    noCompletedPlans: 'No se han completado planes',
    noCurrentPlans: 'No hay planes asignados a usted',
    noResource:
      'Este recurso ya no está disponible.  Comuníquese con su Líder.',
    noResourceBuild:
      'Este recurso ya no está disponible.  Elimínelo de su plan de capacitación y comuníquese con el Centro de AYUDA si tiene alguna pregunta.',
    noResourceReference:
      'No hay una identificación de referencia asignada a este recurso',
    noSections:
      'Aún no tiene secciones, recursos ni tareas.  Agréguelos con el botón + a continuación',
    noTeamMembersAssigned:
      'Aún no tiene Miembros del Equipo asignados a este Plan de Capacitación',
    noTrainingPlans: 'Aún no tiene Planes de Capacitación',
    planCategories: {
      default: 'Valor predeterminado',
      backOfHouse: 'Trastienda',
      compliance: 'Cumplimiento',
      frontOfHouse: 'Área de atención al público',
      leadership: 'Liderazgo',
      hospitality: 'Hospitalidad',
      onboarding: 'Incorporación',
    },
    planNamePlaceholder: 'Ingrese un nombre de plan',
    preview: 'Vista previa',
    quizzes: {
      calculatingQuiz: 'Calculando cuestionario',
      congratulations: '¡Felicidades!',
      quizFail:
        'Lo sentimos, no aprobó. Vuelva a realizar el cuestionario. Debe aprobar para completar su capacitación.',
      quizPassed: 'Aprobado',
      quizResultsDelayed:
        'Lamentamos que los resultados del cuestionario estén tardando más de lo normal en calcularse.',
      quizResultsIssue:
        'Lo sentimos, pero este cuestionario tuvo un problema; intente responder el cuestionario de nuevo.',
      quizSuccessPopup: 'Felicitaciones, aprobó con un puntaje de {{score}}%',
      quizWaitingForResults: 'Espere mientras se calculan sus resultados...',
      youDidNotPass: 'No aprobó',
    },
    restartPlan1:
      '¿Está seguro de que desea que este Miembro del Equipo reinicie el',
    restartPlan2:
      'plan? Esto restablecerá su progreso a 0 elementos completados.',
    requiredValue: 'es obligatorio',
    sectionName: 'Nombre de la Sección',
    searchbarPlaceholder: 'Título de la sección',
    showingXOfYPlans: 'Presentación {{showing}} de {{total}} Planes',
    showingXOfYQuizzes: 'Presentación {{showing}} de {{total}} Cuestionarios',
    showingXOfYTeamMembers:
      'Presentación de {{showing}} de {{total}} Miembros del Equipo',
    section: {
      addSectionItem: '+ Agregar ...',
      sectionItems: {
        addResource: 'Recursos',
        addTask: 'Tarea',
        addQuiz: 'Cuestionario',
      },
      addSection: '+ Agregar sección',
    },
    selectCategory: 'Seleccionar Categoría',
    enterDueDate: 'Ingrese la Fecha de Vencimiento',
    setDueDate: 'Establecer Fecha de Vencimiento',
    statusOptions: {
      completed: 'Completado',
      inProgress: 'En curso',
      locations: 'Ubicaciones',
      status: 'Estado',
    },
    tabAscend: 'Ascender',
    tabBuild: 'Crear Planes',
    tabExplore: 'Explorar',
    tabBuildQuizzes: 'Preparar Cuestionarios',
    tabMy: 'Mis Planes',
    tabManagePlans: 'Gestionar Planes',
    tabPlans: 'Planes',
    tasksCompleted: 'Tarea completada',
    teamMembers: {
      count: 'Miembros del Equipo - ',
      searchbarPlaceholder: 'Buscar Miembros del Equipo',
      unassignConfirmation:
        '¿Está seguro de que desea desasignar a {{name}}?  Este plan ya no estará disponible.',
    },
    teamMembersCount: 'Miembros del Equipo',
    templates: {
      templateModal: 'Biblioteca de Plantillas',
    },
    timeSpentOnPlanTooltip:
      'Esta es una aproximación del tiempo total necesario para completar este plan.',
    toastMessage: {
      assignedTeamMember:
        'Ha asignado correctamente 1 Miembro del Equipo a este plan.',
      assignedTeamMembers:
        'Ha asignado correctamente {{count}} Miembros del Equipo a este plan.',
      completed1: 'Ha completado correctamente el',
      completed2: 'Plan de Capacitación',
      created: 'se creó correctamente.',
      deleted: 'se eliminó correctamente.',
      duplicated: 'se duplicó correctamente.',
      enabled: 'Se ha habilitado correctamente el {{courseName}}.',
      renamed: 'se cambió el nombre correctamente.',
      sectionDeleted: 'la sección se ha eliminado correctamente.',
      unassigned:
        'Se ha desasignado correctamente a {{teamMember}} de este plan.',
      updated: 'se actualizó correctamente',
      updateDueDate:
        'La fecha límite para {{teamMember}} se ha actualizado correctamente.',
    },
    trainingMode: {
      completedPlanModal:
        'Ha completado con éxito el Plan de Capacitación <strong>{{planName}}</strong>.  Entregue el dispositivo a su líder.',
      enterTrainingModeModal:
        'Ahora está ingresando al Modo de capacitación.  Si decide entregar su dispositivo a un Miembro del Equipo, este puede acceder a cualquier página de Pathway 2.0 o aplicación de Chick-Fil-A desde sus credenciales si sale de la experiencia del Modo de capacitación.',
      estimatedCompletionTime:
        'Tiempo estimado de finalización: {{estimatedTime}}',
      exitModal:
        'Su progreso se guardó y ahora saldrá del Modo de capacitación.  Devuelva el dispositivo a su Líder.',
      exitTrainingMode: 'Salir del Modo de Capacitación',
      selectLanguage: '¿En qué idioma desea que se muestre este plan?',
      toolTip:
        'El Modo de capacitación le permite capacitar a varios Miembros del Equipo a la vez o completar elementos en nombre de un Miembro del Equipo. Para iniciar el Modo de capacitación, asigne al menos un Miembro del Equipo que no haya completado este plan.',
    },
    trainingPlans: 'Planes de Capacitación',
    translationToastText: 'se tradujo correctamente a',
    storePlans: 'Almacenar Planes',
    whichLocations: '¿Para qué ubicación(es) es este plan?',
    whichLocationsPrintReport:
      '¿Para qué ubicación(es) desea imprimir este informe?',
    whichOperator: '¿Para qué operador es el plan de capacitación?',
  },
  UserNotAssignedToAppError: {
    errorHeader: 'No autorizado',
    errorParagraph:
      'No puede acceder a Pathway 2.0 porque no tiene asignada esta aplicación en Okta. <strong>Inicie sesión con una cuenta autorizada.</strong>',
  },
  UserNotAssignedToRoleError: {
    errorHeader: 'No autorizado',
    errorParagraph:
      'No puede acceder a esta página porque no tiene los permisos correctos asignados en TMUA. Consulte con su Operador o Líder para obtener más información.',
  },
};
