import Constants from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';

const applyFilters = ({ teamMembers, locationFilters, statusFilters }) => {
  let filteredTeamMembers = teamMembers;
  if (locationFilters?.length) {
    filteredTeamMembers = filteredTeamMembers?.filter(teamMember => {
      const { locations } = teamMember;
      const hasLocation = locations.some(location =>
        locationFilters.includes(location),
      );
      return hasLocation;
    });
  }
  if (statusFilters?.length) {
    filteredTeamMembers = filteredTeamMembers?.filter(teamMember => {
      const { status } = teamMember;
      const hasStatus = statusFilters.includes(status);
      return hasStatus;
    });
  }
  return filteredTeamMembers;
};

const applySort = (teamMembers, sort) => {
  let sortedTeamMembers = teamMembers;
  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedTeamMembers = sortedTeamMembers?.toSorted((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedTeamMembers = sortedTeamMembers?.toSorted((a, b) =>
        b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.DUE_DATE:
      sortedTeamMembers = sortedTeamMembers?.toSorted((a, b) => {
        // We do this to ensure an undefined or null date sorts to the back of the list
        const dateA = +new Date(a.dueDate) || Number.MAX_SAFE_INTEGER;
        const dateB = +new Date(b.dueDate) || Number.MAX_SAFE_INTEGER;
        return dateA - dateB;
      });
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedTeamMembers;
};

const applyPagination = (teamMembers, page, pageSize) => {
  const total = teamMembers.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedTeamMembers = teamMembers.slice(0, showing);
  return { loadedTeamMembers, showing, total };
};

export const slice = createSlice({
  name: 'teamMembersFilter',
  initialState: {
    teamMembers: [],
    statusFilters: [],
    locationFilters: [],
    sort: Constants.PLANS_SORT_OPTIONS.A2Z,
    filteredTeamMembers: [],
    sortedTeamMembers: [],
    paginatedTeamMembers: [],
    page: 1,
    pageSize: 12,
    showing: 0,
  },
  reducers: {
    addTeamMembersStatusFilter: (state, action) => {
      state.statusFilters = [...state.statusFilters, action.payload.filter];
      state.filteredTeamMembers = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    addTeamMembersLocationFilter: (state, action) => {
      state.locationFilters = [...state.locationFilters, action.payload.filter];
      state.filteredTeamMembers = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    clearTeamMembersCheckboxFilters: state => {
      state.locationFilters = [];
      state.statusFilters = [];
      state.filteredTeamMembers = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    loadMorePlans: state => {
      state.page += 1;
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    removeTeamMembersFilter: (state, action) => {
      const { filter } = action.payload;
      if (state.locationFilters.includes(filter)) {
        state.locationFilters = state.locationFilters.filter(
          locationFilter => locationFilter !== filter,
        );
      }
      if (state.statusFilters.includes(filter)) {
        state.statusFilters = state.statusFilters.filter(
          statusFilter => statusFilter !== filter,
        );
      }

      state.filteredTeamMembers = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload.teamMembers ?? [];
      state.sortedTeamMembers = applySort(state.teamMembers, state.sort);
      state.filteredTeamMembers = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    setTeamMembersSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedTeamMembers = applySort(state.teamMembers, state.sort);
      state.filteredTeamMembers = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers,
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addTeamMembersLocationFilter,
  addTeamMembersStatusFilter,
  clearTeamMembersCheckboxFilters,
  loadMorePlans,
  removeTeamMembersFilter,
  setTeamMembers,
  setTeamMembersSort,
} = slice.actions;

export default slice.reducer;
