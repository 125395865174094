import {
  CategoryCompliance,
  CategoryDefault,
  CategoryFrontOfHouse,
  CategoryHospitality,
  CategoryLeadership,
  CategoryOnboarding,
  CatgoryBackOfHouse,
} from 'icons';
import Constants from '../constants';

export const mapCategoryToColor = (
  category = Constants.PLAN_CATEGORIES.DEFAULT,
  isDisabled,
) => {
  if (isDisabled === true) {
    return Constants.PLAN_CATEGORY_COLORS.IS_DISABLED;
  }
  switch (category) {
    case Constants.PLAN_CATEGORIES.DEFAULT:
      return Constants.PLAN_CATEGORY_COLORS.DEFAULT;
    case Constants.PLAN_CATEGORIES.BACK_OF_HOUSE:
      return Constants.PLAN_CATEGORY_COLORS.BACK_OF_HOUSE;
    case Constants.PLAN_CATEGORIES.COMPLIANCE:
      return Constants.PLAN_CATEGORY_COLORS.COMPLIANCE;
    case Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE:
      return Constants.PLAN_CATEGORY_COLORS.FRONT_OF_HOUSE;
    case Constants.PLAN_CATEGORIES.HOSPITALITY:
      return Constants.PLAN_CATEGORY_COLORS.HOSPITALITY;
    case Constants.PLAN_CATEGORIES.LEADERSHIP:
      return Constants.PLAN_CATEGORY_COLORS.LEADERSHIP;
    case Constants.PLAN_CATEGORIES.ONBOARDING:
      return Constants.PLAN_CATEGORY_COLORS.ONBOARDING;

    default:
      return Constants.PLAN_CATEGORY_COLORS.DEFAULT;
  }
};

export const mapCategoryToIcon = (
  category = Constants.PLAN_CATEGORIES.DEFAULT,
) => {
  switch (category) {
    case Constants.PLAN_CATEGORIES.DEFAULT:
      return <CategoryDefault />;
    case Constants.PLAN_CATEGORIES.BACK_OF_HOUSE:
      return <CatgoryBackOfHouse />;
    case Constants.PLAN_CATEGORIES.COMPLIANCE:
      return <CategoryCompliance />;
    case Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE:
      return <CategoryFrontOfHouse />;
    case Constants.PLAN_CATEGORIES.HOSPITALITY:
      return <CategoryHospitality />;
    case Constants.PLAN_CATEGORIES.LEADERSHIP:
      return <CategoryLeadership />;
    case Constants.PLAN_CATEGORIES.ONBOARDING:
      return <CategoryOnboarding />;
    default:
      return <CategoryDefault />;
  }
};

export const mapCategoryToAliasTranslation = (
  category = Constants.PLAN_CATEGORIES.DEFAULT,
) => {
  switch (category) {
    case Constants.PLAN_CATEGORIES.DEFAULT:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.DEFAULT;
    case Constants.PLAN_CATEGORIES.BACK_OF_HOUSE:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.BACK_OF_HOUSE;
    case Constants.PLAN_CATEGORIES.COMPLIANCE:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.COMPLIANCE;
    case Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.FRONT_OF_HOUSE;
    case Constants.PLAN_CATEGORIES.HOSPITALITY:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.HOSPITALITY;
    case Constants.PLAN_CATEGORIES.LEADERSHIP:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.LEADERSHIP;
    case Constants.PLAN_CATEGORIES.ONBOARDING:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.ONBOARDING;
    default:
      return Constants.PLAN_CATEGORY_ALIAS_TRANSLATIONS.DEFAULT;
  }
};
