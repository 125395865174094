import { createSelector } from 'reselect';

export const selectSubHeaderReducerState = state => state.subHeader;

export const selectSubHeader = createSelector(
  selectSubHeaderReducerState,
  state => state,
);

export const hasDropShadow = createSelector(
  selectSubHeaderReducerState,
  state => state.hasDropShadow,
);
