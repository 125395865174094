import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IconCircleCheckDisabled, IconCircleDisabled } from 'icons';
import {
  IconCircle,
  IconCircleCheck,
  IconAlertTriangleFilled,
  IconDeviceGamepad2,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'cfa-react-components';

const PlanCardProcedure = ({
  disabled,
  isActive,
  isComplete,
  isMissing,
  language,
  onClick,
  onCompleteToggle,
  statuses,
  step,
  user,
}) => {
  const [isCompleted, setIsCompleted] = useState(isComplete);
  const { t } = useTranslation();
  const hasNotes = !isEmpty(step?.note);
  const hasUrls = !isEmpty(step?.urls[0]?.urlSet);
  const canExpand = hasNotes || hasUrls;
  const userId = user?.adId ?? user?.userId ?? undefined;
  const userStatus = statuses
    ?.find(status => status.userId === userId)
    ?.steps?.find(statusStep => statusStep.stepId === step.id);
  const stepDuration = userStatus?.stepDuration
    ? Math.round(userStatus?.stepDuration / 60)
    : 0;
  const isGame = step?.reference?.type === Constants.DOCUMENT_TYPES.GAME;

  useEffect(() => {
    setIsCompleted(isComplete);
  }, [isComplete]);

  return (
    <>
      <TaskOrProcedureBody $isActive={isActive} $isDisabled={disabled}>
        {isCompleted ? (
          <IconWrapper
            $isDisabled={disabled}
            data-testid="StepStatusIcon"
            onClick={
              !disabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!disabled ? (
              <StyledIconCircleCheck />
            ) : (
              <IconCircleCheckDisabled />
            )}
          </IconWrapper>
        ) : (
          <IconWrapper
            $isDisabled={disabled}
            data-testid="StepStatusIcon"
            onClick={
              !disabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!disabled ? <StyledIconCircle /> : <DisabledOvalIcon />}
          </IconWrapper>
        )}
        <TypeAndNameWrapper
          $canExpand={canExpand}
          data-testid={`PlanStep${step.id}`}
          onClick={() => {
            step?.type === Constants.STEP_TYPES.DOCUMENT &&
              !disabled &&
              onClick();
          }}
        >
          <Typography data-testid="TaskTitle" variant="overline3">
            {isGame ? t('Generic.game') : t('Generic.resource')}
            {isCompleted &&
              user &&
              ` - ${t('TrainingPlans.completedIn')} ${stepDuration} ${t(
                'Generic.mins',
              )}`}
          </Typography>
          <>
            {isMissing ? (
              <StyledWarningWrapper>
                <IconAlertTriangleFilled size={16} />
                <StyledWarningMessage
                  data-testid="TaskOrProcedureName"
                  variant="body1"
                >
                  {t('TrainingPlans.noResource')}
                </StyledWarningMessage>
              </StyledWarningWrapper>
            ) : step?.type === Constants.STEP_TYPES.DOCUMENT ? (
              <Typography data-testid="TaskOrProcedureName" variant="body1">
                {getNameFromLanguage(step?.reference?.name, language)}
              </Typography>
            ) : (
              <Typography data-testid="TaskOrProcedureName" variant="body1">
                {getNameFromLanguage(step?.name, language)}
              </Typography>
            )}
          </>
        </TypeAndNameWrapper>
        {isGame && (
          <TaskAndProcedureIcon>
            <IconDeviceGamepad2 />
          </TaskAndProcedureIcon>
        )}
      </TaskOrProcedureBody>
    </>
  );
};

PlanCardProcedure.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  isMissing: PropTypes.bool,
  language: PropTypes.string,
  onClick: PropTypes.func,
  onCompleteToggle: PropTypes.func,
  statuses: PropTypes.array,
  step: PropTypes.object,
  user: PropTypes.object,
};

PlanCardProcedure.defaultProps = {
  disabled: false,
  isActive: false,
  isMissing: false,
  isComplete: false,
  language: '',
  onClick: () => {},
  onCompleteToggle: () => {},
  statuses: [],
  step: null,
  user: null,
};

const TaskOrProcedureBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
  padding-bottom: ${props => props.$isActive && '10px'};
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
`;

const StyledIconCircle = styled(IconCircle)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${({ theme }) => theme.semanticColors.success};
`;

const DisabledOvalIcon = styled(IconCircleDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const TypeAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${props => props.$canExpand && !props.$isDisabled && 'pointer'};
`;

const StyledWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const StyledWarningMessage = styled(Typography)`
  margin-left: 0.5em;
`;

const TaskAndProcedureIcon = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
  width: 25px;
  margin: 0 5px;
`;

export default PlanCardProcedure;
