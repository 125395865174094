import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalFooter } from 'cfa-react-components';
import { useEffect, useState } from 'react';

const ScrollModalFooter = ({ scrollRef, children, ...props }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const updateScrollStatus = () => {
      const scrollBottom =
        scrollRef.current?.scrollHeight -
        scrollRef.current?.clientHeight -
        scrollRef.current?.scrollTop;
      if (scrollBottom > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    scrollRef?.current?.addEventListener('scroll', updateScrollStatus);

    return () => window?.removeEventListener('scroll', updateScrollStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current]);

  return (
    <ScrollingModalFooter $isScrolled={isScrolled} {...props}>
      {children}
    </ScrollingModalFooter>
  );
};

const ScrollingModalFooter = styled(ModalFooter)`
  z-index: 5;
  box-shadow: ${({ $isScrolled, theme }) =>
    $isScrolled ? theme.boxShadow.elevation16 : 'none'};
`;

ScrollModalFooter.propTypes = {
  scrollRef: PropTypes.object.isRequired,
  children: PropTypes.node,
};

ScrollModalFooter.defaultProps = {
  children: null,
};
export default ScrollModalFooter;
