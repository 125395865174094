import Constants from 'constants/index';
import toPairs from 'lodash/toPairs';

export const encodeQueryString = pairs =>
  toPairs(pairs)
    .map(
      ([key, value]) =>
        encodeURIComponent(key) + '=' + encodeURIComponent(value),
    )
    .join('&');

export const getKeyValuePairsFromHash = location => {
  const hash = location.hash || '';
  if (hash.length) {
    const hashString = hash.substr(1);
    const replaceChars = decodeURI(hashString)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');
    try {
      return JSON.parse(`{"${replaceChars}"}`);
    } catch (e) {
      console.error('Error in getKeyValuePairsFromHash:', e);
      return {};
    }
  }
  return {};
};

export const objectToQueryString = obj =>
  Object.keys(obj)
    .map(key => [key, obj[key]].map(encodeURIComponent).join('='))
    .join('&');

export const isCurrentPath = ({
  location,
  isAdminPath,
  isAdminRootPath,
  isCategoriesPath,
  isCompliancePath,
  isDetailsPath,
  isExplorePath,
  isLeadershipPath,
  isRootPath,
  isSearchPath,
  isSettingsPath,
  isSignalPath,
  isTeamMemberPlan,
  isTrainingAssignedPath,
  isTrainingBuildPath,
  isTrainingBuildQuizzesPath,
  isTrainingEditQuizzesPath,
  isTrainingModePath,
  isTrainingMyPlansPath,
  isTrainingPath,
  isTrainingPlanEditPath,
  isTrainingPlanPath,
  isTrainingQuizzesPath,
  isTrainingTeamMemberProgressPath,
  isTrainingTeamMembersPath,
  isTrainingTeamPath,
  isTrainingToCompletePath,
}) => {
  const currPath = location.pathname.split('/').filter(p => p) ?? [];
  const routePaths = Constants.ROUTE_PATH_NAMES;

  if (isCategoriesPath) {
    return currPath[0] === routePaths.CATEGORY_PATH_NAME;
  } else if (isCompliancePath) {
    return currPath[0] === routePaths.COMPLIANCE_PATH_NAME;
  } else if (isLeadershipPath) {
    return currPath[0] === routePaths.LEADERSHIP_PATH_NAME;
  } else if (isSignalPath) {
    return currPath[0] === routePaths.HEADLESS_PATH_NAME;
  } else if (isTrainingPath) {
    return currPath[0] === routePaths.TRAINING_PATH_NAME;
  } else if (isTrainingBuildPath) {
    return currPath.length && currPath[1] === 'build';
  } else if (isTrainingModePath) {
    return currPath.length && currPath[1] === 'trainingMode';
  } else if (isTrainingTeamPath) {
    return currPath.length && currPath[1] === 'team';
  } else if (isTrainingBuildQuizzesPath) {
    return (
      currPath.length &&
      currPath[1] === routePaths.QUIZZES_PATH_NAME &&
      currPath[2] === routePaths.BUILD_PATH_NAME
    );
  } else if (isTrainingEditQuizzesPath) {
    return currPath.length && currPath[1] === routePaths.QUIZ_PATH_NAME;
  } else if (isTrainingQuizzesPath) {
    return currPath.length && currPath[1] === routePaths.QUIZZES_PATH_NAME;
  } else if (isTeamMemberPlan) {
    return currPath.length >= 4 && currPath[1] === 'team-members';
  } else if (isTrainingTeamMembersPath) {
    return currPath.length === 3 && currPath[2] === 'team-members';
  } else if (isTrainingTeamMemberProgressPath) {
    return currPath.length === 5 && currPath[2] === 'team-members';
  } else if (isTrainingMyPlansPath) {
    return (
      currPath[0] === routePaths.TRAINING_PATH_NAME && currPath.length === 1
    );
  } else if (isTrainingAssignedPath) {
    return (
      currPath.length &&
      currPath[1] === Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME
    );
  } else if (isTrainingToCompletePath) {
    return (
      currPath.length &&
      currPath[1] === Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME &&
      currPath[2] === 'toComplete'
    );
  } else if (isTrainingPlanEditPath) {
    return (
      currPath[0] === routePaths.TRAINING_PATH_NAME && currPath[1] === 'plan'
    );
  } else if (isSettingsPath) {
    return currPath[0] === routePaths.PROFILE_PATH_NAME;
  } else if (isExplorePath) {
    return (
      !currPath.length ||
      currPath[0] === routePaths.CATEGORY_PATH_NAME ||
      (currPath[0] === routePaths.DOCUMENT_PATH_NAME &&
        !location.state?.isCompliance)
    );
  } else if (isDetailsPath) {
    return currPath[0] === routePaths.DOCUMENT_PATH_NAME;
  } else if (isSearchPath) {
    return currPath[0] === routePaths.SEARCH_PATH_NAME;
  } else if (isRootPath) {
    return !currPath.length;
  } else if (isTrainingPlanPath) {
    return currPath.length && currPath[1] === 'plan';
  } else if (isAdminPath) {
    return currPath[0] === routePaths.ADMIN_PATH_NAME;
  } else if (isAdminRootPath) {
    return currPath === routePaths.ADMIN_PATH_NAME;
  }

  return false;
};

export const getClickableLink = link => {
  if (!link) {
    return;
  }

  return link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `http://${link}`;
};

export const isAdminPath = location =>
  isCurrentPath({
    location,
    isAdminPath: true,
  });
export const isCategoriesPath = location =>
  isCurrentPath({
    location,
    isCategoriesPath: true,
  });
export const isExplorePath = location =>
  isCurrentPath({
    location,
    isExplorePath: true,
  });
export const isLeadershipPath = location =>
  isCurrentPath({
    location,
    isLeadershipPath: true,
  });
export const isSettingsPath = location =>
  isCurrentPath({
    location,
    isSettingsPath: true,
  });
export const isRootPath = location =>
  isCurrentPath({
    location,
    isRootPath: true,
  });
export const isTeamMemberPlan = location =>
  isCurrentPath({
    location,
    isTeamMemberPlan: true,
  });
export const isTrainingAssignedPath = location =>
  isCurrentPath({
    location,
    isTrainingAssignedPath: true,
  });
export const isTrainingBuildPath = location =>
  isCurrentPath({
    location,
    isTrainingBuildPath: true,
  });
export const isTrainingModePath = location =>
  isCurrentPath({
    location,
    isTrainingModePath: true,
  });
export const isSignalPath = location =>
  isCurrentPath({
    location,
    isSignalPath: true,
  });
export const isTrainingMyPlansPath = location =>
  isCurrentPath({
    location,
    isTrainingMyPlansPath: true,
  });
export const isTrainingPath = location =>
  isCurrentPath({
    location,
    isTrainingPath: true,
  });
export const isTrainingPlanPath = location =>
  isCurrentPath({
    location,
    isTrainingPlanPath: true,
  });
export const isTrainingTeamPath = location =>
  isCurrentPath({
    location,
    isTrainingTeamPath: true,
  });
export const isTrainingQuizzesPath = location =>
  isCurrentPath({
    location,
    isTrainingQuizzesPath: true,
  });
export const isTrainingBuildQuizzesPath = location =>
  isCurrentPath({
    location,
    isTrainingBuildQuizzesPath: true,
  });
export const isTrainingEditQuizzesPath = location =>
  isCurrentPath({
    location,
    isTrainingEditQuizzesPath: true,
  });
export const isTrainingTeamMembersPath = location =>
  isCurrentPath({
    location,
    isTrainingTeamMembersPath: true,
  });
export const isTrainingTeamMemberProgressPath = location =>
  isCurrentPath({
    location,
    isTrainingTeamMemberProgressPath: true,
  });
export const isTrainingToCompletePath = location =>
  isCurrentPath({
    location,
    isTrainingToCompletePath: true,
  });
export const isSearchPath = location =>
  isCurrentPath({
    location,
    isSearchPath: true,
  });
