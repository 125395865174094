import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'threeDotMenu',
  initialState: {
    isOpen: false,
  },
  reducers: {
    showThreeDotMenu: state => {
      state.isOpen = true;
    },
    hideThreeDotMenu: state => {
      state.isOpen = false;
    },
  },
});

export const { showThreeDotMenu, hideThreeDotMenu } = slice.actions;

export default slice.reducer;
