import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  IconButton,
  PopoverMenu,
  PopoverMenuToggleButton,
  Surface,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { IconPlus } from '@tabler/icons-react';
import { useRef } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import theme from 'styles/theme';
import FabPopup from './FabPopup';

export const Fab = ({
  onClick,
  onClose,
  isOpen,
  children,
  hasMenu,
  tooltip,
}) => {
  const ref = useRef();
  useClickOutside(ref, () => {
    onClose();
  });

  const ContentFragment = () => {
    return (
      <>
        <Typography fontWeight="bold" variant="body2">
          {tooltip}
        </Typography>
      </>
    );
  };

  return (
    <PopoverMenu>
      <StyledAddButtonWrapper elevation={2} ref={ref}>
        {!!tooltip ? (
          <Tooltip
            content={<ContentFragment />}
            placement="left"
            showOnElementEvents={['hover']}
            style={{
              boxShadow: '0px 4px 12px 0px rgba(91, 103, 112, 0.24)',
              color: `${theme.grayScale.gray7}`,
            }}
          >
            <PopoverMenuToggleButton
              aria-label="Add Button"
              as={IconButton}
              color="tertiary"
              onClick={onClick}
              style={{ padding: '12px' }} // this is currently required because of a cfa-react-components and styled components bug
            >
              <AnimatedIconPlus
                $hasMenu={hasMenu}
                $isOpen={isOpen}
                height={32}
                width={32}
              />
            </PopoverMenuToggleButton>
          </Tooltip>
        ) : (
          <PopoverMenuToggleButton
            aria-label="Add Button"
            as={IconButton}
            color="tertiary"
            onClick={onClick}
            style={{ padding: '12px' }} // this is currently required because of a cfa-react-components and styled components bug
          >
            <AnimatedIconPlus
              $hasMenu={hasMenu}
              $isOpen={isOpen}
              height={32}
              width={32}
            />
          </PopoverMenuToggleButton>
        )}
      </StyledAddButtonWrapper>
      {children}
    </PopoverMenu>
  );
};

Fab.Popup = FabPopup;

Fab.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  hasMenu: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  tooltip: PropTypes.string,
};

Fab.defaultProps = {
  children: null,
  onClose: () => {},
  tooltip: null,
};

const StyledAddButtonWrapper = styled(Surface)`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 4;
  border-radius: 50% !important;
  background: ${props => props.theme.primaryPalette.navyBlue} !important;
`;

const AnimatedIconPlus = styled(IconPlus)`
  transition: transform 0.2s ease-in-out;
  transform: ${props =>
    props.$isOpen && props.$hasMenu ? 'rotate(45deg)' : 'rotate(0deg)'};
`;
