import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'printReport',
  initialState: {
    isOpen: false,
  },
  reducers: {
    hidePrintReport: state => {
      state.isOpen = false;
    },
    showPrintReport: state => {
      state.isOpen = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { hidePrintReport, showPrintReport } = slice.actions;

export default slice.reducer;
