import { useDeviceInfo } from 'util/device';
import Constants from 'constants/index';
import { Divider, Surface, Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StickyMenuCard = ({
  header,
  top,
  className,
  'data-testid': dataTestId,
  children,
}) => {
  const { isDesktop } = useDeviceInfo();
  return (
    <MenuCard
      $isDesktop={isDesktop}
      $top={top}
      className={className}
      data-testid={dataTestId}
      elevation={1}
      radii="sm"
      variant="outlined"
    >
      <Typography fontWeight="medium" variant="body1">
        {header}
      </Typography>
      <Divider variant="fullLength" />
      {children}
    </MenuCard>
  );
};

StickyMenuCard.propTypes = {
  header: PropTypes.string.isRequired,
  top: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  children: PropTypes.node,
};

StickyMenuCard.defaultProps = {
  top: '103px',
  className: '',
  'data-testid': 'StickyMenuCard',
  children: null,
};

const MenuCard = styled(Surface)`
  position: sticky;
  top: ${({ $top, $isDesktop }) =>
    $isDesktop ? $top : `calc(${$top} + ${Constants.HEIGHT.MOBILE_TOP_NAV})`};
  // this calc is to prevent the card from overflowing and also going over the topnav
  max-height: ${({ $top, $isDesktop }) =>
    $isDesktop
      ? `calc(100vh - ${$top} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.STICKY_MENU_MARGIN} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`
      : `calc(100vh - ${$top} - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.STICKY_MENU_MARGIN} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`};
  z-index: 4;
  width: 270px;
  min-width: 270px;
  flex-shrink: 1;
  padding: 16px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px 0;
  gap: 12px;
  overflow-y: auto;
`;

export default StickyMenuCard;
