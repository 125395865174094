import styled from 'styled-components';
import { Button } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showPrintReport } from 'store/printReport/slice';

const PrintReportButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <TrainingModeWrapper>
      <Button color="secondary" onClick={() => dispatch(showPrintReport())}>
        {t('Button.printReport')}
      </Button>
    </TrainingModeWrapper>
  );
};

const TrainingModeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
`;

export default PrintReportButton;
