import Constants from 'constants/index';
import { useGetDocumentQuery } from 'services/pathwayApi';

const useDocumentCookieRefresh = (documentId, isCompliance) => {
  useGetDocumentQuery(documentId ?? '', {
    pollingInterval: Constants.DOCUMENT_COOKIE_REFRESH.POLL_TIME_DEFAULT,
    skip: isCompliance,
  });
};

export default useDocumentCookieRefresh;
