import Constants from 'constants/index';
import {
  NavLink,
  SideNavigation,
  SideNavigationWrapper,
  SideNavigationContent,
  SideNavigationPageContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PathwayLogo } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NavbarDesktop = ({ children, tabs }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isLargeAndUp = useMediaQuery(breakpoints.up('lg'));
  const userPrefCountry = useSelector(state => state.user.country);

  return (
    <SideNavigationWrapper>
      <StyledSideNavigation $isLargeAndUp={isLargeAndUp}>
        <SideNavigationContentStart section="start">
          <PathwayLogoLink
            data-testid="PathwayLogo"
            title={t('Generic.appName')}
            to={`/`}
          >
            {userPrefCountry?.id === Constants.SUPPORTED_COUNTRIES.CA.id ? (
              <>
                <LeftNavLogo $isLargeAndUp={isLargeAndUp} />
                <Typography fontWeight="bold" variant="overline1">
                  {t(
                    `Countries.iconLabel.${Constants.SUPPORTED_COUNTRIES.CA.id}`,
                  )}
                </Typography>
              </>
            ) : userPrefCountry?.id === Constants.SUPPORTED_COUNTRIES.PR.id ? (
              <>
                <LeftNavLogo $isLargeAndUp={isLargeAndUp} />
                <Typography fontWeight="bold" variant="overline1">
                  {t(
                    `Countries.iconLabel.${Constants.SUPPORTED_COUNTRIES.PR.id}`,
                  )}
                </Typography>
              </>
            ) : (
              <>
                <LeftNavLogo $isLargeAndUp={isLargeAndUp} />
                <CountryPlaceholder />
              </>
            )}
          </PathwayLogoLink>
        </SideNavigationContentStart>
        <SideNavigationContentMiddle section="middle">
          {tabs.map((tab, index) => (
            <NavLink
              aria-label={`Navigate to ${tab.label}`}
              as={Link}
              data-testid={`Nav${tab.label}`}
              icon={tab.icon}
              isActive={tab.isActive}
              key={index}
              to={tab.to}
              variant="sidenavigation-link"
            >
              {tab.label}
            </NavLink>
          ))}
        </SideNavigationContentMiddle>
      </StyledSideNavigation>
      <SideNavigationPageContents $isLargeAndUp={isLargeAndUp}>
        {children}
      </SideNavigationPageContents>
    </SideNavigationWrapper>
  );
};

const SideNavigationPageContents = styled(SideNavigationPageContent)`
  ${({ $isLargeAndUp }) =>
    $isLargeAndUp && `margin-left: ${Constants.WIDTH.SIDE_NAV} !important;`}
`;

const StyledSideNavigation = styled(SideNavigation)`
  width: ${({ $isLargeAndUp }) =>
    $isLargeAndUp && `${Constants.WIDTH.SIDE_NAV} !important`};
`;

const SideNavigationContentStart = styled(SideNavigationContent)`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const SideNavigationContentMiddle = styled(SideNavigationContent)`
  display: flex;
  flex-direction: column;
`;

const LeftNavLogo = styled(PathwayLogo)`
  height: ${({ $isLargeAndUp }) => ($isLargeAndUp ? `72px` : `40px`)};
  width: ${({ $isLargeAndUp }) => ($isLargeAndUp ? `64px` : `36px`)};
`;

const PathwayLogoLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const CountryPlaceholder = styled.div`
  height: 24px;
`;

NavbarDesktop.propTypes = {
  children: PropTypes.node.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      isActive: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default NavbarDesktop;
