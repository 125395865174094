import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconFileCertificate } from '@tabler/icons-react';
import { IconCircleDisabled, IconCircleCheckDisabled } from 'icons';
import { Modal, ModalBody, Typography } from 'cfa-react-components';
import Bugsnag from '@bugsnag/browser';
import { Widget } from '@typeform/embed-react';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';

const PlanCardQuiz = ({
  disabled,
  isActive,
  isComplete,
  language,
  onClick,
  onRefetch,
  planId,
  section,
  statuses,
  step,
  users,
}) => {
  const { t } = useTranslation();
  const [lastPolledEventId, setLastPolledEventId] = useState(undefined);
  const [activeQuizScore, setActiveQuizScore] = useState(null);
  const [showPassPopup, setShowPassPopup] = useState(false);
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingRetry, setPollingRetry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showQuizResultsDelayed, setShowQuizResultsDelayed] = useState(false);
  const [showQuizResultsIssue, setShowQuizResultsIssue] = useState(false);

  const maxPollingCount = 7; // we poll every 2 seconds, this number would correspond to the time spent polling. 14 seconds at 7

  // list of status objects for current user(s)
  const userStatuses = statuses?.filter(status =>
    users?.find(id => status.userId === id),
  );

  // finds the status of first user taking the quiz that has not passed the quiz
  let activeQuizUserStatuses = userStatuses?.filter(userStatus =>
    userStatus?.steps?.find(
      stepStatus =>
        stepStatus.stepId === step.id &&
        stepStatus.result !== Constants.QUIZ_RESULTS.PASS,
    ),
  );
  if (activeQuizUserStatuses.length === 0) {
    activeQuizUserStatuses = userStatuses;
  }

  const currentUserStatus = activeQuizUserStatuses?.find(
    userStatus => userStatus.userId === users[0],
  );

  const quizStatus = currentUserStatus?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  );

  const stepDuration = Math.round((quizStatus?.stepDuration ?? 0) / 60);

  const currentStatusStep = activeQuizUserStatuses?.[0]?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  );

  const currentStepEventId = activeQuizUserStatuses?.[0]?.steps?.find(
    stepStatus => stepStatus.stepId === step.id,
  )?.eventId;

  useEffect(() => {
    if (isComplete) {
      return;
    }
    setLastPolledEventId(currentStepEventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuizSubmit = () => {
    setIsLoading(true);
    setPollingCount(1);
  };

  useEffect(() => {
    if (pollingCount > maxPollingCount) {
      if (pollingRetry) {
        setShowQuizResultsIssue(true);
        Bugsnag.notify(
          new Error(
            `Quiz error - quiz results issue: ${JSON.stringify({
              step,
              users,
            })}`,
          ),
        );
      } else {
        setShowQuizResultsDelayed(true);
        Bugsnag.notify(
          new Error(
            `Quiz error - quiz results delayed:: ${JSON.stringify({
              step,
              users,
            })}`,
          ),
        );
      }
    } else if (pollingCount) {
      const eventIdsAreSame = currentStepEventId === lastPolledEventId;
      if (eventIdsAreSame || !currentStepEventId) {
        setTimeout(() => {
          onRefetch();
          setPollingCount(prevState => prevState + 1);
        }, 2000);
      } else {
        setIsLoading(false);
        setPollingCount(0);
        setPollingRetry(false);
        setLastPolledEventId(currentStepEventId);
        if (currentStatusStep.result === Constants.QUIZ_RESULTS.PASS) {
          setActiveQuizScore(
            Math.round(
              (currentStatusStep.score / currentStatusStep.totalQuestions) *
                100,
            ),
          );
          setShowPassPopup(true);
        } else {
          setShowFailPopup(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingCount]);

  const handlePassContinue = () => {
    onRefetch();
    setShowPassPopup(false);
  };

  const handleFailContinue = () => {
    onRefetch();
    setShowFailPopup(false);
  };

  const handleRetakeQuiz = () => {
    setPollingCount(0);
    setIsLoading(false);
    setPollingRetry(false);
    setShowQuizResultsIssue(false);
    setShowQuizResultsDelayed(false);
    onRefetch();
  };

  return (
    <>
      <div>
        <TaskOrProcedureBody
          $isActive={isActive && !isComplete}
          $isDisabled={disabled}
          $isInSection={!!section}
        >
          {isComplete ? (
            <IconWrapper $isPlanComplete={true}>
              <IconCircleCheckDisabled />
            </IconWrapper>
          ) : (
            <DisabledIconWrapper>
              <IconCircleDisabled />
            </DisabledIconWrapper>
          )}
          <TypeAndNameWrapper $isDisabled={disabled} onClick={onClick}>
            <TypeHeader data-testid="TaskTitle" variant="overline3">
              {t('Generic.quiz')}
              {isComplete &&
                ` - ${t('TrainingPlans.completedIn')} ${stepDuration} ${t(
                  'Generic.mins',
                )}`}
            </TypeHeader>
            <Typography data-testid="QuizName" variant="body1">
              {getNameFromLanguage(step?.name, language)}
            </Typography>
          </TypeAndNameWrapper>
          <QuizIconWrapper $canExpand={!isComplete} onClick={onClick}>
            <IconFileCertificate />
          </QuizIconWrapper>
        </TaskOrProcedureBody>
        {isComplete && (
          <StyledPassMessage data-testid="PassMessage" variant="body1">
            {users.length === 1
              ? t('TrainingPlans.quizzes.quizSuccessPopup', {
                  score: `${Math.round(
                    (quizStatus.score / quizStatus.totalQuestions) * 100,
                  )}`,
                })
              : t('TrainingPlans.quizzes.quizPassed')}
          </StyledPassMessage>
        )}
      </div>
      {isActive && !isComplete && (
        <QuizWrapper>
          <StyledWidget
            disableScroll={true}
            hidden={{
              userIds: users.toString(),
              checklistId: planId,
              stepId: step.id,
              language: language,
            }}
            hideHeaders={true}
            id={step.quizId}
            onSubmit={() => {
              handleQuizSubmit(step.id);
            }}
          />
        </QuizWrapper>
      )}
      <ConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizSuccessPopup', {
          score: activeQuizScore,
        })}
        bodyTextColor="success"
        headerText={t('TrainingPlans.quizzes.congratulations')}
        isOpen={showPassPopup}
        onClose={handlePassContinue}
        primaryButtonHandler={handlePassContinue}
        primaryButtonText={t('Button.continue')}
      />
      <ConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizFail')}
        headerText={t('TrainingPlans.quizzes.youDidNotPass')}
        isOpen={showFailPopup}
        onClose={handleFailContinue}
        primaryButtonHandler={handleFailContinue}
        primaryButtonText={t('Button.continue')}
      />
      <ConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizResultsDelayed')}
        headerText={t('TrainingPlans.quizzes.calculatingQuiz')}
        isOpen={showQuizResultsDelayed}
        primaryButtonHandler={handleRetakeQuiz}
        primaryButtonText={t('Button.retake')}
        secondaryButtonHandler={() => {
          setPollingCount(1);
          setPollingRetry(true);
          setShowQuizResultsDelayed(false);
        }}
        secondaryButtonText={t('Button.wait')}
        showCloseButton={false}
      />
      <ConfirmationModal
        bodyText={t('TrainingPlans.quizzes.quizResultsIssue')}
        headerText={t('TrainingPlans.quizzes.calculatingQuiz')}
        isOpen={showQuizResultsIssue}
        primaryButtonHandler={handleRetakeQuiz}
        primaryButtonText={t('Button.retake')}
        showCloseButton={false}
      />
      <Modal show={isLoading}>
        <StyledModalBody>
          <Typography color="default" variant="h3">
            {t('TrainingPlans.quizzes.quizWaitingForResults')}
          </Typography>
        </StyledModalBody>
      </Modal>
    </>
  );
};

const TypeHeader = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;

const StyledModalBody = styled(ModalBody)`
  padding: 92px 133px;
`;

const TaskOrProcedureBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  cursor: ${props => !props.$isDisabled && 'pointer'};
  padding-bottom: ${props => props.$isActive && '10px'};
`;

const TypeAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${props => !props.$isDisabled && 'pointer'};
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: ${props => (!props.$isPlanComplete ? 'pointer' : 'default')};
`;

const DisabledIconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: 'default';
`;

const QuizWrapper = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
`;

const StyledPassMessage = styled(Typography)`
  margin-left: 41px;
  color: ${props => props.theme?.semanticColors?.success};
`;

const StyledWidget = styled(Widget)`
  width: 100%;
`;

const QuizIconWrapper = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
  display: flex;
  width: 25px;
  margin: 0 5px;
  cursor: ${props => props.$canExpand && 'pointer'};
`;

PlanCardQuiz.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  section: PropTypes.object,
  statuses: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

PlanCardQuiz.defaultProps = {
  disabled: false,
  section: null,
};

// memo to prevent re-renders when taking the quiz
export default memo(PlanCardQuiz, (prevProps, nextProps) => {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.isComplete === nextProps.isComplete &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.step === nextProps.step &&
    prevProps.statuses === nextProps.statuses &&
    prevProps.language === nextProps.language
  );
});
