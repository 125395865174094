import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';

const ValidationErrorPopup = ({
  showValidationError,
  setShowValidationError,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      bodyText={t('TrainingPlans.buildQuizzes.validateRequiredFields')}
      headerText={t('TrainingPlans.buildQuizzes.missingRequiredFields')}
      isOpen={showValidationError}
      onClose={() => setShowValidationError(false)}
      primaryButtonColor="secondary"
      primaryButtonHandler={() => setShowValidationError(false)}
      primaryButtonText={t('Button.okay')}
    />
  );
};

ValidationErrorPopup.propTypes = {
  showValidationError: PropTypes.bool.isRequired,
  setShowValidationError: PropTypes.func.isRequired,
};

export default ValidationErrorPopup;
