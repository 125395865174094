import { useEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';

export const sendNativeMessage = message => {
  const isReactNativeWebView = isFunction(
    window?.ReactNativeWebView?.postMessage,
  );
  if (isReactNativeWebView) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
  }
};

export const useNativeListener = listener => {
  useEffect(() => {
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [listener]);
};

export const useNativeToken = () => {
  const [token, setToken] = useState();
  useEffect(() => {
    const listener = message => {
      if (message.data?.type === 'accessToken') {
        setToken(message.data?.payload);
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);
  return token;
};
