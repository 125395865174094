import Bugsnag from '@bugsnag/browser';
import Constants from '../constants';
import { formatBugsnagErrorMessage } from '../bugsnag';

export const getAdminCategoryDetails = (authState, categoryId, countryCode) =>
  fetch(
    `${Constants.PATHWAY_API.BASE_URL}/${Constants.PATHWAY_API.CATEGORIES_PATH_NAME}/${categoryId}?isAdmin=true&country=${countryCode}`,
    {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    },
  )
    .then(apiCategory => apiCategory.json())
    .then(categoryDetails => {
      return categoryDetails;
    })
    .catch(err => {
      Bugsnag.notify(formatBugsnagErrorMessage(err));
      return {};
    });
