import { useContext } from 'react';
import { GoogleAnalyticsContext } from './googleAnalyticsContext';

export const useGoogleAnalytics = () => {
  const context = useContext(GoogleAnalyticsContext);
  if (context === undefined) {
    throw new Error(
      'useGoogleAnalytics must be used within a GoogleAnalyticsProvider',
    );
  }
  return context;
};
