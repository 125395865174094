import Constants from 'constants/index';
import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Ribbon } from 'icons';
import {
  IconAward,
  IconCalendar,
  IconRefresh,
  IconUserMinus,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useUpdateChecklistStatusMutation,
  useResetTrainingPlanMutation,
} from 'services/pathwayApi';
import { useSelector } from 'react-redux';
import {
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
} from 'store/user/selectors';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import ProgressBar from 'sharedComponents/app/ProgressBar';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import { Card, CardContent, Tooltip, Typography } from 'cfa-react-components';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';

const TeamMemberCard = props => {
  const {
    completedStepCount,
    completionDate,
    id,
    isComplete,
    isCompliance,
    name,
    onRefetch,
    onUnassign,
    onUpdateDueDate,
    percentComplete,
    planId,
    planName,
    selectedDueDate,
    stepCount,
    timeSpentOnPlan,
  } = props;
  const { t } = useTranslation();
  const [updateChecklistStatus] = useUpdateChecklistStatusMutation();
  const [resetTrainingPlan] = useResetTrainingPlanMutation();
  const [showMarkCompletedPopup, setShowMarkCompletedPopup] = useState(false);
  const [showRestartTrainingPopup, setShowRestartTrainingPopup] =
    useState(false);

  const locationsWithOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const locationsWithLeaderPermission = useSelector(
    selectLocationsWithLeaderPermission,
  );

  const handleUnassign = () => {
    onUnassign(id, name);
  };

  const handleUpdateDueDate = () => {
    onUpdateDueDate(id, name);
  };

  const handleMarkComplete = () => {
    setShowMarkCompletedPopup(true);
  };

  const onMarkCompleteCancel = () => {
    setShowMarkCompletedPopup(false);
  };

  const onMarkCompleteConfirm = () => {
    setShowMarkCompletedPopup(false);
    updateChecklistStatus({
      checklist: planId,
      status: Constants.TRAINING_PLANS.COMPLETED,
      userIds: [id],
    })
      .unwrap()
      .then(payload => {
        onRefetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const handleRestartTraining = () => {
    setShowRestartTrainingPopup(true);
  };

  const onRestartPlanConfirm = () => {
    setShowRestartTrainingPopup(false);
    resetTrainingPlan({
      checklistId: planId,
      userIds: [id],
    })
      .unwrap()
      .then(payload => {
        onRefetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onRestartPlanCancel = () => {
    setShowRestartTrainingPopup(false);
  };

  const generateProgressText = () => {
    if (isComplete) {
      return `${t(
        'TrainingPlans.accountability.planCompletedIn',
      )} ${timeSpentOnPlan}`;
    } else if (completedStepCount > 0 && !isComplete) {
      return `${completedStepCount}/${stepCount} ${t(
        'TrainingPlans.accountability.itemsCompletedIn',
      )} ${timeSpentOnPlan}`;
    } else if (isCompliance && !isComplete) {
      return `${percentComplete} ${t(
        'TrainingPlans.accountability.percentCompleted',
      )}`;
    } else {
      return `${completedStepCount}/${stepCount} ${t(
        'TrainingPlans.accountability.itemsCompleted',
      )}`;
    }
  };

  return (
    <>
      {isCompliance ? (
        <StyledCard>
          <StyledCardContent>
            <StyledCardLeft>
              <StyledCardName
                data-testid="PlanName"
                fontWeight="bold"
                variant="body1"
              >
                {name}
              </StyledCardName>
              {!isComplete && (
                <ProgressBar
                  current={percentComplete}
                  isComplete={isComplete}
                  isCompliance={isCompliance}
                  selectedDueDate={selectedDueDate}
                  showDate={true}
                  text={generateProgressText()}
                  total={isCompliance ? 100 : stepCount}
                />
              )}
            </StyledCardLeft>
            <StyledCardRight>
              {isComplete && (
                <Tooltip
                  content={
                    <>
                      <strong>
                        {t('TrainingPlans.statusOptions.completed')}:
                      </strong>{' '}
                      {new Date(completionDate).toLocaleDateString()}
                    </>
                  }
                  placement="top"
                  showOnElementEvents={['hover']}
                >
                  <StyledRibbon />
                </Tooltip>
              )}
              <PopoverMenuButton>
                {isCompliance ? (
                  <PopoverMenuItemButton
                    icon={<IconUserMinus />}
                    onClick={handleUnassign}
                    text={t('Button.unassign')}
                  />
                ) : (
                  <>
                    <PopoverMenuItemButton
                      icon={<IconUserMinus />}
                      onClick={handleUnassign}
                      text={t('Button.unassign')}
                    />
                    {!isComplete && (
                      <PopoverMenuItemButton
                        icon={<IconCalendar />}
                        onClick={handleUpdateDueDate}
                        testId="popupMenuUpdateDueDate"
                        text={t('Button.updateDueDate')}
                      />
                    )}
                    {!isComplete &&
                      (locationsWithOperatorPermission.length > 0 ||
                        locationsWithLeaderPermission.length > 0) && (
                        <PopoverMenuItemButton
                          icon={<IconAward />}
                          onClick={() => handleMarkComplete(planName)}
                          testId="popupMenuMarkCompleted"
                          text={t('Button.markCompleted')}
                        />
                      )}
                    {(completedStepCount !== 0 || isComplete) &&
                      (locationsWithOperatorPermission.length > 0 ||
                        locationsWithLeaderPermission.length > 0) && (
                        <PopoverMenuItemButton
                          icon={<IconRefresh />}
                          onClick={() => handleRestartTraining(planName)}
                          testId="popupMenuRestartTraining"
                          text={t('Button.restartTraining')}
                        />
                      )}
                  </>
                )}
              </PopoverMenuButton>
            </StyledCardRight>
          </StyledCardContent>
        </StyledCard>
      ) : (
        <Link
          to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planId}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${id}`}
        >
          <StyledCard>
            <StyledCardContent>
              <StyledCardLeft>
                <StyledCardName
                  data-testid="PlanName"
                  fontWeight="bold"
                  variant="body1"
                >
                  {name}
                </StyledCardName>
                <ProgressBar
                  completionDate={completionDate}
                  current={completedStepCount}
                  isComplete={isComplete}
                  isCompliance={isCompliance}
                  selectedDueDate={selectedDueDate}
                  showDate={true}
                  text={generateProgressText()}
                  total={isCompliance ? 100 : stepCount}
                />
              </StyledCardLeft>
              <StyledCardRight>
                <PopoverMenuButton>
                  {isCompliance ? (
                    <PopoverMenuItemButton
                      icon={<IconUserMinus />}
                      onClick={handleUnassign}
                      text={t('Button.unassign')}
                    />
                  ) : (
                    <>
                      <PopoverMenuItemButton
                        icon={<IconUserMinus />}
                        onClick={handleUnassign}
                        text={t('Button.unassign')}
                      />
                      {!isComplete && (
                        <PopoverMenuItemButton
                          icon={<IconCalendar />}
                          onClick={handleUpdateDueDate}
                          testId="popupMenuUpdateDueDate"
                          text={t('Button.updateDueDate')}
                        />
                      )}
                      {!isComplete &&
                        (locationsWithOperatorPermission.length > 0 ||
                          locationsWithLeaderPermission.length > 0) && (
                          <PopoverMenuItemButton
                            icon={<IconAward />}
                            onClick={() => handleMarkComplete(planName)}
                            testId="popupMenuMarkCompleted"
                            text={t('Button.markCompleted')}
                          />
                        )}
                      {(completedStepCount !== 0 || isComplete) &&
                        (locationsWithOperatorPermission.length > 0 ||
                          locationsWithLeaderPermission.length > 0) && (
                          <PopoverMenuItemButton
                            icon={<IconRefresh />}
                            onClick={() => handleRestartTraining(planName)}
                            testId="popupMenuRestartTraining"
                            text={t('Button.restartTraining')}
                          />
                        )}
                    </>
                  )}
                </PopoverMenuButton>
              </StyledCardRight>
            </StyledCardContent>
          </StyledCard>
        </Link>
      )}
      <ConfirmationModal
        bodyText={`${t('TrainingPlans.markCompleted1')} <b>${planName}</b> ${t(
          'TrainingPlans.markCompleted2',
        )}`}
        headerText={t('Generic.markCompleted')}
        isOpen={!!showMarkCompletedPopup}
        onClose={onMarkCompleteCancel}
        primaryButtonHandler={onMarkCompleteConfirm}
        primaryButtonText={t('Button.yes')}
        secondaryButtonHandler={onMarkCompleteCancel}
        secondaryButtonText={t('Button.cancel')}
      />

      <ConfirmationModal
        bodyText={`${t('TrainingPlans.restartPlan1')} <b>${planName}</b> ${t(
          'TrainingPlans.restartPlan2',
        )}`}
        headerText={t('Generic.restartTraining')}
        isOpen={!!showRestartTrainingPopup}
        onClose={onRestartPlanCancel}
        primaryButtonHandler={onRestartPlanConfirm}
        primaryButtonText={t('Button.yes')}
        secondaryButtonHandler={onRestartPlanCancel}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

TeamMemberCard.propTypes = {
  completedStepCount: PropTypes.number,
  completionDate: PropTypes.string,
  id: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCompliance: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onUpdateDueDate: PropTypes.func,
  onRefetch: PropTypes.func.isRequired,
  onUnassign: PropTypes.func,
  percentComplete: PropTypes.number,
  planId: PropTypes.string.isRequired,
  planName: PropTypes.string,
  selectedDueDate: PropTypes.number.isRequired,
  stepCount: PropTypes.number,
  timeSpentOnPlan: PropTypes.string.isRequired,
};

TeamMemberCard.defaultProps = {
  completedStepCount: 0,
  completionDate: null,
  isCompliance: false,
  onUnassign: () => {},
  onUpdateDueDate: () => {},
  percentComplete: 0,
  planName: '',
  stepCount: 0,
};

const StyledCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  overflow: visible !important;
  cursor: ${({ $isDisabled }) => $isDisabled && 'default !important'};
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
`;

const StyledCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ $isSmAndUp }) =>
    $isSmAndUp ? 'center' : 'flex-start'};
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  margin: 0 15px;
`;
const StyledCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
`;
const StyledCardName = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledRibbon = styled(Ribbon)`
  height: 25px;
  width: 25px;
`;

export default TeamMemberCard;
