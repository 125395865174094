import Constants from 'constants/index';
import { getLaunchdDarklyUser, getUserInfo } from 'util/user';
import { getCountryFromApiCountry } from 'util/international';
import oktaAuth from 'okta/oktaConfig';
import { initializeUser as initializeUserFromStore } from 'store/user/slice';
import { selectUser } from 'store/user/selectors';
import { formatBugsnagErrorMessage } from 'bugsnag';
import Bugsnag from '@bugsnag/browser';
import i18n from 'i18next';
import { initializeLanguage } from 'i18n/language';
import { sendNativeMessage } from './hooks/useNativeListener';

export const initializeUser = store => {
  if (Constants.IS_IN_CYPRESS_TEST_LOCAL || Constants.IS_IN_JEST_TEST) {
    const testUser = {
      fullName: 'Will Shakespeare',
      email: 'will@thebard.com',
      userType: 'Staff',
      locations: ['00000'],
      audience: 'STAFF_AUDIENCE',
      language: '',
      country: {},
      permissions: {
        ADMIN: ['00000'],
        LEADER: ['00000'],
        LOGIN: ['00000'],
        OPERATOR: ['00000'],
        TRAINER: ['00000'],
      },
      userId: '12345abcde',
    };
    store.dispatch(initializeUserFromStore(testUser));
    return;
  }

  oktaAuth.authStateManager.subscribe(async authState => {
    if (authState && authState?.accessToken?.claims) {
      const { language, country } = await getUserLanguageAndCountryPreference(
        authState,
        store,
      ).then(async ({ langCode, country: userCountry }) => {
        const remotePreferencesStateToLocalUserObject = {
          language: langCode,
          country: userCountry,
        };
        store.dispatch(
          initializeUserFromStore(remotePreferencesStateToLocalUserObject),
        );
        await i18n.changeLanguage(langCode);
        return remotePreferencesStateToLocalUserObject;
      });
      const user = getUserInfo(
        authState?.accessToken?.claims,
        language,
        country,
      );
      let isValidUser = false;
      if (!user) {
        const err = 'User info cannot be read from token';
        console.error(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else if (!user.permissions || !Object.keys(user.permissions)?.length) {
        const err = 'User permissions do not exist in token';
        console.error(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else {
        isValidUser = true;
      }
      if (!isValidUser) {
        await oktaAuth.signOut({
          postLogoutRedirectUri: Constants.PATHWAY_LOGOUT_REDIRECT,
        });
      } else {
        store.dispatch(initializeUserFromStore(user));
      }
    }
  });

  const reactNativeWebView = window.ReactNativeWebView;
  let androidUser;
  if (
    reactNativeWebView &&
    typeof reactNativeWebView.injectedObjectJson === 'function'
  ) {
    androidUser = JSON.parse(
      reactNativeWebView.injectedObjectJson(),
    ).userFromToken;
  }
  let userFromToken;
  if (window.userFromToken) {
    userFromToken = JSON.parse(window.userFromToken);
  } else if (androidUser) {
    userFromToken = androidUser;
  }

  if (userFromToken) {
    const { language, country } = getUserLanguageAndCountryPreference(
      userFromToken,
      store,
    ).then(async ({ langCode, country: userCountry }) => {
      const remotePreferencesStateToLocalUserObject = {
        language: langCode,
        country: userCountry,
      };
      store.dispatch(
        initializeUserFromStore(remotePreferencesStateToLocalUserObject),
      );

      console.log(remotePreferencesStateToLocalUserObject)
      await i18n.changeLanguage(langCode).then();
      initializeLanguage();

      const user = getUserInfo(userFromToken, langCode, userCountry);
      let isValidUser = false;
      if (!user) {
        const err = 'User info cannot be read from token';
        console.error(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else if (!user.permissions || !Object.keys(user.permissions)?.length) {
        const err = 'User permissions do not exist in token';
        console.error(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else {
        isValidUser = true;
      }
      if (!isValidUser) {
        oktaAuth.signOut({
          postLogoutRedirectUri: Constants.PATHWAY_LOGOUT_REDIRECT,
        });
      } else {
        store.dispatch(initializeUserFromStore(user));
      }
      return remotePreferencesStateToLocalUserObject;
    });
  }
};

function getUserLanguageAndCountryPreference(authState) {

  return new Promise(resolve => {
    let langCode = Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
    let country = Constants.SUPPORTED_COUNTRIES.US;

    // if (window.accessToken) {
    //   const language = window.language || 'es';
    //   const userCountry = {"id": window.country || country?.id};
    //   return resolve({ langCode: language, country: userCountry });
    // }
    const accessToken = window.accessToken || authState?.accessToken?.accessToken;
    return fetch(`${Constants.PATHWAY_API.BASE_URL}/users/preferences`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(data => data.json())
      .then(prefs => {
        const userPrefLang = prefs?.language;
        if (userPrefLang) {
          langCode = userPrefLang;
        }
        const countryPreference = prefs?.country;
        if (countryPreference) {
          country = getCountryFromApiCountry(countryPreference);
        }
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      })
      .finally(() => {
        resolve({ langCode, country });
      });
  });
}

export const initializeLaunchDarklyUser = ({ ldClient, store }) => {
  const state = store.getState();
  const user = selectUser(state);
  sendNativeMessage({
    type: 'updateUser',
    payload: {
      user,
    },
  });
  const ldUser = getLaunchdDarklyUser(user);
  // if browser setting > cookies > "Do not track" then user info wont show in Users list page in LD
  // although the user info should still have been posted to LD for use in flag targeting: apparently
  // "Do not track" browser setting impacts the LD analytics api (events.launchdarkly.com)
  // but for user flag targeting the feature flag API (app.launchdarkly.com) is used
  // https://docs.launchdarkly.com/guides/best-practices/user-data
  if (ldUser && ldUser.email) {
    ldClient.identify(ldUser, null, function () {});
  }
};
