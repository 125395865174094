import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PopoverMenuItem } from 'cfa-react-components';

const PopoverMenuItemButton = ({
  className,
  icon,
  isDisabled,
  isDestructive,
  isVisible,
  onClick,
  text,
  'data-testid': dataTestId,
}) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <>
      {isVisible && (
        <MenuItem
          $isDestructive={isDestructive}
          $isDisabled={isDisabled}
          className={className}
          data-testid={dataTestId}
          onClick={handleClick}
        >
          {icon}
          {text}
        </MenuItem>
      )}
    </>
  );
};

const MenuItem = styled(PopoverMenuItem)`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  color: ${({ theme, $isDestructive, $isDisabled }) =>
    $isDestructive
      ? theme.semanticColors.error
      : $isDisabled
      ? theme.grayScale.gray2
      : theme.primaryPalette.navyBlue} !important;
  &:first-child {
    padding-top: 24px !important;
  }
  &:last-child {
    padding-bottom: 24px !important;
  }
`;

PopoverMenuItemButton.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  icon: PropTypes.node.isRequired,
  isDestructive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

PopoverMenuItemButton.defaultProps = {
  className: '',
  'data-testid': 'PopoverMenuItem',
  isDestructive: false,
  isDisabled: false,
  isVisible: true,
};

export default PopoverMenuItemButton;
