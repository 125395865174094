import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import {
  useGetAssignableChecklistsQuery,
  useEnableCourseMutation,
  useGetIsCourseEnabledQuery,
  useGetOperatorsQuery,
  useGetTeamMembersQuery,
  useGetCourseReportQuery,
} from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
} from 'store/user/selectors';
import { withRoles } from 'sharedComponents/app/withRoles';
import print from 'print-js';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { toast } from 'react-hot-toast';
import {
  addManagePlansLocationFilter,
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
} from 'store/managePlansFilter/slice';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from 'store/managePlansFilter/selector';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import ClearFiltersHeader from 'components/ClearFiltersHeader/ClearFiltersHeader';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import { setHeader } from 'store/header/slice';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import { PlanCard } from '../SharedComponents/PlanProgressCard';

const ManagePlansTab = () => {
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const planLocations = useSelector(selectAllLocationsWithAtLeastTrainer);
  const [showChooseLocationsPlanPopup, setShowChooseLocationsPlanPopup] =
    useState(false);
  const [showEnablePlanPopUp, setShowEnablePlanPopUp] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [planId, setPlanId] = useState('');
  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { t } = useTranslation();
  const { data: operators } = useGetOperatorsQuery();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  const [enableCourse] = useEnableCourseMutation();
  const dispatch = useDispatch();

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const searchFilter = useSelector(selectSearchFilter);
  const { showing, total } = useSelector(selectPagination);

  const OperatorsOfLocationsWithAtLeastLeader = operators?.filter(
    operator =>
      arrayIntersect(operator?.locations, locationsWithAtLeastLeaderPermission)
        .length > 0,
  );

  const { data: complianceStatusData, refetch: refetchIsCourseEnabled } =
    useGetIsCourseEnabledQuery(
      OperatorsOfLocationsWithAtLeastLeader?.[0]?.id ?? operators?.[0]?.id,
      {
        skip: !operators?.length,
      },
    );

  const {
    data: unOrderedPlans,
    isFetching,
    error,
    refetch,
  } = useGetAssignableChecklistsQuery();

  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;

  const planIsLearnUponDocumentUuid =
    planId?.match(Constants.UUID_REGEX_MATCH)?.length > 0;

  const { data: statusReportData, isSuccess: isStatusReportSuccess } =
    useGetCourseReportQuery(
      {
        courseId: planId,
        location: selectedLocations,
      },
      {
        skip:
          !planId ||
          !isTrainerOrLeaderOrOperator ||
          !planIsLearnUponDocumentUuid ||
          !selectedLocations.length,
      },
    );

  const { data: allTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { refetchOnMountOrArgChange: true },
  );

  // Set Plans
  useEffect(() => {
    const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
      return {
        ...plan,
        planName: getNameFromLanguage(plan.checklist.name).toLowerCase(),
      };
    });
    dispatch(
      setManagePlans({
        plans: storePlansWithName?.concat(unOrderedPlans?.courses) ?? [],
      }),
    );
  }, [dispatch, unOrderedPlans]);

  // we want to call api on each page load, no caching
  useEffect(() => {
    refetch();
  }, [refetch]);

  // Check if compliance plan is enabled
  useEffect(() => {
    if (complianceStatusData && unOrderedPlans) {
      const compliancePlansWithStatus = unOrderedPlans?.courses
        ?.filter(plan => plan?.courseID)
        .map(course => ({
          ...course,
          enabled: complianceStatusData?.[course?.id]?.enabled ?? false,
        }));

      const plansWithoutCompliance = unOrderedPlans?.checklists?.filter(
        plan => !plan?.courseID,
      );

      dispatch(
        setManagePlans({
          plans: plansWithoutCompliance?.concat(compliancePlansWithStatus),
        }),
      );
    }
  }, [complianceStatusData, dispatch, unOrderedPlans]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
  }, [dispatch, t]);

  const statusReportToPrint = [...statusReport?.userData]
    .sort((teamMemberA, teamMemberB) => {
      return teamMemberA.firstName.localeCompare(teamMemberB.firstName);
    })
    .map(report => {
      return {
        name: `${report.firstName} ${report.lastName}`,
        DOB: report.birthDate ? report.birthDate : t('Generic.na'),
        completed: report.courseCompletionDate
          ? report.courseCompletionDate
          : t('Generic.notStarted'),
      };
    });

  const reportToPrint = {
    printable: statusReportToPrint,
    properties: ['name', 'DOB', 'completed'],
    type: 'json',
    header: `
        <div class="header">
        <span class="courseName">${statusReport?.courseName}</span>
        </div>
        `,
    style: `.header { display: flex; flex-direction: column; align-items: center; font-weight: 900;}
                .courseName {margin-top: 0.5em; margin-bottom: 2em; }`,
    gridHeaderStyle: `border: 1px solid #5B6770; padding: 0.5em;`,
    gridStyle: `border: 1px solid #5B6770; text-align: center; padding: 0.5em;`,
  };

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onCancel = () => {
    setShowEnablePlanPopUp(false);
  };

  const onContinue = () => {
    enableCourse({
      operatorId: operators[0]?.id,
      courseId,
    })
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('TrainingPlans.toastMessage.enabled', { courseName })}
          </ToastMessageBlock>
        ));
        refetch();
        refetchIsCourseEnabled();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
    setShowEnablePlanPopUp(false);
  };

  const onShowPopUp = (id, name) => {
    setCourseId(id);
    setCourseName(name);
    setShowEnablePlanPopUp(true);
  };
  const onPrintReport = () => {
    print(reportToPrint);
    setShowChooseLocationsPlanPopup(false);
  };

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.COMPLIANCE]: {
      translationString: t('TrainingPlans.planCategories.compliance'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.newest'),
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.oldest'),
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '4',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ];

  return (
    <>
      <StyledContent>
        <SearchFilterHeader
          onChange={e =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('TrainingPlans.filtering.searchManagePlans')}
          searchValue={searchFilter}
          title={t('TrainingPlans.tabManagePlans')}
        />
        {!!isSmAndDown && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setManagePlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              filteredAndSortedPlans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            <CheckboxFilterSection
              labels={planLocations.reduce(
                (acc, loc) => ({
                  ...acc,
                  [loc]: {
                    translationString: loc,
                    value: !!currentFilters.includes(loc),
                  },
                }),
                {},
              )}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansLocationFilter({ filter: value }));
                }
              }}
              title={t('Generic.locations')}
            />
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlanCardList>
          {!isSmAndDown && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={planLocations.reduce(
                  (acc, loc) => ({
                    ...acc,
                    [loc]: {
                      translationString: loc,
                      value: !!currentFilters.includes(loc),
                    },
                  }),
                  {},
                )}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeManagePlansFilter({ filter: value }));
                  } else {
                    dispatch(addManagePlansLocationFilter({ filter: value }));
                  }
                }}
                title={t('Generic.locations')}
              />
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeManagePlansFilter({ filter: value }));
                  } else {
                    dispatch(addManagePlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
            </StickyFilterCard>
          )}
          <PlanCardsContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setManagePlansSort({ sort: option.value }));
              }}
              onClear={() => {
                dispatch(clearManagePlansCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              text={`${total ?? 0} ${t('TrainingPlans.filtering.plans')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />

            <ClearFiltersHeader
              aliases={categoryLabels}
              clearAllFilters={() => {
                dispatch(clearManagePlansCheckboxFilters());
              }}
              clearFilter={value => {
                dispatch(removeManagePlansFilter({ filter: value }));
              }}
              filters={currentFilters}
            />
            <PlanCardsList>
              <LoadingOverlay isOpen={isFetching} />
              {!filteredAndSortedPlans?.length && !isFetching && (
                <Typography variant="body1">
                  {t('TrainingPlans.createPlan')}
                </Typography>
              )}
              {!!filteredAndSortedPlans?.length && !isFetching && (
                <>
                  <div className="list-results-no-pad">
                    {filteredAndSortedPlans.map((plan, idx) => (
                      <PlanCard
                        assignedTeamMembers={
                          plan?.courseID
                            ? allTeamMembersData.filter(
                                (value, innerIndex, self) =>
                                  innerIndex ===
                                  self.findIndex(
                                    user => user.adId === value.adId,
                                  ),
                              ).length
                            : plan?.assignedUsers
                        }
                        completedTeamMembers={
                          plan?.courseID
                            ? filteredAndSortedPlans
                                ?.filter(filteredPlan => filteredPlan?.courseID)
                                .map(user => Object.values(user.enrollments))
                                ?.[
                                  // We do this part to isolate the indices of the compliance plans
                                  idx -
                                    filteredAndSortedPlans?.filter(
                                      filteredPlan => !filteredPlan.courseID,
                                    ).length
                                ]?.filter(
                                  enrollment =>
                                    enrollment.status ===
                                      Constants.LEARN_UPON_TRAINING_PLANS
                                        .COMPLETED ||
                                    enrollment.status ===
                                      Constants.LEARN_UPON_TRAINING_PLANS
                                        .PASSED,
                                ).length
                            : plan?.completedUsers
                        }
                        isManagePlan={true}
                        key={idx}
                        locations={locationsWithAtLeastTrainer}
                        onPrintReport={id => {
                          setPlanId(id);
                          setShowChooseLocationsPlanPopup(true);
                        }}
                        onShowPopUp={() =>
                          onShowPopUp(
                            plan?.id,
                            getNameFromLanguage(plan?.courseName),
                          )
                        }
                        planDetails={plan}
                        refetch={refetch}
                      />
                    ))}
                    <LoadMorePaginator
                      onClick={() => dispatch(loadMorePlans())}
                      showing={showing}
                      showingText={t('TrainingPlans.showingXOfYPlans', {
                        showing,
                        total,
                      })}
                      total={total}
                    />
                  </div>
                </>
              )}
            </PlanCardsList>
          </PlanCardsContainer>
        </PlanCardList>
      </StyledContent>
      <ConfirmationModal
        bodyText={t('Settings.complianceEnable', {
          name: courseName,
        })}
        headerText={t('Generic.enablePlan')}
        isOpen={showEnablePlanPopUp}
        onClose={onCancel}
        primaryButtonHandler={onContinue}
        primaryButtonText={t('Button.continue')}
        secondaryButtonHandler={onCancel}
        secondaryButtonText={t('Button.cancel')}
      />

      <ConfirmationModal
        bodyText={t('TrainingPlans.whichLocationsPrintReport')}
        children={locationsWithAtLeastTrainer.map((id, idx) => (
          <CheckboxList
            id={id}
            idx={idx}
            key={idx}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
        ))}
        headerText={t('Generic.chooseLocation')}
        isDisabled={!selectedLocations?.length}
        isOpen={showChooseLocationsPlanPopup}
        onClose={() => setShowChooseLocationsPlanPopup(false)}
        primaryButtonHandler={onPrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={() => setShowChooseLocationsPlanPopup(false)}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

export default withRoles(ManagePlansTab, [
  Constants.USER_PERMISSIONS.LEADER,
  Constants.USER_PERMISSIONS.OPERATOR,
  Constants.USER_PERMISSIONS.TRAINER,
]);
