import Constants from 'constants/index';
import { useEffect, useState } from 'react';
import first from 'lodash/first';

export function useLoadMore(endpoint, queryParam, language, maxResults = 100) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuery, setCurrentQuery] = useState(queryParam);
  const [pageSize, setPageSize] = useState(Constants.DEFAULT_PAGING_SIZE);
  const [numFound, setNumFound] = useState(0);
  const [pages, setPages] = useState([]);
  const [error, setError] = useState();
  const [queryId, setQueryId] = useState('');
  const [misspellingSuggestion, setMisspellingSuggestion] = useState();
  const result = endpoint.useQuery(
    {
      query: currentQuery,
      page: currentPage,
      pageSize: pageSize,
      language,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    setCurrentQuery(queryParam);
    setPages([]);
    setCurrentPage(1);
    setPageSize(12);
  }, [queryParam]);

  useEffect(() => {
    if (result.isError) {
      setError(result.error);
      return;
    }
    setError(null);
    // need this return for reacts crazy re-rendering and nulls thrown until finally it has the data
    if (!result.isSuccess || !result.currentData) {
      return;
    }

    setNumFound(Math.min(result.data.numberFound, maxResults));
    setQueryId(result.data.queryId);
    setMisspellingSuggestion(
      first(result.data.spellCorrectedQueryResults)?.suggestedQueryText,
    );
    setPages(result.data.results);
  }, [currentPage, currentQuery, maxResults, result]);
  const hasNextPage = pages.length < numFound;

  return {
    result: {
      ...result,
      queryId,
      data: pages,
      numFound,
      misspellingSuggestion,
      showingResults: hasNextPage
        ? Math.min(Math.ceil(pageSize * currentPage), maxResults)
        : numFound > 0
        ? numFound
        : null,
      hasNextPage,
      fetchNextPage(num) {
        setPageSize(num * 12 > 100 ? 100 : num * 12);
      },
      fetchCustomPage(num) {
        setPageSize(num * 12 > 100 ? 100 : num * 12);
      },
    },
    isFetching: result.isFetching,
    error,
  };
}
