import history from 'util/history';
import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { isCurrentEnvironment } from 'util/environment';
import store, { persistor } from 'store';
import { ThemeProvider } from 'styled-components';
import isBrowserSupported from 'isBrowserSupported';
import initializeApp from 'initializeApp';
import { Route, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Security } from '@okta/okta-react';
import oktaAuth, { restoreOriginalUri } from 'okta/oktaConfig';
import CustomLoginCallback from 'okta/CustomLoginCallback';
import GenericError from 'sharedComponents/app/GenericError';
import { BugsnagErrorBoundary } from 'bugsnag';
import { PersistGate } from 'redux-persist/integration/react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { initializeLaunchDarklyUser } from 'initializeUser';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { sendNativeMessage } from 'hooks/useNativeListener';
import { useEffect, useState } from 'react';
import { GoogleAnalyticsProvider } from 'googleAnalytics/googleAnalyticsContext';
import { useIsNative } from 'hooks/useIsNative';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import packagejson from '../package.json';
import theme from './styles/theme.js';
import DocumentHead from './DocumentHead';
import ScrollToTop from './ScrollToTop';
import Layout from './containers/Layout/Layout';

if (isBrowserSupported()) {
  initializeApp(store);
}

function App() {
  const isNative = useIsNative();
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const ldClient = useLDClient();
  if (ldClient) {
    initializeLaunchDarklyUser({ ldClient, store });
  }

  oktaAuth.authStateManager.subscribe(authState => {
    if (ldClient) {
      initializeLaunchDarklyUser({ ldClient, store });
    }
    sendNativeMessage({
      type: 'authStateManagerEvent',
      payload: {
        isAuthenticated: authState.isAuthenticated,
      },
    });
    if (authState.isAuthenticated) {
      setUserAuthenticated(true);
    }
  });

  useEffect(() => {
    if (!isCurrentEnvironment(Constants.DEV)) {
      console.log(`ver. ${packagejson.version}`);
    }
    const iOSToken = window.accessToken;
    const reactNativeWebView = window.ReactNativeWebView;
    let androidToken;
    if (
      reactNativeWebView &&
      typeof reactNativeWebView.injectedObjectJson === 'function'
    ) {
      androidToken = JSON.parse(
        reactNativeWebView.injectedObjectJson(),
      ).accessToken;
    }
    if (iOSToken || androidToken) {
      setUserAuthenticated(true);
    }
  }, []);

  return (
    <GoogleAnalyticsProvider measurementId="G-W10Z8MTTF7">
      <ThemeProvider theme={theme}>
        <LoadingOverlay isOpen={!userAuthenticated} />
        <BugsnagErrorBoundary FallbackComponent={GenericError}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                {/* isNative block is for native mobile view, otherwise web view */}
                {isNative ? (
                  <>
                    <DocumentHead />
                    <ScrollToTop />
                    <Layout />
                  </>
                ) : (
                  <Security
                    oktaAuth={oktaAuth}
                    restoreOriginalUri={restoreOriginalUri}
                  >
                    <DocumentHead />
                    <ScrollToTop />
                    <Layout />
                    <Route
                      component={CustomLoginCallback}
                      path="/login/callback"
                    />
                  </Security>
                )}
              </Router>
            </PersistGate>
          </Provider>
        </BugsnagErrorBoundary>
      </ThemeProvider>
    </GoogleAnalyticsProvider>
  );
}

if (window.Cypress) {
  // allows us to connect cypress to redux
  window.store = store;
  window.persistor = persistor;
  window.utils = { getNameFromLanguage };
}

const ldProviderOptions = {
  useReport: true,
  logger: basicLogger({ level: 'warn' }),
};
export default withLDProvider({
  clientSideID: Constants.LAUNCH_DARKLY_CLIENT_ID,
  options: ldProviderOptions,
})(App);
