import Constants from 'constants/index';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setPageNumber } from 'store/scrollPosition/slice';
import Searchbar from 'components/Searchbar/Searchbar';
import { useLocationChange } from 'hooks/useLocationChange';
import { saveSearchTerm } from 'store/search/slice';

const SearchbarSubHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const onChange = e => {
    setSearchValue(e.target.value);
  };

  const onClear = () => {
    setSearchValue('');
  };

  const onSubmit = suggestion => {
    dispatch(setPageNumber(1));
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`,
      search: `query=${suggestion ?? searchValue}`,
    });
  };

  useLocationChange((currLocation, prevLocation) => {
    // we need to clear search input when navigating away from the search page
    if (
      prevLocation?.pathname !== currLocation?.pathname &&
      !currLocation?.pathname?.includes('search')
    ) {
      setSearchValue('');
    } else {
      const prevQuery = new URLSearchParams(prevLocation?.search)
        .get('query')
        ?.trim();
      const currQuery = new URLSearchParams(currLocation?.search)
        .get('query')
        ?.trim();
      // new search
      if (prevQuery && currQuery && prevQuery !== currQuery) {
        setSearchValue(currQuery);
      }
      // hits search page directly
      else if (!prevQuery && currQuery) {
        dispatch(saveSearchTerm(currQuery));
        setSearchValue(currQuery);
      }
    }
  });

  return (
    <SubHeaderContainer>
      <Searchbar
        elevation={1}
        fullWidth
        onChange={onChange}
        onClear={onClear}
        onSubmit={onSubmit}
        searchValue={searchValue}
        showRecentSearchDropdown
      />
    </SubHeaderContainer>
  );
};

const SubHeaderContainer = styled.div`
  position: relative;
  max-width: 1152px;
  margin: 0 auto;
  padding-bottom: 16px;
`;

export default SearchbarSubHeader;
