import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PopoverMenuItem } from 'cfa-react-components';

const FabPopupItem = ({ icon, title, onClick, ...props }) => {
  return (
    <PopoverMenuItem id={title} onClick={onClick} {...props}>
      <ItemContainer>
        {icon}
        {title}
      </ItemContainer>
    </PopoverMenuItem>
  );
};

FabPopupItem.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default FabPopupItem;
