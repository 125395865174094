import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalHeader } from 'cfa-react-components';
import { useEffect, useState } from 'react';

const ScrollModalHeader = ({ scrollRef, children, ...props }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const updateScrollStatus = () => {
      const scrollTop = scrollRef.current?.scrollTop;
      if (scrollTop !== 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    scrollRef?.current?.addEventListener('scroll', updateScrollStatus);

    return () => window?.removeEventListener('scroll', updateScrollStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current]);

  return (
    <ScrollingModalHeader $isScrolled={isScrolled} {...props}>
      {children}
    </ScrollingModalHeader>
  );
};

const ScrollingModalHeader = styled(ModalHeader)`
  z-index: 5;
  box-shadow: ${({ $isScrolled, theme }) =>
    $isScrolled ? theme.boxShadow.elevation16 : 'none'};
`;

ScrollModalHeader.propTypes = {
  scrollRef: PropTypes.object.isRequired,
  children: PropTypes.node,
};

ScrollModalHeader.defaultProps = {
  children: null,
};
export default ScrollModalHeader;
