import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'subcategory',
  initialState: {
    id: '',
  },
  reducers: {
    setSubcategoryId: (state, action) => {
      state.id = action.payload.id;
    },
  },
});

export const { setSubcategoryId } = slice.actions;

export default slice.reducer;
