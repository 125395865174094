import {
  mapCategoryToAliasTranslation,
  mapCategoryToColor,
  mapCategoryToIcon,
} from 'util/categoryUtils';
import { getNameFromLanguage } from 'util/language';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';

export const TemplateCard = ({ plan, onPreview, onCopy }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));

  return (
    <TemplatePlanCard data-testid="TemplatePlanCard" elevation={1}>
      <ProgressCardImageWrapper
        $backgroundColor={mapCategoryToColor(
          plan?.category,
          plan?.disabled === true,
        )}
      >
        {mapCategoryToIcon(plan?.category)}
      </ProgressCardImageWrapper>
      <ProgressCardContent>
        <StyledPlanCardLeft>
          <Typography variant="overline3">
            {t(mapCategoryToAliasTranslation(plan?.category))}
          </Typography>
          <StyledPlanName
            data-testid="PlanName"
            fontWeight="bold"
            variant="body1"
          >
            {getNameFromLanguage(plan.name)}
          </StyledPlanName>
          <Typography data-testid="PlanTaskCount" variant="body2">
            {`${plan?.stepsTotal} ${t('Generic.items')}`}
          </Typography>
        </StyledPlanCardLeft>
        <StyledPlanCardRight $isSmAndUp={isSmAndUp}>
          <>
            <StyledTemplateButton
              color="secondary"
              data-testid="templatePreviewButtonTest"
              onClick={() => onPreview(plan.id)}
              variant="outlined"
            >
              {t('Button.preview')}
            </StyledTemplateButton>
            <StyledTemplateButton
              color="secondary"
              data-testid="templateCopyButtonTest"
              onClick={onCopy}
              variant="filled"
            >
              {t('Button.copy')}
            </StyledTemplateButton>
          </>
        </StyledPlanCardRight>
      </ProgressCardContent>
    </TemplatePlanCard>
  );
};

const TemplatePlanCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  overflow: visible !important;
  cursor: 'default !important';
`;

const ProgressCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  max-height: 96px;
  height: auto;
  background: ${props => props.$backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
`;

const ProgressCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
`;

const StyledPlanCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ $isSmAndUp }) =>
    $isSmAndUp ? 'center' : 'flex-start'};
  flex-grow: 1;
  overflow: hidden;
  margin: 0 15px;
`;

const StyledPlanCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
`;

const StyledPlanName = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledTemplateButton = styled(Button)`
  min-width: unset;
`;

TemplateCard.propTypes = {
  plan: PropTypes.object.isRequired,
  onPreview: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};
