import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from 'cfa-react-components';
import DocumentHead from 'DocumentHead';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

const PageTitle = ({ className, 'data-testid': DataTestId, children }) => {
  return (
    <PageHeaderContainer className={className} data-testid={DataTestId}>
      {children && (
        <>
          <DocumentHead pageTitle={startCase(toLower(children))} />
          <PageTitleText variant="h2">{children}</PageTitleText>
        </>
      )}
    </PageHeaderContainer>
  );
};

PageTitle.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  children: PropTypes.string,
};

PageTitle.defaultProps = {
  className: '',
  'data-testid': 'PageTitle',
  children: '',
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const PageTitleText = styled(Typography)``;

export default PageTitle;
