import { RadioGroup, RadioButton } from 'cfa-react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioButtonList = ({ defaultValue, setValue, listItems, name }) => {
  return (
    <StyledRadioGroup
      defaultValue={defaultValue}
      name={name}
      onChange={e => setValue(e.target.value)}
      orientation="vertical"
    >
      {listItems.map((item, id) => {
        return (
          <RadioButton key={`${item}-item-${id}`} label={item} value={item} />
        );
      })}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

RadioButtonList.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  listItems: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

RadioButtonList.defaultProps = {};

export default RadioButtonList;
