import { stripHtml } from 'util/dom';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const getFullPageTitle = specificPageTitle =>
  specificPageTitle
    ? stripHtml(specificPageTitle)
    : 'Pathway 2.0: Learning and Training Resources';

const DocumentHead = ({ pageTitle, children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{getFullPageTitle(pageTitle)}</title>
        {children}
      </Helmet>
    </HelmetProvider>
  );
};

DocumentHead.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.any,
};

DocumentHead.defaultProps = {
  pageTitle: '', // will be empty when menu item not loaded yet
  children: null,
};

export default DocumentHead;
