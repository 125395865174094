import { setupBugsnag } from 'bugsnag';
import { initialized } from 'store/app/slice';
import { initializeUser } from 'initializeUser';
import { initializeLanguage } from 'i18n/language';
import { initializeTranslation } from 'i18n/translation';

let appIntialized = false;
const initializeApp = store => {
  if (!appIntialized && store) {
    setupBugsnag();
    initializeUser(store);
    initializeLanguage();
    initializeTranslation();

    store.dispatch(initialized(true));
    // wo this closure reference to appIntialized var
    // dispatch(initialize()) will be called twice
    appIntialized = true;
  }
};

export default initializeApp;
