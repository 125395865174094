import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Typography } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';

const LoadMorePaginator = ({
  className,
  'data-testid': dataTestId,
  onClick,
  showing,
  showingText,
  total,
}) => {
  const { t } = useTranslation();
  const hasMore = showing < total;
  const hidden = total <= showing;

  return (
    <>
      {!hidden && (
        <PaginatorContainer className={className} data-testid={dataTestId}>
          <Button color="secondary" disabled={!hasMore} onClick={onClick}>
            {t('Button.loadMore')}
          </Button>
          <Typography variant="body1">{showingText}</Typography>
        </PaginatorContainer>
      )}
    </>
  );
};

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 32px;
`;

LoadMorePaginator.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showing: PropTypes.number.isRequired,
  showingText: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

LoadMorePaginator.defaultProps = {
  className: '',
  'data-testid': 'LoadMorePaginator',
};

export default LoadMorePaginator;
