import Constants from 'constants/index';

export const isCurrentEnvironment = env => Constants.ENV === env;

export const setWindowObjectToConnectAppToCypress = (key, val) => {
  if (!window.cfaPathway) {
    window.cfaPathway = {};
  }
  if (key) {
    window.cfaPathway[key] = val;
  }
};
