import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Location } from 'icons';

const LocationsDisplay = ({ locations }) => {
  return (
    <StyledSelectedLocationsOuterWrapper>
      <StyledSelectedLocationsInnerWrapper>
        {locations.map(storeLocation => (
          <StyledMappedLocations key={storeLocation}>
            <StyledLocationWrapper>
              <StyledLocation />
            </StyledLocationWrapper>
            <StyledSelectedLocation data-testid="location">
              {storeLocation}
            </StyledSelectedLocation>
          </StyledMappedLocations>
        ))}
      </StyledSelectedLocationsInnerWrapper>
    </StyledSelectedLocationsOuterWrapper>
  );
};

LocationsDisplay.propTypes = {
  locations: PropTypes.array.isRequired,
};

const StyledSelectedLocationsOuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
`;
const StyledSelectedLocationsInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto 5px;
  padding: 0;
`;
const StyledSelectedLocation = styled.span`
  margin: auto 1em auto 0.25em;
  font-weight: 500;
  font-size: 14px;
`;
const StyledLocationWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;
const StyledMappedLocations = styled.div`
  display: flex;
  cursor: default;
`;
const StyledLocation = styled(Location)`
  height: 15px;
  width: 15px;
`;

export default LocationsDisplay;
