import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconGripVertical } from '@tabler/icons-react';

const SixDotIcon = ({ position }) => {
  return (
    <SixDotIconWrapper $position={position} data-testid="Draggable">
      <StyledSixDotIcon />
    </SixDotIconWrapper>
  );
};

const SixDotIconWrapper = styled.div`
  align-self: ${({ $position }) =>
    $position === 'top' ? 'flex-start' : 'center'};
  cursor: pointer;
  // margin-right: 0.5em;
`;
const StyledSixDotIcon = styled(IconGripVertical)`
  margin-left: -4px;
  color: ${({ theme }) => theme.grayScale.gray3};
`;

SixDotIcon.propTypes = {
  position: PropTypes.oneOf(['top', 'center']),
};

SixDotIcon.defaultProps = {
  position: 'center',
};
export default SixDotIcon;
