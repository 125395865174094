import { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PdfPage = ({ pdf, pageNum }) => {
  const canvasRef = useRef(null);

  // eslint-disable-next-line no-shadow
  const renderPage = useCallback((pageNum, pdf) => {
    pdf &&
      pdf.getPage(pageNum).then(function (page) {
        const viewport = page.getViewport({ scale: 2.5 });
        const canvas = canvasRef.current;

        if (canvas) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport,
          };
          page.render(renderContext);
        }
      });
  }, []);

  useEffect(() => {
    renderPage(pageNum, pdf);
  }, [pdf, pageNum, renderPage]);

  return (
    <StyledCanvasContainer>
      <StyledCanvas ref={canvasRef}></StyledCanvas>
    </StyledCanvasContainer>
  );
};

const StyledCanvasContainer = styled.div`
  position: relative;
`;

const StyledCanvas = styled.canvas`
  height: 100%;
  width: 100%;
`;

PdfPage.propTypes = {
  pageNum: PropTypes.number.isRequired,
  pdf: PropTypes.object.isRequired,
};

export default PdfPage;
