import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import { useGetTrainingPlanQuery } from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import PropTypes from 'prop-types';
import { PlanCard } from 'containers/TrainingPlans/SharedComponents/PlanProgressCard';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import {
  selectFilters,
  selectSort,
  selectSortedPaginatedAndFilteredMyPlans,
  selectMyPlansPagination,
  selectMyPlansSearchFilter,
  selectShowMyCompletedPlansOnlySwitchValue,
} from 'store/myPlansFilter/selector';
import {
  setMyPlans,
  setMyPlansSort,
  addMyPlansCategoryFilter,
  removeMyPlansFilter,
  clearMyPlansCheckboxFilters,
  loadMoreMyPlans,
  clearMyPlansSearchFilter,
  setMyPlansSearchFilter,
  toggleShowInProgressOnly,
} from 'store/myPlansFilter/slice';
import { setHeader } from 'store/header/slice';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import ClearFiltersHeader from 'components/ClearFiltersHeader/ClearFiltersHeader';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';

export const PlansCount = props => {
  const { count, plan, testId } = props;

  return (
    <StyledPlansCount
      data-testid={testId}
    >{`${plan} (${count})`}</StyledPlansCount>
  );
};

PlansCount.propTypes = {
  count: PropTypes.number.isRequired,
  plan: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

const MyPlansTab = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const currentFilters = useSelector(selectFilters);
  const breakpoints = useBreakpoints();
  const sort = useSelector(selectSort);
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const dispatch = useDispatch();
  const filteredAndSortedPlans = useSelector(
    selectSortedPaginatedAndFilteredMyPlans,
  );
  const { showing, total } = useSelector(selectMyPlansPagination);
  const searchFilter = useSelector(selectMyPlansSearchFilter);
  const showCompletedPlansOnly = useSelector(
    selectShowMyCompletedPlansOnlySwitchValue,
  );

  const { complianceTraining: featureFlagComplianceTraining } = useFlags();
  const {
    data: unOrderedPlans,
    isFetching,
    refetch,
    error,
  } = useGetTrainingPlanQuery(
    { myPlans: true },
    { refetchOnMountOrArgChange: true },
  );
  const [coursePlans, setCoursePlans] = useState([]);
  const [compliancePlans, setCompliancePlans] = useState([]);

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
    return () => {
      dispatch(setHeader(''));
    };
  }, [dispatch, t]);

  // We initially set to the entire plans array and order it
  useEffect(() => {
    if (Array.isArray(unOrderedPlans?.checklistResults)) {
      const storePlansWithName = unOrderedPlans?.checklistResults?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(plan.checklist.name).toLowerCase(),
        };
      });
      setCoursePlans(orderBy(storePlansWithName, ['planName'], ['asc']));
    }
  }, [unOrderedPlans]);

  // We do the same as above but for compliance plans
  useEffect(() => {
    if (unOrderedPlans?.coursesResults?.[0]?.enrollments) {
      const compliancePlansWithName =
        unOrderedPlans?.coursesResults?.[0]?.enrollments &&
        Object.keys(unOrderedPlans?.coursesResults?.[0]?.enrollments).map(
          key => unOrderedPlans?.coursesResults?.[0]?.enrollments?.[key],
        );
      setCompliancePlans(orderBy(compliancePlansWithName, ['name'], ['asc']));
    }
  }, [unOrderedPlans]);

  useEffect(() => {
    dispatch(
      setMyPlans({
        plans:
          coursePlans.concat(
            featureFlagComplianceTraining ? compliancePlans : [],
          ) ?? [],
      }),
    );
  }, [coursePlans, compliancePlans, dispatch, featureFlagComplianceTraining]);

  // We check to see if a training plan has been completed to trigger the toast notification
  useEffect(() => {
    if (location.state && location.state.completedPlan) {
      toast.custom(toastObj => (
        <ToastMessageBlock id={toastObj.id}>
          {`${t('TrainingPlans.toastMessage.completed1')} ${
            location.state.completedPlan
          }
        
        ${t('TrainingPlans.toastMessage.completed2')}`}
        </ToastMessageBlock>
      ));
      // After we display the toast, we set the state back to false
      history.replace({
        pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
        state: { completedPlan: false },
      });
    }
  }, [location.state, history, t]);

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const learnUponEnrollmentIsComplete = enrolledCourse => {
    return (
      enrolledCourse?.status ===
        Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
      enrolledCourse?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED
    );
  };

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.COMPLIANCE]: {
      translationString: t('TrainingPlans.planCategories.compliance'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.dueDate'),
      value: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ];

  return (
    <>
      <div className="container-spacer" data-testid="currentPlansList">
        <div className="list-results-no-pad">
          {
            <>
              <SearchFilterHeader
                onChange={e => {
                  dispatch(clearMyPlansCheckboxFilters());
                  dispatch(
                    setMyPlansSearchFilter({ searchFilter: e.target.value }),
                  );
                }}
                onClear={() => dispatch(clearMyPlansSearchFilter())}
                searchPlaceholder={t('TrainingPlans.filtering.searchMyPlans')}
                searchValue={searchFilter}
                title={t('TrainingPlans.tabMy')}
              />
              {!!isSmAndDown && (
                <FilterAndSortButton
                  onSortChange={option => {
                    dispatch(setMyPlansSort({ sort: option.value }));
                  }}
                  sortOptions={sortOptions}
                  sortValue={sortOptions?.find(option => option.value === sort)}
                  text={`${t('TrainingPlans.filtering.show')} ${
                    filteredAndSortedPlans?.length ?? 0
                  } ${t('TrainingPlans.filtering.results')}`}
                >
                  <CheckboxFilterSection
                    labels={categoryLabels}
                    onChange={value => {
                      if (!!currentFilters.includes(value)) {
                        dispatch(removeMyPlansFilter({ filter: value }));
                      } else {
                        dispatch(addMyPlansCategoryFilter({ filter: value }));
                      }
                    }}
                    title={t('Browse.categories')}
                  />
                  <>
                    <StyledLeftSideOfCompletedMyPlansToggle
                      $isMobileView={isSmAndDown}
                    >
                      <CompletedMyPlansToggleLabel
                        $isMobileView={isSmAndDown}
                        variant="body1"
                      >
                        {t('TrainingPlans.filtering.includeCompleted')}
                      </CompletedMyPlansToggleLabel>
                    </StyledLeftSideOfCompletedMyPlansToggle>
                    <div>
                      <StyledSwitch
                        checked={showCompletedPlansOnly}
                        data-testid="Switch-Completed"
                        onChange={() => dispatch(toggleShowInProgressOnly())}
                      />
                    </div>
                  </>
                </FilterAndSortButton>
              )}
              <StyledContent>
                <MyPlansCardList>
                  {!isSmAndDown && (
                    <StickyFilterCard>
                      <CheckboxFilterSection
                        labels={categoryLabels}
                        onChange={value => {
                          if (!!currentFilters.includes(value)) {
                            dispatch(removeMyPlansFilter({ filter: value }));
                          } else {
                            dispatch(
                              addMyPlansCategoryFilter({ filter: value }),
                            );
                          }
                        }}
                        title={t('Browse.categories')}
                      />
                    </StickyFilterCard>
                  )}
                  <MyPlansCardsContainer>
                    <SortFilterHeader
                      handleOnCompletedPlansOnlyToggle={() => {
                        dispatch(toggleShowInProgressOnly());
                      }}
                      label={t('TrainingPlans.filtering.sortBy')}
                      onChange={option => {
                        dispatch(setMyPlansSort({ sort: option.value }));
                      }}
                      onClear={() => {
                        dispatch(clearMyPlansCheckboxFilters());
                      }}
                      options={sortOptions}
                      showClear={false}
                      showCompletedPlansOption={true}
                      showMyCompletedPlansOnlySwitchValue={
                        showCompletedPlansOnly
                      }
                      text={`${total ?? 0} ${t(
                        'TrainingPlans.filtering.plans',
                      )}`}
                      value={sortOptions?.find(option => option.value === sort)}
                    />

                    <ClearFiltersHeader
                      aliases={categoryLabels}
                      clearAllFilters={() => {
                        dispatch(clearMyPlansCheckboxFilters());
                      }}
                      clearFilter={value => {
                        dispatch(removeMyPlansFilter({ filter: value }));
                      }}
                      filters={currentFilters}
                    />
                    <MyPlanCardsList>
                      <LoadingOverlay isOpen={isFetching} />
                      {!filteredAndSortedPlans?.length && !isFetching && (
                        <Typography variant="body1">
                          {t('TrainingPlans.noTrainingPlans')}
                        </Typography>
                      )}
                      {!!filteredAndSortedPlans?.length && !isFetching && (
                        <>
                          <div className="list-results-no-pad">
                            {filteredAndSortedPlans
                              .filter(
                                enrolledCourse =>
                                  !enrolledCourse.operatorDisabled,
                              )
                              .map((plan, idx) => {
                                return (
                                  <PlanCard
                                    completionDate={
                                      plan.courseName
                                        ? plan.completedDate
                                        : plan?.status?.[0].completionDate
                                    }
                                    isComplete={
                                      plan?.courseName
                                        ? learnUponEnrollmentIsComplete(plan)
                                        : plan?.status?.[0].status ===
                                          Constants.TRAINING_PLANS.COMPLETED
                                    }
                                    isCompliance={!!plan.courseName}
                                    isMyPlan={true}
                                    key={idx}
                                    planDetails={
                                      plan.courseName
                                        ? { ...plan }
                                        : { ...plan.checklist }
                                    }
                                    refetch={refetch}
                                    selectedDueDate={
                                      plan?.courseName
                                        ? new Date(plan?.dueDate).getTime()
                                        : new Date(
                                            plan?.status?.[0].dueDate,
                                          ).getTime()
                                    }
                                    stepsCompleted={
                                      plan?.courseName
                                        ? null
                                        : plan?.status?.[0].stepsComplete
                                    }
                                  />
                                );
                              })}
                            <LoadMorePaginator
                              onClick={() => dispatch(loadMoreMyPlans())}
                              showing={showing}
                              showingText={t('TrainingPlans.showingXOfYPlans', {
                                showing,
                                total,
                              })}
                              total={total}
                            />
                          </div>
                        </>
                      )}
                    </MyPlanCardsList>
                  </MyPlansCardsContainer>
                </MyPlansCardList>
              </StyledContent>
            </>
          }
        </div>
      </div>
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const MyPlansCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const MyPlansCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const MyPlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const StyledPlansCount = styled.div`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-weight: 600;
  margin-bottom: 10px;
`;

const StyledLeftSideOfCompletedMyPlansToggle = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: ${props => (!!props.$isMobileView ? 600 : 'default')};
`;

const CompletedMyPlansToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray7};
  margin-top: ${props => (!!props.$isMobileView ? '16px' : null)};
`;

const StyledSwitch = styled(Switch)`
  margin-top: 16px;
`;

export default MyPlansTab;
