import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Breadcrumbs as CfaBreadcrumbs } from 'cfa-react-components';
import { Link } from 'react-router-dom';

const BreadcrumbLinks = ({
  breadcrumbs,
  className,
  'data-testid': DataTestId,
}) => {
  const mapBreadCrumbsToLinks = () => {
    return breadcrumbs?.map(breadcrumb => {
      if (breadcrumb?.to) {
        return {
          ...breadcrumb,
          as: Link,
        };
      } else {
        return {
          ...breadcrumb,
        };
      }
    });
  };

  const mappedBreadcrumbs = mapBreadCrumbsToLinks();

  return (
    <PageHeaderContainer className={className} data-testid={DataTestId}>
      {breadcrumbs?.length > 0 && (
        <PathBreadcrumbs breadcrumbs={mappedBreadcrumbs} overrideMarginLeft />
      )}
    </PageHeaderContainer>
  );
};

BreadcrumbLinks.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

BreadcrumbLinks.defaultProps = {
  breadcrumbs: [],
  className: '',
  'data-testid': 'BreadcrumbLinks',
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const PathBreadcrumbs = styled(CfaBreadcrumbs)`
  & a,
  button,
  p {
    text-transform: capitalize;
  }
`;

export default BreadcrumbLinks;
