import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Surface, TextField } from 'cfa-react-components';
import { IconX, IconSearch } from '@tabler/icons-react';
import { forwardRef, useRef, useState } from 'react';
import { selectSearchTerms } from 'store/search/selectors';
import { useClickOutside } from 'hooks/useClickOutside';
import { removeSearchTerm, saveSearchTerm } from 'store/search/slice';
import { useTranslation } from 'react-i18next';
import SearchbarSuggestionsDropdown from './SearchbarSuggestionsDropdown';

const Searchbar = forwardRef(
  (
    {
      searchValue,
      placeholder,
      onChange,
      onSubmit,
      onClear,
      onFocus,
      onBlur,
      showRecentSearchDropdown,
      elevation,
      fullWidth,
      'data-testid': DataTestId,
      className,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pastSearchTerms = useSelector(selectSearchTerms);
    const searchInputDropDownRef = useRef();
    const [suggestionsIsOpen, setSuggestionsIsOpen] = useState(false);

    const onFocusSearchInput = () => {
      if (showRecentSearchDropdown) {
        setSuggestionsIsOpen(true);
      }
      onFocus();
    };

    const onBlurSearchInput = () => {
      if (showRecentSearchDropdown) {
        setSuggestionsIsOpen(false);
      }
      onBlur();
    };

    const onSubmitSearch = e => {
      e.preventDefault();
      if (searchValue.trim() === '') {
        return;
      }
      if (showRecentSearchDropdown) {
        dispatch(saveSearchTerm(searchValue));
        setSuggestionsIsOpen(false);
      }
      onSubmit();
    };

    const onClickSuggestion = suggestion => {
      if (showRecentSearchDropdown) {
        setSuggestionsIsOpen(false);
      }
      onSubmit(suggestion);
    };

    const onClickRemoveSuggestion = suggestion => {
      if (showRecentSearchDropdown) {
        dispatch(removeSearchTerm(suggestion));
      }
    };

    useClickOutside(searchInputDropDownRef, onBlurSearchInput);

    return (
      <SearchbarWrapper
        className={className}
        data-testid={DataTestId}
        elevation={elevation}
        ref={searchInputDropDownRef}
      >
        <SearchForm onSubmit={onSubmitSearch}>
          <SearchTextInput
            data-testid="Search"
            fullWidth={fullWidth}
            onChange={onChange}
            onClick={onFocusSearchInput}
            placeholder={placeholder || t('Search.searchForResources')}
            ref={ref}
            textAlign="start"
            value={searchValue}
          />
          <FormButton type="submit">
            <SearchIcon
              $isInFocus={!!searchValue}
              data-testid="SearchMagnifyButton"
            />
          </FormButton>
          {searchValue && (
            <ClearIcon data-testid="ClearSearchButton" onClick={onClear} />
          )}
        </SearchForm>
        {suggestionsIsOpen && (
          <SearchbarSuggestionsDropdown
            onRemoveSuggestion={onClickRemoveSuggestion}
            onSetSuggestion={onClickSuggestion}
            suggestions={pastSearchTerms}
          />
        )}
      </SearchbarWrapper>
    );
  },
);

Searchbar.propTypes = {
  searchValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showRecentSearchDropdown: PropTypes.bool,
  elevation: PropTypes.number,
  fullWidth: PropTypes.bool,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
};

Searchbar.defaultProps = {
  searchValue: '',
  placeholder: '',
  onSubmit: () => {},
  onFocus: () => {},
  onBlur: () => {},
  showRecentSearchDropdown: false,
  elevation: 0,
  fullWidth: false,
  'data-testid': 'Searchbar',
  className: '',
};

const SearchbarWrapper = styled(Surface)`
  position: relative;
  overflow: visible;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

const SearchForm = styled.form`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

const SearchTextInput = styled(TextField)`
  & input {
    padding-right: 80px;
  }
`;

const SearchIcon = styled(IconSearch)`
  height: 18px;
  width: 18px;
  color: ${({ theme, $isInFocus }) =>
    $isInFocus ? theme.grayScale.gray6 : theme.grayScale.gray3};
`;

const ClearIcon = styled(IconX)`
  height: 18px;
  width: 18px;
  position: absolute;
  right: 48px;
  top: 14px;
  cursor: pointer;
`;

const FormButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 0;
  line-height: 0;
`;

export default Searchbar;
