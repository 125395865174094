import { device } from 'util/device';
import StepWizard from 'react-step-wizard';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalFooter,
  Button,
  ModalBody,
  ModalHeader,
} from 'cfa-react-components';
import React from 'react';

const StepWizardPopUp = ({
  setStepIndex,
  initialStep,
  open,
  closePopup,
  step,
  setStepWizard,
  steps,
  'data-testid': dataTestId,
  className,
}) => {
  return (
    <Modal
      className={className}
      data-testid={dataTestId}
      onClose={closePopup}
      show={open}
      size="md"
    >
      <ModalHeader></ModalHeader>
      <StepWizard
        initialStep={initialStep}
        instance={setStepWizard}
        onStepChange={({ activeStep }) => setStepIndex(activeStep)}
        transitions={{
          enterRight: '',
          enterLeft: '',
          exitRight: '',
          exitLeft: '',
        }}
      >
        {Object.keys(steps).map((key, idx) => (
          <React.Fragment key={idx}>
            <ModalBody>{steps[key].ContentData()}</ModalBody>
          </React.Fragment>
        ))}
      </StepWizard>
      <ModalFooter>
        <DialogButton
          color="secondary"
          data-testid="PrimaryConfirmButton"
          onClick={step.onClickBack}
          variant="outlined"
        >
          {step.labelButtonDeny}
        </DialogButton>
        <DialogButton
          color="secondary"
          data-testid="SecondaryConfirmButton"
          disabled={step.isDisabledButtonNext}
          onClick={step.onClickNext}
          variant="filled"
        >
          {step.labelButtonNext}
        </DialogButton>
      </ModalFooter>
    </Modal>
  );
};

export default StepWizardPopUp;

StepWizardPopUp.propTypes = {
  setStepIndex: PropTypes.func.isRequired,
  initialStep: PropTypes.number,
  open: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  setStepWizard: PropTypes.func.isRequired,
  steps: PropTypes.object.isRequired,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
};

StepWizardPopUp.defaultProps = {
  'data-testid': 'StepWizardPopUp',
  className: '',
};

const DialogButton = styled(Button)`
  @media ${device.desktop} {
    flex: 1;
  }
`;

StepWizardPopUp.defaultProps = { initialStep: 1 };
