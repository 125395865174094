export const stripHtml = html => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const writeScriptTagIfNotPresent = src => {
  return new Promise(resolve => {
    if (document.querySelectorAll(`script[src='${src}']`).length === 0) {
      const tag = document.createElement('script');
      tag.setAttribute('src', src);
      tag.onload = resolve;
      document.body.appendChild(tag);
    } else {
      resolve();
    }
  });
};
