import { createSelector } from 'reselect';

export const trainingModeReducerState = state => state.trainingMode;

export const selectTrainingModeModalIsOpen = createSelector(
  trainingModeReducerState,
  state => state.isOpen,
);

export const selectIsTrainingMode = createSelector(
  trainingModeReducerState,
  state => state.isTrainingMode,
);

export const selectShowProgressModal = createSelector(
  trainingModeReducerState,
  state => state.showProgressModal,
);

export const selectAssignedChecklistStatus = createSelector(
  trainingModeReducerState,
  state => state.assignedChecklistStatus,
);
