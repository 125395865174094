import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { pluralize } from 'util/language';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLoadMore } from 'hooks/useLoadMore';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  pathwayApi,
  useAddSearchResultFeedbackMutation,
} from 'services/pathwayApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import GenericError from 'sharedComponents/app/GenericError';
import { formatBugsnagErrorMessage } from 'bugsnag';
import DocumentHead from 'DocumentHead';
import { useState, useEffect } from 'react';
import theme from 'styles/theme';
import { getCurrentLanguage } from 'i18n/language';
import { useGoogleAnalytics } from 'googleAnalytics/useGoogleAnalytics';
import { useDispatch, useSelector } from 'react-redux';
import { scrollPosition } from 'store/scrollPosition/selectors';
import {
  selectUser,
  selectUsersHighestPermissionLevel,
} from 'store/user/selectors';
import { setData, setId, setPageNumber } from 'store/scrollPosition/slice';
import { Button, LoadingIndicator, Typography } from 'cfa-react-components';
import SearchResultCard from './components/SearchResultCard';

const SearchResultsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const position = useSelector(scrollPosition);
  const dispatch = useDispatch();
  const [activeThumbsUp, setActiveThumbsUp] = useState([]);
  const [activeThumbsDown, setActiveThumbsDown] = useState([]);
  const useQuery = paramName =>
    new URLSearchParams(useLocation().search)?.get(paramName);
  const query = useQuery('query');
  const languageCode = getCurrentLanguage();
  const user = useSelector(selectUser);
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  const [currentData, setCurrentData] = useState([]);

  const {
    result,
    isFetching,
    error: fetchingError,
  } = useLoadMore(
    pathwayApi.endpoints.getSearchResults,
    query,
    languageCode,
    100,
  );
  const { searchResultFeedback: featureFlagSearchResultFeedback } = useFlags();

  const gtag = useGoogleAnalytics();
  useEffect(() => {
    if (
      result?.status === 'fulfilled' &&
      result.showingResults &&
      query?.length > 0
    ) {
      if (gtag !== null) {
        // ensure we have initialized gtag scripts
        gtag('event', 'search_result', {
          query: query ?? 'empty',
          num_results: result?.numFound ?? 0,
          user_perms: userPermissionLevel ?? 'empty',
          cfa_user_id: user.userId ?? 'empty',
          user_type: user.userType ?? 'empty',
          restaurants: user.locations?.toString() ?? 'empty',
          datetime: Date().toLocaleString() ?? 'empty',
          env: Constants.ENV ?? 'empty',
        });
      }
    }
  }, [
    gtag,
    query,
    result,
    user.locations,
    user.type,
    user.userId,
    user.userType,
    userPermissionLevel,
  ]);

  useEffect(() => {
    if (position.pageNumber) {
      result.fetchCustomPage(position.pageNumber);
    }
  });

  useEffect(() => {
    if (result.data.length && position.id) {
      // The id needs to have a letter in front of it so I chose 'a'
      const category = document.querySelector('#a' + position.id);
      category?.scrollIntoView({ behavior: 'smooth' });
    }
  });

  useEffect(() => {
    if (result.data && !result.isFetching) {
      setCurrentData([...new Set(result.data)]);
    }
  }, [result.data, result.isFetching]);

  useEffect(() => {
    if (!position.pageNumber) {
      dispatch(setPageNumber(1));
    }
  }, [position.pageNumber, dispatch]);

  /* Leaving comment in the code as a reminder of how to handle mutation
      with trigger (addSearchResultFeedback) and response (addResult)
  const [addSearchResultFeedback, addResult] = useAddSearchResultFeedbackMutation();
 useEffect(() => {
   console.log('result', result, 'addResult', addResult);
   if (addResult.error)  console.log(addResult.error);
  }, [result, addResult]);  */
  const [addSearchResultFeedback] = useAddSearchResultFeedbackMutation();
  const onSearchResultFeedback = ({ resultId, queryId, feedback }) => {
    if (!queryId || !resultId) {
      Bugsnag.notify(
        new Error(
          `No ${!queryId ? 'queryId' : 'resultId'} found in SearchResults`,
        ),
      );
      return;
    }
    addSearchResultFeedback({
      resultId,
      queryId,
      relevanceValue: feedback,
    })
      .unwrap()
      .catch(err => {
        const errorDetails = {
          error: JSON.stringify(err),
          resultId: resultId,
          queryId: queryId,
          query: query,
        };
        Bugsnag.notify(
          new Error(
            `Error in addSearchResultFeedback: ${JSON.stringify(errorDetails)}`,
          ),
        );
      });
  };

  const goToDetailsPage = id => {
    history.push(`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${id}`);
  };

  const onSearchCardClick = (id, resultId, documentId) => {
    dispatch(setId(id));
    dispatch(setData(result.data));
    onSearchResultFeedback({
      resultId,
      queryId: result.queryId,
      feedback: Constants.KENDRA.SEARCH_RESULTS.FEEDBACK.RELEVANT,
    });
    goToDetailsPage(documentId);
  };

  const getFeedbackText = id => {
    const thanksEl = color => (
      <span style={{ color }}>{t('Search.submitFeedback')}</span>
    );
    if (activeThumbsUp.includes(id)) {
      return thanksEl(theme.tertiaryPalette.green);
    } else if (activeThumbsDown.includes(id)) {
      return thanksEl(theme.secondaryPalette.red);
    } else {
      return <span>{t('Search.rateResult')}:</span>;
    }
  };

  const onThumbsUp = (id, resultId) => {
    setActiveThumbsUp([...activeThumbsUp, id]);
    setActiveThumbsDown(activeThumbsDown.filter(item => id !== item));
    onSearchResultFeedback({
      resultId,
      queryId: result.queryId,
      feedback: Constants.KENDRA.SEARCH_RESULTS.FEEDBACK.RELEVANT,
    });
  };
  const onThumbsDown = (id, resultId) => {
    setActiveThumbsUp(activeThumbsUp.filter(item => id !== item));
    setActiveThumbsDown([...activeThumbsDown, id]);
    onSearchResultFeedback({
      resultId,
      queryId: result.queryId,
      feedback: Constants.KENDRA.SEARCH_RESULTS.FEEDBACK.NOT_RELEVANT,
    });
  };

  const onSearchMisspellingSuggestionClick = suggestion => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`,
      search: `query=${suggestion}`,
    });
  };

  if (isApiError(fetchingError)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(fetchingError));
    return <GenericError />;
  }

  const onLoadMore = () => {
    dispatch(setId(''));
    dispatch(setData({}));
    dispatch(setPageNumber(position.pageNumber + 1));
    result.fetchNextPage(position.pageNumber + 1);
  };

  return (
    <SearchResultsContainer>
      <LoadingOverlay isOpen={isFetching && result?.data?.length === 0} />
      <DocumentHead pageTitle={query} />
      {result.showingResults && !isFetching ? (
        <>
          {result.misspellingSuggestion && (
            <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
              {t('Search.showingResultsSuggestion')}
              {': '}
              <StyledMisspellingSuggestion
                data-testid="MisspellingSuggestion"
                onClick={() =>
                  onSearchMisspellingSuggestionClick(
                    result.misspellingSuggestion,
                  )
                }
              >
                {result.misspellingSuggestion}
              </StyledMisspellingSuggestion>
            </StyledMisspellingSuggestionWrapper>
          )}
          <Typography variant="body1">
            {result.numFound} {t('Search.numberOfResultsFor')} <b>{query}</b>
          </Typography>
        </>
      ) : (
        <Typography variant="body1">
          {result.numFound} {t('Search.numberOfResultsFor')} <b>{query}</b>
        </Typography>
      )}
      <div>
        {!result?.data?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          currentData?.map(
            ({
              icon,
              id,
              documentId,
              locationTypes,
              marketTest,
              name,
              resultId,
              type,
            }) => (
              <SearchResultCard
                documentId={documentId}
                documentType={type}
                featureFlagSearchResultFeedback={
                  featureFlagSearchResultFeedback
                }
                getFeedbackText={getFeedbackText}
                icon={icon}
                id={id}
                isMarketTest={marketTest}
                isThumbsDown={activeThumbsDown.includes(id)}
                isThumbsUp={activeThumbsUp.includes(id)}
                key={id}
                locationTypes={locationTypes}
                name={name}
                onClick={onSearchCardClick}
                onThumbsDown={onThumbsDown}
                onThumbsUp={onThumbsUp}
                resultId={resultId}
              />
            ),
          )
        )}
      </div>
      {result && result.hasNextPage && (
        <LoadMoreButton
          color="secondary"
          data-testid="LoadMore"
          disabled={isFetching}
          onClick={onLoadMore}
          size="lg"
          variant="filled"
        >
          <>
            {isFetching && (
              <LoadMoreLoadingIndicator disabled size="sm" variant="inline" />
            )}
            {t('Button.loadMore')}
          </>
        </LoadMoreButton>
      )}
      {result.showingResults && (
        <StyledShowingResultsFooter variant="body1">
          {t('Search.showingResultsFooter', {
            showingResults: result.showingResults,
            numberOfResults: result.numFound <= 100 ? result.numFound : '100+',
            resultsText: pluralize(
              result.numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
          })}
        </StyledShowingResultsFooter>
      )}
      <SpacerDiv />
    </SearchResultsContainer>
  );
};

const SearchResultsContainer = styled.div`
  position: relative;
`;

const LoadMoreButton = styled(Button)`
  margin: 0 auto;
  margin-top: 32px;
  display: block;
`;
const LoadMoreLoadingIndicator = styled(LoadingIndicator)`
  margin-right: 10px;
  margin-top: -5px;
`;
const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 15px;
`;
const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;
const StyledShowingResultsFooter = styled(Typography)`
  text-align: center;
  margin-top: 16px;
  color: ${props => props.theme.grayScale.gray6};
`;
const StyledNoResults = styled.div`
  padding: 30px 20px;
`;
const SpacerDiv = styled.div`
  height: 400px;
`;

export default SearchResultsPage;
