import Constants from 'constants/index';
import { isAdminPath } from 'util/url';
import styled from 'styled-components';
import { TabItem, Tabs } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const AdminTabs = {
  BROWSE: 'browse',
};

const AdminSubHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  let activeItemKey = '';

  if (isAdminPath(location)) {
    activeItemKey = AdminTabs.BROWSE;
  }

  return (
    <Tabs activeItemKey={activeItemKey} color="secondary" onChange={() => {}}>
      <HeaderTabItem itemKey={AdminTabs.BROWSE}>
        <HeaderLink to={`/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`}>
          {t('Admin.browse')}
        </HeaderLink>
      </HeaderTabItem>
    </Tabs>
  );
};

const HeaderTabItem = styled(TabItem)`
  & > button {
    padding: 0;
    margin-bottom: 24px;
    margin-top: 8px;
  }
`;

const HeaderLink = styled(Link)`
  padding: 16px 32px;
`;

export default AdminSubHeader;
