export const generateTotalTime = (totalMinutes, hour, mins) => {
  const timeSpentHours = Number.isInteger(totalMinutes)
    ? Math.floor(totalMinutes / 60)
    : 0;
  const timeSpentMinutes = Number.isInteger(totalMinutes)
    ? Math.floor(totalMinutes % 60)
    : 0;
  const timeSpentOnPlanString =
    timeSpentHours > 0
      ? `${timeSpentHours} ${hour} ${timeSpentMinutes} ${mins}`
      : `${timeSpentMinutes} ${mins}`;
  return timeSpentOnPlanString;
};

export const generateTotalTimeFromSteps = (steps, hours, mins) => {
  const timeSpentOnPlan = steps
    ?.map(it => it.stepDuration)
    .reduce((acc, a) => acc + a, 0);
  const timeSpentHrs = Number.isInteger(timeSpentOnPlan)
    ? Math.floor(timeSpentOnPlan / 3600)
    : 0;
  const timeSpentMins = Number.isInteger(timeSpentOnPlan)
    ? Math.ceil((timeSpentOnPlan % 3600) / 60)
    : 0;
  const timeSpentOnPlanString =
    timeSpentHrs > 0
      ? `${timeSpentHrs} ${hours} ${timeSpentMins} ${mins}`
      : `${timeSpentMins} ${mins}`;
  return timeSpentOnPlanString;
};
