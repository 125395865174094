import { getNameFromLanguage } from 'util/language';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Draggable, Container as DragAndDropContainer } from 'react-smooth-dnd';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { arrayMoveImmutable } from 'array-move';
import { subcategoryId } from 'store/subcategory/selectors';
import { Tag } from 'cfa-react-components';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconEdit,
  IconEye,
  IconEyeOff,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import Document from './Document';
import SubtitleDocuments from './SubtitleDocuments';
import AddResourceToSubtitleButton from './AddResourceToSubtitleButton';

const Subtitle = ({
  countryName,
  handleDocumentDelete,
  handleDocumentVisibilityToggleForSubtitle,
  isNewlyAdded,
  onAdminAddResourceToSubtitle,
  onDeleteSubtitle,
  onEditSubtitle,
  onSaveSubtitles,
  openInNewTab,
  RenderDocumentEndContent,
  showAdminFunctionality,
  showSubtitleEditFunctionality,
  subcategoryIndex,
  subtitle,
  subtitleIndex,
  subtitles,
  triggerEnableCancelButton,
  userIsAdmin,
}) => {
  const { t } = useTranslation();
  const [mutableSubtitle, setMutableSubtitle] = useState(subtitle);
  const [editSubtitle, setEditSubtitle] = useState(false);
  const [showConfirmationVisibilityPopUp, setShowConfirmationVisibilityPopUp] =
    useState(false);

  useEffect(() => {
    if (subtitle) {
      setMutableSubtitle(subtitle);
    }
  }, [subtitle]);

  const onSubtitleEdit = (index, name, documents, visibleDocuments) => {
    onEditSubtitle(index, name, documents, visibleDocuments);
    setEditSubtitle(true);
    triggerEnableCancelButton();
  };

  const handleSubtitleVisibility = () => {
    const copiedSubtitles = cloneDeep(subtitles);
    copiedSubtitles[subtitleIndex] = {
      ...mutableSubtitle,
      enabled: !mutableSubtitle.enabled,
    };
    onSaveSubtitles(copiedSubtitles, subcategoryIndex);
    setShowConfirmationVisibilityPopUp(false);
  };

  const updateArrayIndex = (array, removedIndex, addedIndex) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(item => {
      return { ...item };
    });
  };

  const onDrop = dropResult => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedDocuments = updateArrayIndex(
      mutableSubtitle.documents,
      removedIndex,
      addedIndex,
    );
    setMutableSubtitle({ ...mutableSubtitle, documents: updatedDocuments });
    const copiedSubtitles = cloneDeep(subtitles);
    copiedSubtitles[subtitleIndex] = {
      ...mutableSubtitle,
      documents: updatedDocuments,
    };
    onSaveSubtitles(copiedSubtitles, subcategoryIndex);
  };

  return (
    <>
      <Draggable key={`subtitle-${subtitleIndex}`}>
        <StyledSubtitleWrapper $index={subtitleIndex} key={subtitleIndex}>
          <StyledSubtitleHeaderWrapper>
            {!!userIsAdmin && !!showAdminFunctionality && (
              <StyledSubtitleSixDotIcon className="subtitle-drag-handle" />
            )}
            <StyledSubTitleHeader>
              {getNameFromLanguage(mutableSubtitle.name)}
            </StyledSubTitleHeader>
            {!!userIsAdmin && !!showAdminFunctionality && (
              <AdminFeaturesWrapper>
                {mutableSubtitle.enabled ? (
                  <StyledShowTag
                    label={t('Admin.categoryIsVisible')}
                    leadingElement={<IconEye width={10} />}
                    onClick={() => setShowConfirmationVisibilityPopUp(true)}
                    variant="filled"
                  />
                ) : !mutableSubtitle.enabled ? (
                  <StyledHideTagWrapper
                    onClick={() => setShowConfirmationVisibilityPopUp(true)}
                  >
                    <StyledHideTag
                      disabled
                      label={t('Admin.categoryHidden')}
                      leadingElement={<IconEyeOff width={10} />}
                      variant="filled"
                    />
                  </StyledHideTagWrapper>
                ) : null}
                <PopoverMenuButton>
                  <PopoverMenuItemButton
                    icon={<IconEdit />}
                    onClick={() =>
                      onSubtitleEdit(
                        subtitleIndex,
                        subtitle.name,
                        subtitle.documents,
                        subtitle.visibleDocuments,
                      )
                    }
                    text={t('Button.edit')}
                  />
                  <PopoverMenuItemButton
                    icon={<IconTrash />}
                    isDestructive={true}
                    onClick={() =>
                      onDeleteSubtitle(subtitleIndex, mutableSubtitle.name)
                    }
                    text={t('Button.delete')}
                  />
                </PopoverMenuButton>
              </AdminFeaturesWrapper>
            )}
          </StyledSubtitleHeaderWrapper>
          <DragAndDropContainer
            className="StyledDragAndDropContainer"
            dragHandleSelector=".document-drag-handle"
            onDrop={e => onDrop(e)}
          >
            {showSubtitleEditFunctionality && (
              <SubtitleDocuments
                contentToRenderAtDocumentEnd={RenderDocumentEndContent}
                handleDocumentDelete={handleDocumentDelete}
                handleDocumentVisibilityToggleForSubtitle={
                  handleDocumentVisibilityToggleForSubtitle
                }
                openDocumentInNewTab={openInNewTab}
                showAdminFunctionality={showAdminFunctionality}
                subcategoryId={subcategoryId}
                subtitle={subtitle}
                userIsAdmin={userIsAdmin}
              />
            )}
            {(editSubtitle || !showAdminFunctionality) &&
              mutableSubtitle.documents?.map((document, documentIndex) => (
                <Document
                  document={document}
                  index={documentIndex}
                  key={document.id}
                  openInNewTab={openInNewTab}
                  RenderDocumentEndContent={RenderDocumentEndContent}
                  showAdminFunctionality={showAdminFunctionality}
                  subcategoryId={subcategoryId}
                  userIsAdmin={userIsAdmin}
                />
              ))}
          </DragAndDropContainer>
        </StyledSubtitleWrapper>
      </Draggable>
      {isNewlyAdded && (
        <AddResourceToSubtitleButton
          onAdminAddResourceToSubtitle={onAdminAddResourceToSubtitle}
        />
      )}
      <ConfirmationModal
        bodyText={
          mutableSubtitle.enabled
            ? t('Admin.makeHiddenSubtitleBody', {
                categoryName: getNameFromLanguage(mutableSubtitle.name),
                countryName,
              })
            : t('Admin.makeVisibleSubtitleBody', {
                categoryName: getNameFromLanguage(mutableSubtitle.name),
                countryName,
              })
        }
        headerText={
          mutableSubtitle.enabled
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showConfirmationVisibilityPopUp}
        onClose={() => setShowConfirmationVisibilityPopUp(false)}
        primaryButtonColor={mutableSubtitle.enabled ? 'primary' : 'secondary'}
        primaryButtonHandler={handleSubtitleVisibility}
        primaryButtonText={
          mutableSubtitle.enabled
            ? t('Button.yesMakeHidden')
            : t('Button.yesMakeVisible')
        }
        secondaryButtonHandler={prev =>
          setShowConfirmationVisibilityPopUp(!prev)
        }
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

Subtitle.propTypes = {
  countryName: PropTypes.string.isRequired,
  handleDocumentDelete: PropTypes.func,
  handleDocumentVisibilityToggleForSubtitle: PropTypes.func,
  isNewlyAdded: PropTypes.bool,
  onAdminAddResourceToSubtitle: PropTypes.func,
  onDeleteSubtitle: PropTypes.func,
  onEditSubtitle: PropTypes.func,
  onSaveSubtitles: PropTypes.func,
  openInNewTab: PropTypes.bool,
  RenderDocumentEndContent: PropTypes.func,
  showAdminFunctionality: PropTypes.bool,
  showSubtitleEditFunctionality: PropTypes.bool,
  subcategoryIndex: PropTypes.number.isRequired,
  subtitle: PropTypes.object.isRequired,
  subtitleIndex: PropTypes.number.isRequired,
  subtitles: PropTypes.array.isRequired,
  triggerEnableCancelButton: PropTypes.func,
  userIsAdmin: PropTypes.bool.isRequired,
};

Subtitle.defaultProps = {
  handleDocumentDelete: () => {},
  handleDocumentVisibilityToggleForSubtitle: () => {},
  isNewlyAdded: false,
  onAdminAddResourceToSubtitle: () => {},
  onDeleteSubtitle: null,
  onEditSubtitle: null,
  onSaveSubtitles: () => {},
  openInNewTab: false,
  RenderDocumentEndContent: null,
  showAdminFunctionality: false,
  showSubtitleEditFunctionality: false,
  triggerEnableCancelButton: () => {},
};

const StyledSubtitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const StyledSubtitleSixDotIcon = styled(IconGripVertical)`
  width: 25px;
  height: 25px;
  margin: 0 1em;
  cursor: move;
  z-index: 2;
  color: ${({ theme }) => theme.grayScale.gray3};
`;

const AdminFeaturesWrapper = styled('div')`
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledShowTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 16px;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledHideTagWrapper = styled('span')`
  margin-left: 16px;
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledSubtitleHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledSubTitleHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.grayScale.gray6};
  margin: ${props => (props.index === 0 ? '0px' : '15px')} 0 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export default Subtitle;
