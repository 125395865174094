import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import StepWizardPopUp from 'sharedComponents/app/popups/StepWizardPopUp';
import { TextField, Typography } from 'cfa-react-components';

const AddPlanWithLocations = ({
  open,
  setOpen,
  locations,
  planName,
  selectedLocations,
  submitHandler,
}) => {
  const isMultiLocation = locations.length > 1;
  const initialStep = isMultiLocation ? 1 : 2;
  const [newLocations, setNewLocations] = useState(
    isMultiLocation ? selectedLocations : locations,
  );
  const closePopup = () => setOpen(false);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] = useState();
  const [input, setInput] = useState(planName);

  const Step1 = () => (
    <>
      <DialogHeader variant="h3">
        {t('TrainingPlans.addPlanMenu.chooseLocations')}
      </DialogHeader>
      <DialogHeader variant="body1">
        {t('TrainingPlans.whichLocations')}
      </DialogHeader>
      <CheckboxGroup>
        {locations.map((id, idx) => (
          <CheckboxList
            id={id}
            idx={idx}
            key={idx}
            selectedLocations={newLocations}
            setSelectedLocations={setNewLocations}
          />
        ))}
      </CheckboxGroup>
    </>
  );

  const Step2 = () => (
    <>
      <DialogHeader variant="h3">
        {t('TrainingPlans.givePlanName')}
      </DialogHeader>
      <TextField
        autoFocus
        fullWidth
        name={'input'}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' && submitHandler({ newLocations, name: input })
        }
        placeholder={t('TrainingPlans.planNamePlaceholder')}
        value={input}
      />
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.cancel'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
      },
      onClickNext: () => {
        stepWizard.nextStep();
      },
      isDisabledButtonNext: !newLocations.length,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: isMultiLocation ? t('Button.back') : t('Button.cancel'),
      labelButtonNext: t('Button.create'),
      onClickBack: () => {
        isMultiLocation ? stepWizard.previousStep() : closePopup();
      },
      onClickNext: () => {
        submitHandler({ newLocations, name: input });
      },
      isDisabledButtonNext: !input,
      ContentData: Step2,
    },
  };

  const step = stepsData[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={open}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

AddPlanWithLocations.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  selectedLocations: PropTypes.array.isRequired,
  planName: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
};

AddPlanWithLocations.defaultProps = {
  planName: '',
};

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 8px auto;
  width: fit-content;
  gap: 8px;
`;

const DialogHeader = styled(Typography)`
  margin-bottom: 16px;
`;
export default AddPlanWithLocations;
