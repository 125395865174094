import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepWizardPopUp from 'sharedComponents/app/popups/StepWizardPopUp';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import { Typography } from 'cfa-react-components';

const CopyTemplatePopup = ({
  open,
  setOpen,
  locations,
  planName,
  reopenTemplates,
  submitHandler,
}) => {
  const ref = useRef();
  const isMultiLocation = locations.length > 1;
  const initialStep = isMultiLocation ? 1 : 2;
  const [newLocations, setNewLocations] = useState(locations);
  const closePopup = () => setOpen(false);
  const { t } = useTranslation();

  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] = useState();
  const [input, setInput] = useState(
    `${planName} ${t('TrainingPlans.duplicatePlanInputCopy')}`,
  );

  useEffect(() => {
    if (stepIndex === 2) {
      ref?.current?.focus();
    }
  }, [stepIndex]);

  const Step1 = () => (
    <>
      <StyledTitle color="secondary" variant="h3">
        {t('TrainingPlans.whichLocations')}
      </StyledTitle>
      <CheckboxWrapper>
        <CheckboxListWrapper>
          {locations.map((id, idx) => (
            <CheckboxList
              id={id}
              idx={idx}
              key={idx}
              selectedLocations={newLocations}
              setSelectedLocations={setNewLocations}
            />
          ))}
        </CheckboxListWrapper>
      </CheckboxWrapper>
    </>
  );

  const Step2 = () => (
    <>
      <StyledTitle color="secondary" variant="h3">
        {t('TrainingPlans.givePlanName')}
      </StyledTitle>
      <input
        autoFocus
        className={'form-control'}
        name={'input'}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' && submitHandler({ newLocations, name: input })
        }
        placeholder={t('TrainingPlans.planNamePlaceholder')}
        ref={ref}
        value={input}
      />
      {!input.length && (
        <span className="error-w-icon">{`${t('Generic.plan')} ${t(
          'TrainingPlans.requiredValue',
        )}`}</span>
      )}
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.back'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
        reopenTemplates();
      },
      onClickNext: () => {
        stepWizard.nextStep();
      },
      isDisabledButtonNext: !newLocations.length,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: isMultiLocation ? t('Button.back') : t('Button.cancel'),
      labelButtonNext: t('Button.create'),
      onClickBack: () => {
        isMultiLocation ? stepWizard.previousStep() : closePopup();
      },
      onClickNext: () => {
        submitHandler({ newLocations, name: input });
      },
      isDisabledButtonNext: !input,
      ContentData: Step2,
    },
  };

  const step = stepsData[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={open}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

const StyledTitle = styled(Typography)`
  margin-bottom: 20px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const CheckboxListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

CopyTemplatePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  planName: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
  reopenTemplates: PropTypes.func.isRequired,
};

CopyTemplatePopup.defaultProps = {
  planName: '',
};

export default CopyTemplatePopup;
