import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from 'cfa-react-components';

const SquareIconButton = ({ children, ...props }) => {
  return <StyledIconButton {...props}>{children}</StyledIconButton>;
};

const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.primaryPalette.navyBlue};
  color: ${({ theme }) => theme.primaryPalette.white} !important;
  border-radius: 4px;
  padding: 8px;
`;

SquareIconButton.propTypes = {
  children: PropTypes.node,
};

SquareIconButton.defaultProps = {
  children: null,
};

export default SquareIconButton;
