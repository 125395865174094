import PropTypes from 'prop-types';
import { Checkbox } from 'cfa-react-components';

const CheckboxList = ({ id, idx, selectedLocations, setSelectedLocations }) => {
  return (
    <Checkbox
      checked={selectedLocations.includes(id)}
      key={idx}
      label={id}
      onChange={() => {
        if (selectedLocations.includes(id)) {
          setSelectedLocations(
            selectedLocations.filter(location => location !== id),
          );
        } else {
          setSelectedLocations([...selectedLocations, id]);
        }
      }}
    />
  );
};

CheckboxList.propTypes = {
  id: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  selectedLocations: PropTypes.array.isRequired,
  setSelectedLocations: PropTypes.func.isRequired,
};

export default CheckboxList;
