import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageTitle from 'components/PageTitle/PageTitle';
import BreadcrumbLinks from 'components/BreadcrumbLinks/BreadcrumbLinks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setHeader } from 'store/header/slice';

const PageHeader = ({
  title,
  breadcrumbs,
  header,
  className,
  'data-testid': DataTestId,
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (header !== '') {
      dispatch(setHeader(header));
    }
  }, [dispatch, header]);

  return (
    <PageHeaderContainer className={className} data-testid={DataTestId}>
      <PageHeaderLeft>
        {breadcrumbs && <BreadcrumbLinks breadcrumbs={breadcrumbs} />}
        {title && <PageHeaderTitle>{title}</PageHeaderTitle>}
      </PageHeaderLeft>
      <PageHeaderRight>{children}</PageHeaderRight>
    </PageHeaderContainer>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ),
  header: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  children: PropTypes.node,
};

PageHeader.defaultProps = {
  title: '',
  breadcrumbs: [],
  header: '',
  className: '',
  'data-testid': 'PageHeader',
  children: null,
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 24px;
`;

const PageHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PageHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PageHeaderTitle = styled(PageTitle)`
  margin-top: 8px;
`;
export default PageHeader;
