import Constants from 'constants/index';
import { getCurrentLanguage } from 'i18n/language';

export const getNameFromLanguage = (name, selected) => {
  if (selected && selected === Constants.LANGUAGE_OPTIONS.ENGLISH) {
    return name?.en;
  } else if (selected && selected === Constants.LANGUAGE_OPTIONS.SPANISH) {
    // Not all names have a Spanish counterpart so we return English in those cases
    if (name?.es === undefined) {
      return name?.en;
    } else {
      return name?.es;
    }
  } else if (selected === Constants.ADMIN_MANAGING_COUNTRIES.PR.LABEL_KEY) {
    // Not all names have an English counterpart so we return Spanish in those cases
    if (name?.en === undefined) {
      return name?.es;
    } else {
      return name?.en;
    }
  } else {
    return getCurrentLanguage() !== Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE &&
      name?.es
      ? name?.es
      : name?.en;
  }
};

export const getLanguageCode = (
  language = Constants.LANGUAGE_OPTIONS.ENGLISH,
) => {
  switch (language) {
    case Constants.LANGUAGE_OPTIONS.ESPANOL:
    case Constants.LANGUAGE_OPTIONS.SPANISH:
      return Constants.LANGUAGE.SPANISH_LANGUAGE_CODE;
    default:
      return Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
  }
};

export const pluralize = (quantity, singular, plural) =>
  quantity === 1 ? `${singular}` : `${plural}`;
