import PropTypes from 'prop-types';

import { PopoverMenuContent } from 'cfa-react-components';
import FabPopupItem from './FabPopupItem';

const FabPopup = ({ children, ...props }) => {
  return <PopoverMenuContent {...props}>{children}</PopoverMenuContent>;
};

FabPopup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FabPopup.Item = FabPopupItem;

export default FabPopup;
