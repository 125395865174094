import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'sideBar',
  initialState: {
    toggleCollapsed: false,
    linkClicked: '',
  },
  reducers: {
    toggleSideBar: state => {
      state.toggleCollapsed = !state.toggleCollapsed;
    },
    setLinkClicked: (state, action) => {
      state.linkClicked = action.payload;
    },
    setSidebarOpen: state => {
      state.toggleCollapsed = false;
    },
    setSidebarCollapsed: state => {
      state.toggleCollapsed = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLinkClicked,
  setSidebarOpen,
  setSidebarCollapsed,
  toggleSideBar,
} = slice.actions;

export default slice.reducer;
