import { device } from 'util/device';
import { Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CategorySection = ({
  section,
  'data-testid': dataTestId,
  className,
  children,
}) => {
  return (
    <CategorySectionContainer className={className} data-testid={dataTestId}>
      <CardSectionText variant="overline1">{section}</CardSectionText>
      <CategoryCardGroup>{children}</CategoryCardGroup>
    </CategorySectionContainer>
  );
};

CategorySection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  section: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};

CategorySection.defaultProps = {
  className: '',
  children: null,
  'data-testid': '',
};

const CategorySectionContainer = styled.div`
  margin: 32px 0;
`;

const CardSectionText = styled(Typography)`
  margin-bottom: 8px;
`;

const CategoryCardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  @media (${device.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export default CategorySection;
