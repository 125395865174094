import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion, AccordionItem, Divider } from 'cfa-react-components';

const SubCategoryAccordion = ({
  name,
  'data-testid': DataTestId,
  className,
  children,
}) => {
  return (
    <SectionAccordion
      className={className}
      data-testid={DataTestId}
      elevation={1}
      iconPosition="end"
      variant="surface"
    >
      <SectionAccordionItem header={name} itemKey="0">
        <AccordianDivider />
        <AccordianContent>{children}</AccordianContent>
      </SectionAccordionItem>
    </SectionAccordion>
  );
};

SubCategoryAccordion.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SubCategoryAccordion.defaultProps = {
  className: '',
  'data-testid': '',
  children: null,
};

const SectionAccordion = styled(Accordion)`
  margin: 16px 0;
`;

const AccordianContent = styled.div`
  margin-top: 4px;
`;
const SectionAccordionItem = styled(AccordionItem)`
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.grayScale.gray2}`} !important;
`;

const AccordianDivider = styled(Divider)`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
`;

export default SubCategoryAccordion;
