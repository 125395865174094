import { createSelector } from 'reselect';

export const quizzesState = state => state.quizzes;

export const selectPaginatedQuizzes = createSelector(
  quizzesState,
  state => state.paginatedQuizzes,
);

export const selectPagination = createSelector(quizzesState, state => ({
  showing: state.showing,
  total: state.total,
}));
