import PropTypes from 'prop-types';
import { MessageBlock, MessageBlockContent } from 'cfa-react-components';
import toast from 'react-hot-toast';

const ToastMessageBlock = ({ children, id, severity }) => {
  return (
    <MessageBlock onClose={() => toast.remove(id)} severity={severity}>
      <MessageBlockContent>{children}</MessageBlockContent>
    </MessageBlock>
  );
};

ToastMessageBlock.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
};

ToastMessageBlock.defaultProps = {
  severity: 'success',
};

export default ToastMessageBlock;
