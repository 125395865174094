import Constants from 'constants/index';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'cfa-react-components';
import { Link } from 'react-router-dom';

const SubCategoryDocument = ({
  id,
  name,
  icon,
  openInNewTab,
  rightSideContent,
  'data-testid': DataTestId,
  className,
}) => {
  return (
    <>
      <Link
        className={className}
        data-testid={id || DataTestId}
        rel={openInNewTab ? 'noopener noreferrer' : ''}
        target={openInNewTab ? '_blank' : '_self'}
        to={`/doc/${id}`}
      >
        <DocumentContainer>
          <DocumentContent>
            <DocumentIcon
              alt="Document Icon"
              src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${icon}.svg`}
            />
            <Typography variant="body1">{name}</Typography>
          </DocumentContent>
          {rightSideContent}
        </DocumentContainer>
      </Link>
    </>
  );
};

// document.type
// {documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
//   <StyledVideoSearchResultIcon />
// ) : (
//   const StyledVideoSearchResultIcon = styled(VideoSearchResult)`
//   margin-right: 10px;
// `;

SubCategoryDocument.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  openInNewTab: PropTypes.bool,
  rightSideContent: PropTypes.node,
};

SubCategoryDocument.defaultProps = {
  className: '',
  'data-testid': '',
  icon: '',
  openInNewTab: false,
  rightSideContent: null,
};

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const DocumentContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  text-align: left;
`;
const DocumentIcon = styled.img`
  width: 30px;
  margin-right: 12px;
  border-radius: 0;
`;
export default SubCategoryDocument;
