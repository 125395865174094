import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  IconButton,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuToggleButton,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { IconDots } from '@tabler/icons-react';

const PopoverMenuButton = ({
  children,
  className,
  'data-testid': dataTestId,
  handleClick,
  size,
  text,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  return (
    <PopoverMenu className={className} data-testid={dataTestId}>
      <PopoverContainer
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick && handleClick();
        }}
      >
        {text ? (
          <PopoverMenuToggleButton
            as={Button}
            color="secondary"
            size={size}
            style={{ width: '100%' }}
            variant="outlined"
          >
            {text}
          </PopoverMenuToggleButton>
        ) : (
          <PopoverMenuToggleButton as={IconButton}>
            <IconDots />
          </PopoverMenuToggleButton>
        )}
      </PopoverContainer>
      <PopoverMenuContent
        style={{ width: isSmAndDown ? '100%' : 'auto', padding: '0 16px' }}
      >
        {children}
      </PopoverMenuContent>
    </PopoverMenu>
  );
};

const PopoverContainer = styled.div`
  position: relative;
`;

PopoverMenuButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  handleClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PopoverMenuButton.defaultProps = {
  children: null,
  className: '',
  'data-testid': 'PopoverMenuButton',
  handleClick: () => {},
  size: 'lg',
  text: null,
};

export default PopoverMenuButton;
