import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import {
  mapCategoryToAliasTranslation,
  mapCategoryToColor,
  mapCategoryToIcon,
} from 'util/categoryUtils';
import { filterObject } from 'util/filterObject';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'sharedComponents/app/ProgressBar';
import { Link, useHistory } from 'react-router-dom';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import PropTypes from 'prop-types';
import {
  useAssignUserToChecklistMutation,
  useGetAssignedCoursesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import { useSelector } from 'react-redux';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import ManageTranslationsPopup from 'containers/TrainingPlans/ManageTranslationsModal/ManageTranslations';
import AddTeamMembersPopUp from 'sharedComponents/app/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import uniqBy from 'lodash/uniqBy';
import DueDatePopUp from 'sharedComponents/app/popups/DueDatePopUp';
import {
  Card,
  CardContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import InputDialog from 'components/Dialogs/InputDialog';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconBuilding,
  IconCopy,
  IconEdit,
  IconPrinter,
  IconTrash,
  IconUserPlus,
  IconWorld,
} from '@tabler/icons-react';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import toast from 'react-hot-toast';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';

export const PlanCard = ({
  assignedTeamMembers,
  canModifyPlan,
  completedTeamMembers,
  completionDate,
  isBuildingPlan,
  isComplete,
  isCompliance,
  isManagePlan,
  isMyPlan,
  locations,
  onDeletePlan,
  onDuplicatePlan,
  onPrintReport,
  onShowPopUp,
  onRenamePlan,
  onUpdateLocations,
  planDetails,
  refetch,
  selectedDueDate,
  selectedLocations,
  setSelectedLocations,
  stepsCompleted,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));
  const [showDeletePlanPopup, setShowDeletePlanPopup] = useState(false);
  const [showDuplicatePlanPopup, setShowDuplicatePlanPopup] = useState(false);
  const [showChooseLocationsPlanPopup, setShowChooseLocationsPlanPopup] =
    useState(false);
  const [showRenamePlanPopup, setShowRenamePlanPopup] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showManageTranslationsPopup, setShowManageTranslationsPopup] =
    useState(false);
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);
  const [isDuplicatePlan, setIsDuplicatePlan] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [assignedComplianceTeamMembers, setAssignedComplianceTeamMembers] =
    useState(0);
  const [temporaryLocations, setTemporaryLocations] = useState([]);
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const isManagePlanCompliance =
    isManagePlan && planDetails?.courseID !== undefined;
  const isManagePlanStore = isManagePlan && planDetails?.checklist;

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const { data: complianceStatus, isSuccess: isAssignedCoursesSuccess } =
    useGetAssignedCoursesQuery(
      {
        courseId: planDetails.id,
        location: locations,
      },
      { skip: isBuildingPlan || isMyPlan || !planDetails.id },
    );

  useEffect(() => {
    if (planDetails && setSelectedLocations) {
      setSelectedLocations(planDetails.locations);
      setTemporaryLocations(planDetails.locations);
    }
  }, [planDetails, setSelectedLocations]);

  useEffect(() => {
    if (isAssignedCoursesSuccess) {
      setAssignedComplianceTeamMembers(
        assignedTeamMembers -
          Object.keys(
            filterObject(complianceStatus.enrollments, 'hidden', false),
          ).length,
      );
    }
  }, [
    assignedTeamMembers,
    complianceStatus?.enrollments,
    isAssignedCoursesSuccess,
  ]);

  const planName =
    isCompliance || isManagePlanCompliance
      ? getNameFromLanguage(planDetails?.courseName)
      : isManagePlanStore
      ? getNameFromLanguage(planDetails?.checklist?.name)
      : getNameFromLanguage(planDetails?.name);

  const hideDuplicatePlanPopup = () => {
    setShowDuplicatePlanPopup(false);
  };

  const onShowRenamePlanPopup = () => {
    setShowRenamePlanPopup(true);
  };

  const onDeletePlanAndClosePopup = () => {
    onDeletePlan();
    setShowDeletePlanPopup(false);
  };

  const onRenamePlanAndClosePopup = inputValue => {
    onRenamePlan(inputValue);
    setShowRenamePlanPopup(false);
  };

  const onShowDeletePlanPopup = () => {
    setShowDeletePlanPopup(true);
  };

  const onDuplicatePlanAndClosePopup = inputValue => {
    onDuplicatePlan(planDetails?.id, inputValue, temporaryLocations);
    hideDuplicatePlanPopup();
  };

  const onSaveTranslations = updatedTranslations => {
    const payload = {
      locations: selectedLocations,
      checklist: {
        ...updatedTranslations,
      },
    };
    setShowManageTranslationsPopup(false);
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${Constants.LANGUAGE_OPTIONS.SPANISH}`}
          </ToastMessageBlock>
        ));
        refetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onShowAddTeamMembersPopup = () => {
    setShowAddTeamMembersPopup(true);
  };

  const onShowManageTranslationsPopup = () => {
    setShowManageTranslationsPopup(true);
  };

  const detailsPageUrl = isBuildingPlan
    ? `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${planDetails?.id}`
    : isCompliance
    ? `/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${planDetails?.pathwayCourseId}/${isCompliance}`
    : isManagePlanStore
    ? `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planDetails?.checklist?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`
    : isManagePlanCompliance
    ? `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planDetails?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}?compliancePlan=${planName}`
    : `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${planDetails?.id}`;

  if (detailsPageUrl.includes('undefined')) {
    console.error('Details page url does not include plan id');
  }

  const onAssignTeamMembers = (members, dueDate) => {
    const payload = Array.isArray(members)
      ? { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };

    const numberOfTeamMembersToAssign = payload.userIds.length;

    assignUserToChecklist({ body: payload, id: planDetails.id })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planDetails?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`,
          state: { refetch: true },
        });
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
    setShowAddTeamMembersPopup(false);
  };

  const handleNextClick = id => {
    setShowAddTeamMembersPopup(false);
    setShowDueDatePopup(true);
    setSelectedId(id);
  };

  const onDuplicateName = () => {
    setShowChooseLocationsPlanPopup(false);
    setShowDuplicatePlanPopup(true);
  };

  const onSave = dueDate => {
    setShowDueDatePopup(false);
    onAssignTeamMembers(
      selectedId.includes(',') ? selectedId.split(',') : selectedId,
      dueDate,
    );
  };

  const getTimeString = time => {
    const timeSpentHrs = Math.floor(time / 60);
    const timeSpentMins = time % 60;
    return `${
      timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
    } ${timeSpentMins} ${t('Generic.mins')}`;
  };

  const handleDuplicatePlan = () => {
    setIsDuplicatePlan(true);
    if (locationsWithAtLeastTrainer.length > 1) {
      setShowChooseLocationsPlanPopup(true);
    } else {
      setShowDuplicatePlanPopup(true);
    }
  };

  return (
    <>
      {
        <Link
          data-testid="TeamPlanCard"
          // We do this for compliance plans that are disabled
          onClick={event =>
            isManagePlanCompliance &&
            !planDetails?.enabled &&
            event.preventDefault()
          }
          to={detailsPageUrl}
        >
          <ProgressPlanCard
            $isDisabled={isManagePlan && isCompliance && !planDetails?.enabled}
            data-testid={isCompliance ? 'MyCompliancePlan' : 'MyPlan'}
            elevation={1}
          >
            <ProgressCardImageWrapper
              $backgroundColor={mapCategoryToColor(
                isManagePlanStore
                  ? planDetails?.checklist?.category
                  : planDetails?.courseName
                  ? Constants.PLAN_CATEGORIES.COMPLIANCE
                  : planDetails?.category,
                isManagePlan && planDetails?.enabled === false,
              )}
            >
              {mapCategoryToIcon(
                isManagePlanStore
                  ? planDetails?.checklist?.category
                  : planDetails?.courseName
                  ? Constants.PLAN_CATEGORIES.COMPLIANCE
                  : planDetails?.category,
              )}
            </ProgressCardImageWrapper>
            <ProgressCardContent>
              <StyledPlanCardLeft>
                <Typography variant="overline3">
                  {t(
                    mapCategoryToAliasTranslation(
                      isManagePlanStore
                        ? planDetails?.checklist?.category
                        : planDetails?.courseName
                        ? Constants.PLAN_CATEGORIES.COMPLIANCE
                        : planDetails?.category,
                    ),
                  )}
                </Typography>
                <StyledPlanName
                  data-testid="PlanName"
                  fontWeight="bold"
                  variant="body1"
                >
                  {planName}
                </StyledPlanName>
                {isBuildingPlan ? (
                  <Typography data-testid="PlanTaskCount" variant="body2">
                    {`${planDetails?.stepsTotal} ${t('Generic.items')}
                  ${
                    !!planDetails?.estimatedMinutes
                      ? ` · ${t('Generic.around')} ${getTimeString(
                          planDetails?.estimatedMinutes,
                        )} ${t('TrainingPlans.accountability.toComplete')}`
                      : ''
                  }`}
                  </Typography>
                ) : isManagePlan ? (
                  <ProgressBar
                    current={completedTeamMembers || 0}
                    isDisabled={
                      (isCompliance &&
                        !planDetails?.courseID &&
                        planDetails?.enabled) ||
                      (isManagePlan &&
                        planDetails?.courseID &&
                        !planDetails?.enabled)
                    }
                    text={
                      isComplete
                        ? `${t('Generic.planCompleted')}`
                        : `${completedTeamMembers ?? completedTeamMembers}/${
                            isAssignedCoursesSuccess
                              ? assignedComplianceTeamMembers
                              : assignedTeamMembers
                          } ${t('Generic.teamMembersCompleted')}`
                    }
                    total={assignedTeamMembers || 0}
                  />
                ) : (
                  <ProgressBar
                    completionDate={completionDate}
                    current={
                      isCompliance
                        ? planDetails?.percentComplete
                        : stepsCompleted
                        ? stepsCompleted
                        : planDetails?.stepsComplete
                    }
                    isComplete={isComplete}
                    isMyPlan={isMyPlan}
                    selectedDueDate={selectedDueDate}
                    showDate={true}
                    text={
                      isComplete
                        ? `${t('Generic.planCompleted')}`
                        : isCompliance
                        ? t('Generic.percentCompleted', {
                            percent: planDetails?.percentComplete,
                          })
                        : `${stepsCompleted ?? planDetails?.stepsComplete}/${
                            planDetails?.stepsTotal
                          } ${t('TrainingPlans.accountability.itemsCompleted')}`
                    }
                    total={isCompliance ? 100 : planDetails?.stepsTotal}
                  />
                )}
                {!isSmAndUp && (
                  <Typography variant="body2">
                    {new Date(
                      isManagePlanCompliance
                        ? planDetails?.createdDate
                        : planDetails?.checklist
                        ? planDetails?.checklist?.created
                        : planDetails?.created,
                    ).toLocaleDateString('en-US')}
                  </Typography>
                )}
              </StyledPlanCardLeft>
              {isBuildingPlan && (
                <StyledPlanCardRight>
                  {!!isSmAndUp && (
                    <Typography variant="body2">
                      {new Date(planDetails.created ?? '').toLocaleDateString(
                        'en-US',
                      )}
                    </Typography>
                  )}
                  <PopoverMenuButton>
                    <PopoverMenuItemButton
                      icon={<IconEdit />}
                      isVisible={canModifyPlan}
                      onClick={onShowRenamePlanPopup}
                      testId="ThreeDotMenuRenamePlanPopup"
                      text={t('TrainingPlans.manageThreeDotMenu.rename')}
                    />
                    <PopoverMenuItemButton
                      icon={<IconUserPlus />}
                      onClick={onShowAddTeamMembersPopup}
                      testId="ThreeDotMenuAssignPopup"
                      text={t('Generic.assign')}
                    />
                    {locationsWithAtLeastTrainer.length > 1 && (
                      <PopoverMenuItemButton
                        icon={<IconBuilding />}
                        onClick={() => setShowChooseLocationsPlanPopup(true)}
                        testId="ThreeDotMenuChooseLocationsPopup"
                        text={t(
                          'TrainingPlans.manageThreeDotMenu.chooseLocation',
                        )}
                      />
                    )}
                    <PopoverMenuItemButton
                      icon={<IconWorld />}
                      isVisible={canModifyPlan}
                      onClick={onShowManageTranslationsPopup}
                      testId="ThreeDotMenuManageTranslationsPopup"
                      text={t('Generic.editTranslations')}
                    />
                    <PopoverMenuItemButton
                      icon={<IconCopy />}
                      onClick={handleDuplicatePlan}
                      testId="ThreeDotMenuDuplicatePlanPopup"
                      text={t('TrainingPlans.manageThreeDotMenu.duplicate')}
                    />
                    <PopoverMenuItemButton
                      icon={<IconTrash data-testid="PlanDelete" />}
                      isDestructive={true}
                      isVisible={canModifyPlan}
                      onClick={onShowDeletePlanPopup}
                      testId="ThreeDotMenuDeletePlanPopup"
                      text={t('TrainingPlans.manageThreeDotMenu.delete')}
                    />
                  </PopoverMenuButton>
                </StyledPlanCardRight>
              )}
              {isManagePlan && (
                <StyledPlanCardRight>
                  {!!isSmAndUp && (
                    <Typography variant="body2">
                      {new Date(
                        isManagePlanCompliance
                          ? planDetails?.createdDate
                          : isManagePlanStore
                          ? planDetails?.checklist?.created
                          : planDetails.created,
                      ).toLocaleDateString('en-US')}
                    </Typography>
                  )}
                  {planDetails?.courseID && (
                    <PopoverMenuButton isDisabled={!planDetails?.enabled}>
                      {planDetails?.enabled ? (
                        <PopoverMenuItemButton
                          icon={<IconPrinter />}
                          onClick={() => onPrintReport(planDetails?.id)}
                          testId="ThreeDotMenuAssignPopup"
                          text={t('Button.printReport')}
                        />
                      ) : (
                        <>
                          <PopoverMenuItemButton
                            icon={<IconUserPlus />}
                            onClick={onShowPopUp}
                            text={t('Button.enablePlan')}
                          />
                          <PopoverMenuItemButton
                            icon={<IconPrinter />}
                            isDisabled={true}
                            onClick={() => onPrintReport(planDetails?.id)}
                            text={t('Button.printReport')}
                          />
                        </>
                      )}
                    </PopoverMenuButton>
                  )}
                </StyledPlanCardRight>
              )}
            </ProgressCardContent>
          </ProgressPlanCard>
        </Link>
      }
      <ConfirmationModal
        bodyText={t('TrainingPlans.deletePlanTextNew', {
          planName: planName ?? '',
        })}
        headerText={t('TrainingPlans.manageThreeDotMenu.delete')}
        isOpen={showDeletePlanPopup}
        onClose={() => setShowDeletePlanPopup(false)}
        primaryButtonHandler={onDeletePlanAndClosePopup}
        primaryButtonText={t('Button.yesDelete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={() => setShowDeletePlanPopup(false)}
        secondaryButtonText={t('Button.cancel')}
      />
      <InputDialog
        headerText={t('TrainingPlans.changePlanName')}
        isOpen={showRenamePlanPopup}
        onClose={() => setShowRenamePlanPopup(false)}
        primaryButtonHandler={onRenamePlanAndClosePopup}
        primaryButtonText={t('Button.update')}
        secondaryButtonHandler={() => setShowRenamePlanPopup(false)}
        secondaryButtonText={t('Button.cancel')}
        textInputDefaultValue={planName}
        textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
      />
      <AddTeamMembersPopUp
        handleNextClick={({ id }) => handleNextClick(id)}
        isOpen={showAddTeamMembersPopup}
        locations={arrayIntersect(
          locationsWithAtLeastTrainer,
          planDetails?.locations ?? [],
          [],
        )}
        onClose={() => setShowAddTeamMembersPopup(false)}
        planDetails={planDetails ?? {}}
        teamMemberQueryOptions={{ refetchOnMountOrArgChange: true }}
      />
      <ConfirmationModal
        children={
          temporaryLocations &&
          locationsWithAtLeastTrainer.map((id, idx) => (
            <CheckboxList
              id={id}
              idx={idx}
              key={idx}
              selectedLocations={temporaryLocations}
              setSelectedLocations={setTemporaryLocations}
            />
          ))
        }
        headerText={t('Generic.chooseLocation')}
        isDisabled={!temporaryLocations?.length}
        isOpen={showChooseLocationsPlanPopup}
        onClose={() => setShowChooseLocationsPlanPopup(false)}
        primaryButtonHandler={
          isDuplicatePlan
            ? onDuplicateName
            : () => onUpdateLocations(temporaryLocations)
        }
        primaryButtonText={
          isDuplicatePlan ? t('Button.next') : t('Button.save')
        }
        secondaryButtonHandler={() => setShowChooseLocationsPlanPopup(false)}
        secondaryButtonText={t('Button.cancel')}
      />
      <InputDialog
        headerText={t('TrainingPlans.givePlanName')}
        isDuplicatePlan={showDuplicatePlanPopup}
        isOpen={showDuplicatePlanPopup}
        onClose={() => setShowDuplicatePlanPopup(false)}
        primaryButtonHandler={onDuplicatePlanAndClosePopup}
        primaryButtonText={t('Button.duplicate')}
        secondaryButtonHandler={() => setShowDuplicatePlanPopup(false)}
        secondaryButtonText={t('Button.cancel')}
        textInputDefaultValue={
          planName + ` ${t('TrainingPlans.duplicatePlanInputCopy')}`
        }
        textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
      />
      <ManageTranslationsPopup
        isOpen={showManageTranslationsPopup}
        onClose={() => setShowManageTranslationsPopup(false)}
        onSave={onSaveTranslations}
        translatableObject={planDetails}
      />
      <DueDatePopUp
        isOpen={showDueDatePopup}
        onClose={() => setShowDueDatePopup(false)}
        onSave={dueDate => onSave(dueDate)}
      />
    </>
  );
};

const ProgressPlanCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  flex: 1 0;
  cursor: ${({ $isDisabled }) => $isDisabled && 'default !important'};
`;
const ProgressCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  max-height: 96px;
  height: auto;
  background: ${props => props.$backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
`;
const ProgressCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
  overflow: hidden;
`;
const StyledPlanCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ $isSmAndUp }) =>
    $isSmAndUp ? 'center' : 'flex-start'};
  flex-grow: 1;
  overflow: hidden;
  margin: 0 15px;
  overflow-wrap: break-word;
  max-width: 100%;
`;
const StyledPlanCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
`;
const StyledPlanName = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: break-word;
`;

PlanCard.propTypes = {
  assignedTeamMembers: PropTypes.number,
  canModifyPlan: PropTypes.bool,
  completedTeamMembers: PropTypes.number,
  completionDate: PropTypes.string,
  isBuildingPlan: PropTypes.bool,
  isComplete: PropTypes.bool,
  isCompliance: PropTypes.bool,
  isManagePlan: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  locations: PropTypes.array,
  onDeletePlan: PropTypes.func,
  onDuplicatePlan: PropTypes.func,
  onPrintReport: PropTypes.func,
  onRenamePlan: PropTypes.func,
  onShowPopUp: PropTypes.func,
  onUpdateLocations: PropTypes.func,
  planDetails: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  selectedDueDate: PropTypes.number,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func,
  stepsCompleted: PropTypes.number,
};

PlanCard.defaultProps = {
  assignedTeamMembers: 0,
  canModifyPlan: false,
  completedTeamMembers: 0,
  completionDate: null,
  isBuildingPlan: false,
  isComplete: false,
  isCompliance: false,
  isManagePlan: false,
  isMyPlan: false,
  locations: [],
  onDeletePlan: () => {},
  onDuplicatePlan: () => {},
  onPrintReport: () => {},
  onRenamePlan: () => {},
  onShowPopUp: () => {},
  onUpdateLocations: () => {},
  selectedDueDate: 0,
  selectedLocations: [],
  setSelectedLocations: () => {},
  stepsCompleted: 0,
};
