import Constants from 'constants/index';
import get from 'lodash/get';

export const getLaunchdDarklyUser = ({
  audience,
  email,
  fullName,
  locations,
  userType,
}) => {
  return {
    audience,
    email,
    key: email,
    kind: 'user',
    locations,
    name: fullName,
    userType,
  };
};

export const getUserInfo = (token, language, country) => {
  const {
    nickname,
    family_name,
    email,
    userType,
    cfa_loc_list,
    cfa_aud,
    cfa_perms,
    cfa_guid,
  } = {
    ...token,
  };
  const fullName = `${nickname} ${family_name}`;
  const firstName = `${nickname}`;
  const permissions = get(cfa_perms, Constants.APPLICATION_SYSTEM_TAG) ?? {};
  return {
    firstName,
    fullName,
    email,
    userType,
    locations: cfa_loc_list,
    audience: cfa_aud,
    permissions,
    userId: cfa_guid,
    language,
    country,
  };
};

export const getHighestUserPermission = (permissions = {}) => {
  if (Constants.USER_PERMISSIONS.ADMIN in permissions) {
    return Constants.USER_PERMISSION_NAMES.ADMIN;
  }
  if (Constants.USER_PERMISSIONS.STAKEHOLDER in permissions) {
    return Constants.USER_PERMISSION_NAMES.STAKEHOLDER;
  }
  if (Constants.USER_PERMISSIONS.OPERATOR in permissions) {
    return Constants.USER_PERMISSION_NAMES.OPERATOR;
  }
  if (Constants.USER_PERMISSIONS.LEADER in permissions) {
    return Constants.USER_PERMISSION_NAMES.LEADER;
  }
  if (Constants.USER_PERMISSIONS.TRAINER in permissions) {
    return Constants.USER_PERMISSION_NAMES.TRAINER;
  }
  return Constants.USER_PERMISSION_NAMES.LOGIN;
};
