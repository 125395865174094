import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'cfa-react-components';

const StickyMenuButton = ({
  text,
  icon,
  error,
  onClick,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <MenuButton
      $error={error}
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {icon}
      {text}
    </MenuButton>
  );
};

StickyMenuButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  error: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

StickyMenuButton.defaultProps = {
  error: false,
  className: '',
  'data-testid': 'StickyMenuItem',
};

const MenuButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  font-weight: 500;
  color: ${({ theme, $error }) =>
    $error ? theme.semanticColors.error : theme.primaryPalette.navyBlue};
`;

export default StickyMenuButton;
