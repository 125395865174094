import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { isApiError } from 'util/request';
import { generateTotalTime } from 'util/time';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import store from 'store';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import GenericError from 'sharedComponents/app/GenericError';
import {
  useGetAssignedChecklistsQuery,
  useUpdateChecklistStatusMutation,
  useUpdateChecklistStepStatusMutation,
} from 'services/pathwayApi';
import { useEffect, useState } from 'react';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllLocationsWithAtLeastTrainer,
  selectUser,
  selectUserId,
  selectUserLanguage,
} from 'store/user/selectors';
import { Button, Tooltip, Typography } from 'cfa-react-components';
import useTrainingPlanTimer from 'hooks/useTrainingPlanTimer';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import PlanCard from '../SharedComponents/PlanCard/PlanCard';
import PlanCardTask from '../SharedComponents/PlanCard/PlanCardTask';
import PlanCardProcedure from '../SharedComponents/PlanCard/PlanCardProcedure';
import PlanCardQuiz from '../SharedComponents/PlanCard/PlanCardQuiz';
import PlanCardBrightcoveVideo from '../SharedComponents/PlanCard/PlanCardBrightcoveVideo';
import LocationsDisplay from '../SharedComponents/LocationsDisplay';

const AssignedPlanView = () => {
  const { planId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const userId = useSelector(selectUserId);
  const userLanguage = useSelector(selectUserLanguage);
  const state = store.getState();
  const user = selectUser(state);
  const {
    data: plans,
    error,
    refetch: refetchPlan,
  } = useGetAssignedChecklistsQuery({}, { refetchOnMountOrArgChange: true });

  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();
  const [updateChecklistStatus] = useUpdateChecklistStatusMutation();
  const [plan, setPlan] = useState(undefined);
  const [activeTask, setActiveTask] = useState('');
  const [activeQuizStepId, setActiveQuizStepId] = useState('');
  const [users, setUsers] = useState([]);
  const [apiError, setApiError] = useState();

  useEffect(() => {
    if (plans) {
      setPlan(
        plans?.checklistResults?.find(
          tempPlan => tempPlan?.checklist?.id === planId,
        ),
      );
    }
  }, [plans, planId]);

  const timeSpentOnPlan =
    plan?.status
      ?.find(status => status?.userId === user.userId)
      ?.steps?.filter(
        filterStep => filterStep.status === Constants.TRAINING_PLANS.COMPLETE,
      )
      .reduce(
        (acc, step) => acc + Math.round((step?.stepDuration ?? 0) / 60),
        0,
      ) ?? 0;
  const timeSpentHrs = Math.floor(timeSpentOnPlan / 60);
  const timeSpentMins = timeSpentOnPlan % 60;

  const isStepCompleted = stepId => {
    if (!stepId) {
      return false;
    }
    return plan?.status
      .filter(status => status.userId === user.userId)
      .every(
        planAssigned =>
          planAssigned?.steps?.find(step => step.stepId === stepId)?.status ===
          Constants.TRAINING_PLANS.COMPLETE,
      );
  };

  const getStepStatus = status => {
    return status
      ? Constants.TRAINING_PLANS.COMPLETE
      : Constants.TRAINING_PLANS.INCOMPLETE;
  };

  useEffect(() => {
    if (plan) {
      dispatch(setHeader(getNameFromLanguage(plan?.checklist?.name)));
    }
  }, [plan, dispatch]);

  useEffect(() => {
    if (plan?.checklist?.category) {
      dispatch(
        setHeaderLabel(
          t(mapCategoryToAliasTranslation(plan?.checklist?.category)),
        ),
      );
      return () => {
        dispatch(setHeaderLabel(''));
      };
    }
  }, [plan, dispatch, t]);

  useEffect(() => {
    if (plan?.checklist?.estimatedMinutes) {
      dispatch(
        setHeaderSubtext(
          `${t(
            'TrainingPlans.accountability.estimatedTimeToComplete',
          )}: ${generateTotalTime(
            plan.checklist.estimatedMinutes,
            t('Generic.hour'),
            t('Generic.mins'),
          )}`,
        ),
      );
    }
    return () => {
      dispatch(setHeaderSubtext(''));
    };
  }, [plan, dispatch, t]);

  const [startTaskTimer, stopTaskTimer] = useTrainingPlanTimer(
    planId,
    activeTask,
    userId,
    getStepStatus(isStepCompleted(activeTask)),
  );

  const [startQuizTimer, stopQuizTimer] = useTrainingPlanTimer(
    planId,
    activeQuizStepId,
    userId,
    getStepStatus(isStepCompleted(activeQuizStepId)),
  );

  useEffect(() => {
    if (activeQuizStepId !== '' && isStepCompleted(activeQuizStepId)) {
      setActiveQuizStepId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId, plan]);

  // timer for tasks
  useEffect(() => {
    if (activeTask !== '') {
      startTaskTimer();
    } else {
      stopTaskTimer();
    }
    return () => {
      stopTaskTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTask]);

  // quiz timer
  useEffect(() => {
    if (activeQuizStepId !== '') {
      startQuizTimer();
    } else {
      stopQuizTimer();
    }
    return () => {
      stopQuizTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId]);

  useEffect(() => {
    setUsers([user.userId]);
  }, [user]);

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const isPlanComplete =
    plan?.status?.[0]?.status === Constants.TRAINING_PLANS.COMPLETED;

  const handleSetActiveTask = step => {
    activeTask === step.id ? setActiveTask('') : setActiveTask(step.id);
  };

  const handleSetActiveQuiz = step => {
    activeQuizStepId === step.id
      ? setActiveQuizStepId('')
      : setActiveQuizStepId(step.id);
  };

  const handleSetActiveVideo = step => {
    activeTask === step.id ? setActiveTask('') : setActiveTask(step.id);
  };

  const onCardClick = step => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/assigned/toComplete`,
      state: {
        step,
        planId,
        stepStatus: getStepStatus(isStepCompleted(step.id)),
        isViewingFromTrainingPlan: true,
      },
    });
  };

  const onOvalIconToggle = id => {
    const isCompleted = !isStepCompleted(id);
    const stepStatus = getStepStatus(isCompleted);
    if (!isPlanComplete) {
      if (id === activeTask) {
        setActiveTask('');
      }
      updateChecklistStepStatus({
        checklistId: planId,
        stepId: id,
        status: stepStatus,
      })
        .unwrap()
        .then(() => {
          refetchPlan();
        })
        .catch(err => {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        });
    }
  };

  const onCompleteButtonClick = planName => {
    updateChecklistStatus({
      checklist: planId,
      status: Constants.TRAINING_PLANS.COMPLETED,
    })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
          state: { completedPlan: getNameFromLanguage(planName) },
        });
      })
      .catch(err => {
        setApiError(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const renderCompleteButton = planName => (
    <StyledButtonWrapper>
      <Button
        color={'secondary'}
        data-testid="CompleteButton"
        disabled={isPlanComplete || areAllStepsComplete() ? false : true}
        onClick={() => onCompleteButtonClick(planName)}
        variant="filled"
      >
        {t('Button.complete')}
      </Button>
    </StyledButtonWrapper>
  );

  const areAllStepsComplete = () => {
    const stepsFromChecklist = plan?.checklist.sections
      .flatMap(section => section?.steps)
      .concat(plan?.checklist?.steps);
    const stepsFromStatus = plan?.status?.filter(
      status => status.userId === user.userId,
    )?.[0]?.steps;
    const matchedSteps = stepsFromStatus?.filter(step =>
      stepsFromChecklist.map(it => it.id).includes(step.stepId),
    );

    return (
      matchedSteps?.every(
        step => step.status === Constants.TRAINING_PLANS.COMPLETE,
      ) && matchedSteps.length === stepsFromChecklist.length
    );
  };

  return (
    <>
      {plan && (
        <>
          {locationsWithAtLeastTrainer.length > 1 && (
            <LocationsDisplay
              locations={
                arrayIntersect(
                  plan.checklist.locations,
                  locationsWithAtLeastTrainer,
                ) ?? []
              }
            />
          )}
          <AssignedPlanHeader>
            <TimeSpentContainer>
              <TypeHeader variant="overline3">
                {isPlanComplete
                  ? t('TrainingPlans.accountability.planCompletedIn')
                  : t('TrainingPlans.accountability.timeSpentOnPlan')}
              </TypeHeader>
              <Tooltip
                content={t('TrainingPlans.timeSpentOnPlanTooltip')}
                placement="bottom"
                showOnElementEvents={['hover']}
              >
                <TimeSpentText>{` ${
                  timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
                } ${timeSpentMins} ${t('Generic.mins')}`}</TimeSpentText>
              </Tooltip>
            </TimeSpentContainer>
          </AssignedPlanHeader>
        </>
      )}
      {plan?.checklist?.steps.map((step, index) => {
        switch (step?.type) {
          case Constants.STEP_TYPES.QUIZ:
            return (
              <PlanCard key={index}>
                <PlanCardQuiz
                  isActive={activeQuizStepId === step.id}
                  isComplete={isStepCompleted(step.id)}
                  language={userLanguage}
                  onClick={() => handleSetActiveQuiz(step)}
                  onRefetch={refetchPlan}
                  planId={plan?.checklist?.id}
                  statuses={plan?.status}
                  step={step}
                  users={users}
                />
              </PlanCard>
            );
          case Constants.STEP_TYPES.TASK:
            return (
              <PlanCard key={index}>
                <PlanCardTask
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id)}
                  onClick={() => handleSetActiveTask(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id)}
                  statuses={plan?.status}
                  step={step}
                  user={user}
                />
              </PlanCard>
            );
          case Constants.STEP_TYPES.DOCUMENT:
            return step?.reference?.format === Constants.STEP_FORMATS.VIDEO ? (
              <PlanCard key={index}>
                <PlanCardBrightcoveVideo
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id)}
                  language={userLanguage}
                  onClick={() => handleSetActiveVideo(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id)}
                  planId={plan?.checklist?.id}
                  statuses={plan?.status}
                  step={step}
                  users={users}
                />
              </PlanCard>
            ) : (
              <PlanCard key={index}>
                <PlanCardProcedure
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id)}
                  isMissing={!step?.available}
                  onClick={() => onCardClick(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id)}
                  statuses={plan?.status}
                  step={step}
                  user={user}
                />
              </PlanCard>
            );
          default:
            return null;
        }
      })}
      {plan?.checklist?.sections.map(section => {
        return (
          !!section.steps.length && (
            <PlanCard
              disabled
              key={section?.id}
              sectionName={getNameFromLanguage(section.name)}
            >
              {section.steps.map((step, stepIndex) => {
                switch (step?.type) {
                  case Constants.STEP_TYPES.QUIZ:
                    return (
                      <PlanCardQuiz
                        isActive={activeQuizStepId === step.id}
                        isComplete={isStepCompleted(step.id)}
                        key={stepIndex}
                        language={userLanguage}
                        onClick={() => handleSetActiveQuiz(step)}
                        onRefetch={refetchPlan}
                        planId={plan?.checklist?.id}
                        statuses={plan?.status}
                        step={step}
                        users={users}
                      />
                    );
                  case Constants.STEP_TYPES.TASK:
                    return (
                      <PlanCardTask
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id)}
                        key={stepIndex}
                        onClick={() => handleSetActiveTask(step)}
                        onCompleteToggle={() => onOvalIconToggle(step.id)}
                        statuses={plan?.status}
                        step={step}
                        user={user}
                      />
                    );
                  case Constants.STEP_TYPES.DOCUMENT:
                    return step?.reference?.format ===
                      Constants.STEP_FORMATS.VIDEO ? (
                      <PlanCardBrightcoveVideo
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id)}
                        key={stepIndex}
                        language={userLanguage}
                        onClick={() => handleSetActiveVideo(step)}
                        onCompleteToggle={() => onOvalIconToggle(step.id)}
                        planId={plan?.checklist?.id}
                        statuses={plan?.status}
                        step={step}
                        users={users}
                      />
                    ) : (
                      <PlanCardProcedure
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id)}
                        isMissing={!step?.available}
                        key={stepIndex}
                        onClick={() => onCardClick(step)}
                        onCompleteToggle={() => onOvalIconToggle(step.id)}
                        statuses={plan?.status}
                        step={step}
                        user={user}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </PlanCard>
          )
        );
      })}
      {plan?.checklist && renderCompleteButton(plan.checklist.name)}
      <ConfirmationModal
        bodyText={t('GenericError.problemCompletingAction')}
        headerText={t('GenericError.errorHeader')}
        isOpen={!!apiError}
        onClose={() => setApiError(null)}
        primaryButtonColor="primary"
        primaryButtonHandler={() => setApiError(null)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`;

const AssignedPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px 0;
`;

const TimeSpentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;
const TypeHeader = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;
const TimeSpentText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export default AssignedPlanView;
