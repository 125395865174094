import Constants from 'constants';
import styled from 'styled-components';
import { useState } from 'react';
import 'react-smartbanner/dist/main.css';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import PropTypes from 'prop-types';
import { useEditCategoriesMutation } from 'services/pathwayApi';
import { Button, Icon, TextField, Typography } from 'cfa-react-components';
import theme from 'styles/theme';
import { IconCornerDownRight, IconPlus } from '@tabler/icons-react';
import AddIcon from './AddIcon';

export const AddCategory = ({
  categoriesArrayLength,
  categoryToEdit,
  isCanada,
  isRecommended,
  isSubcategory,
  onSubcategoryAdd,
  onCancel,
  onClose,
  onSubcategoryCancel,
  selectedCountry,
}) => {
  const [editCategories] = useEditCategoriesMutation();
  const [category, setCategory] = useState({
    icon: categoryToEdit?.icon ?? '',
    id: categoryToEdit?.id ?? '',
    name: {
      en: categoryToEdit?.name?.en ?? '',
      es: categoryToEdit?.name?.es ?? '',
    },
    subcategories: [
      {
        name: {
          en: '',
          es: '',
        },
      },
    ],
  });
  const [showAddIconPopUp, setShowAddIconPopUp] = useState(false);
  const { t } = useTranslation();

  const onNameChange = e => {
    const name = e.target.value;
    if (name.length) {
      setCategory({
        ...category,
        name: { en: name, es: category.name?.es ?? '' },
      });
    } else {
      setCategory({
        ...category,
        name: {
          en: '',
          es: category.name?.es ?? '',
        },
      });
    }
  };

  const onTranslationChange = e => {
    const translation = e.target.value;
    if (translation.length) {
      setCategory({
        ...category,
        name: { en: category.name?.en, es: translation },
      });
    } else {
      setCategory({
        ...category,
        name: {
          en: category.name?.en ?? '',
          es: '',
        },
      });
    }
  };

  const onSubcategoryNameChange = e => {
    const name = e.target.value;
    if (name.length) {
      setCategory({
        ...category,
        subcategories: [
          {
            name: { en: name, es: category?.subcategories?.[0].name?.es ?? '' },
          },
        ],
      });
    } else {
      setCategory({
        ...category,
        subcategories: [
          {
            name: {
              en: '',
              es: category?.subcategories?.[0].name?.es ?? '',
            },
          },
        ],
      });
    }
  };

  const onSubcategoryTranslationChange = e => {
    const translation = e.target.value;
    if (translation.length) {
      setCategory({
        ...category,
        subcategories: [
          {
            name: {
              en: category?.subcategories?.[0].name?.en ?? '',
              es: translation,
            },
          },
        ],
      });
    } else {
      setCategory({
        ...category,
        subcategories: [
          {
            name: {
              en: category?.subcategories?.[0].name?.en ?? '',
              es: '',
            },
          },
        ],
      });
    }
  };

  const onAddCategory = () => {
    const trimmedName = category.name?.en.trim();
    const trimmedTranslation = category.name?.es.trim();
    const payload = [
      {
        country: selectedCountry,
        displayIndex: categoriesArrayLength,
        enabled: false,
        featured: isRecommended ? true : false,
        icon: category.icon,
        name: {
          en: trimmedName,
          es: trimmedTranslation,
        },
      },
    ];
    editCategories(payload)
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onAddIcon = icon => {
    setCategory({ ...category, icon });
    setShowAddIconPopUp(false);
  };

  return (
    <StyledRow>
      <StyledNameRow>
        {!isSubcategory && (
          <StyledAddIconWrapper>
            <StyledAddIcon
              data-testid="AdminAddIconWrapper"
              fontWeight="medium"
              variant="body2"
            >
              {t('Admin.addIcon')}
              <StyledAsterisk>*</StyledAsterisk>
            </StyledAddIcon>
            {category && category.icon ? (
              <StyledIcon
                data-testid="Icon"
                onClick={() => setShowAddIconPopUp(true)}
                src={`${Constants.PATHWAY_CDN_IMG.CATEGORIES}${category.icon}.svg`}
              />
            ) : (
              <Icon
                cursor="pointer"
                data-testid={'AddIcon'}
                icon={StyledIconPlus}
                onClick={() => setShowAddIconPopUp(true)}
                size="md"
              />
            )}
          </StyledAddIconWrapper>
        )}
        <StyledTranslationColumn>
          <TextField
            data-testid={'AddCategoryNameInput'}
            fullWidth
            label={
              isSubcategory
                ? t('Admin.subcategory')
                : isRecommended
                ? t('Admin.recommendedCategory')
                : t('Admin.category')
            }
            onChange={isSubcategory ? onSubcategoryNameChange : onNameChange}
            placeholder={'Enter Name'}
            required={true}
            value={
              isSubcategory
                ? category.subcategories?.[0]?.name.en
                : category.name.en
            }
          />
          <StyledTranslationRow>
            <IconCornerDownRight size={35} />
            <StyledTranslationWrapper>
              <TextField
                data-testid={'AddCategoryTranslationInput'}
                fullWidth
                label={t('Admin.spanishTranslation')}
                onChange={
                  isSubcategory
                    ? onSubcategoryTranslationChange
                    : onTranslationChange
                }
                placeholder={'Enter Translation'}
                required={!isCanada}
                value={
                  isSubcategory
                    ? category.subcategories?.[0]?.name.es
                    : category.name.es
                }
              />
            </StyledTranslationWrapper>
          </StyledTranslationRow>
        </StyledTranslationColumn>
      </StyledNameRow>

      <StyledButtonRow>
        <StyledButtonWrapper>
          <StyledCancelButton
            color="secondary"
            onClick={isSubcategory ? onSubcategoryCancel : onCancel}
            variant="outlined"
          >
            {t('Button.cancel')}
          </StyledCancelButton>
          <StyledAddButton
            color="secondary"
            data-testid="AddButton"
            disabled={
              isSubcategory && isCanada
                ? category?.subcategories?.[0]?.name?.en?.trim().length <= 0
                : isSubcategory
                ? category?.subcategories?.[0]?.name?.en?.trim().length <= 0 ||
                  category?.subcategories?.[0]?.name?.es?.trim().length <= 0
                : isCanada
                ? category.name.en.trim().length <= 0 ||
                  category.icon.trim().length <= 0
                : category.name.en.trim().length <= 0 ||
                  category.name.es.trim().length <= 0 ||
                  category.icon.trim().length <= 0
            }
            onClick={
              isSubcategory
                ? () =>
                    onSubcategoryAdd({
                      ...category,
                      subcategories: [
                        {
                          enabled: false,
                          name: {
                            en: category?.subcategories?.[0]?.name?.en,
                            es: category?.subcategories?.[0]?.name?.es,
                          },
                          subtitles: [],
                        },
                      ],
                    })
                : onAddCategory
            }
          >
            {t('Button.add')}
          </StyledAddButton>
        </StyledButtonWrapper>
      </StyledButtonRow>
      {showAddIconPopUp && (
        <AddIcon
          onAddIcon={icon => onAddIcon(icon)}
          onClose={() => setShowAddIconPopUp(false)}
        />
      )}
    </StyledRow>
  );
};

AddCategory.propTypes = {
  categoriesArrayLength: PropTypes.number,
  categoryToEdit: PropTypes.object,
  isCanada: PropTypes.bool,
  isRecommended: PropTypes.bool,
  isSubcategory: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSubcategoryAdd: PropTypes.func,
  onSubcategoryCancel: PropTypes.func,
  selectedCountry: PropTypes.string,
};

AddCategory.defaultProps = {
  categoriesArrayLength: 0,
  categoryToEdit: {
    icon: '',
    id: '',
    name: {
      en: '',
      es: '',
    },
  },
  isCanada: false,
  isRecommended: false,
  isSubcategory: false,
  onCancel: null,
  onClose: null,
  onSubcategoryAdd: null,
  onSubcategoryCancel: null,
  selectedCountry: '',
};

const StyledRow = styled.div`
  border: ${() => `1px solid ${theme.grayScale.gray2}`};
  border-radius: 4px;
  box-shadow: ${() => `${theme.boxShadow.elevation5}`};
  padding: 1em;
  margin-bottom: 1em;
`;

const StyledTranslationColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const StyledIconPlus = styled(IconPlus)`
  width: 40px;
  height: 40px;
`;

const StyledAddIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  justify-content: flex-start;
  align-items: center;
`;

const StyledNameRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTranslationRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0 0;
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const StyledAddIcon = styled(Typography)`
  text-wrap: nowrap;
  margin-bottom: 8px;
`;

const StyledAsterisk = styled.span`
  color: ${() => theme.semanticColors.error};
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 1em;
`;

const StyledAddButton = styled(Button)``;

const StyledCancelButton = styled(Button)`
  margin-right: 0.5em;
`;

const StyledTranslationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export default AddCategory;
