import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const GoogleAnalyticsContext = createContext();

export const GoogleAnalyticsProvider = ({ measurementId, children }) => {
  const [gtag, setGtag] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.prepend(script);
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function _gtag() {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', measurementId);
      setGtag(
        () =>
          (...args) =>
            window.gtag(...args),
      );
    }
  }, [measurementId]);

  return (
    <GoogleAnalyticsContext.Provider value={gtag}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

GoogleAnalyticsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  measurementId: PropTypes.string.isRequired,
};
