import Constants from 'constants/index';
import { isCurrentPath } from 'util/url';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddProcedurePopUp from 'sharedComponents/app/popups/AddProcedurePopUp';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import {
  useAssignUserToChecklistMutation,
  useDeleteTrainingPlanMutation,
  useDuplicateTrainingPlanMutation,
  useGetTrainingPlanQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import {
  IconBuilding,
  IconNewSection,
  IconNotes,
  IconCircleCheck,
  IconFileCertificate,
  IconEdit,
  IconUserPlus,
  IconWorld,
  IconCopy,
  IconTrash,
  IconGripVertical,
} from '@tabler/icons-react';
import {
  selectAllLocationsWithAtLeastTrainer,
  selectUserLanguage,
} from 'store/user/selectors';
import { setHeader } from 'store/header/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PlanItems from 'containers/TrainingPlans/BuildPlans/EditablePlanItems/PlanItems';
import ManageTranslationsPopup from 'containers/TrainingPlans/ManageTranslationsModal/ManageTranslations';
import AddTeamMembersPopUp from 'sharedComponents/app/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import uniqBy from 'lodash/uniqBy';
import { withRoles } from 'sharedComponents/app/withRoles';
import { Fab } from 'sharedComponents/app/Fab/Fab';
import DueDatePopUp from 'sharedComponents/app/popups/DueDatePopUp';
import StickyMenuCard from 'components/StickyMenuCard/StickyMenuCard';
import StickyMenuButton from 'components/StickyMenuCard/StickyMenuButton';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  Dropdown,
  TextField,
  useBreakpoints,
  useMediaQuery,
  Typography,
  Surface,
  Card,
  Button,
} from 'cfa-react-components';
import InputDialog from 'components/Dialogs/InputDialog';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import theme from 'styles/theme';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import LocationsDisplay from '../SharedComponents/LocationsDisplay';
import QuizLibraryPopUp from '../../../sharedComponents/app/popups/QuizLibraryPopUp';
import QuizPreviewPopUp from '../../../sharedComponents/app/popups/QuizPreviewPopUp';
import { AddTask } from './EditablePlanItems/AddTask';
import EditButtons from './EditablePlanItems/EditButtons';

const EditPlanView = () => {
  const refContainerEnd = useRef();
  const location = useLocation();
  const { planId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const addSectionRef = useRef();
  const addTaskRef = useRef();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const userLanguage = useSelector(selectUserLanguage);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const [showAddSection, setShowAddSection] = useState(false);
  const [showAddProcedure, setShowAddProcedure] = useState(false);
  const [showAddQuiz, setShowAddQuiz] = useState(false);
  const [isInSection, setIsInSection] = useState(false);
  const [isDuplicatePlan, setIsDuplicatePlan] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showDeletePlanPopup, setShowDeletePlanPopup] = useState(false);
  const [showAddPlanItemsMenu, setShowAddPlanItemsMenu] = useState(false);
  const [showRenamePlanPopup, setShowRenamePlanPopup] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showDuplicatePlanPopup, setShowDuplicatePlanPopup] = useState(false);
  const [showChooseLocationsPopup, setShowChooseLocationsPopup] =
    useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [showManageTranslationsPopup, setShowManageTranslationsPopup] =
    useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);
  const [sectionId, setSectionId] = useState('');
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [deleteTrainingPlan] = useDeleteTrainingPlanMutation();
  const [duplicateTrainingPlan] = useDuplicateTrainingPlanMutation();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const [addSectionText, setAddSectionText] = useState('');
  const [isDrop, setIsDrop] = useState(false);
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const [quizForPreview, setQuizForPreview] = useState(null);
  const [showAddQuizPreview, setShowAddQuizPreview] = useState(false);
  const { buildQuizzes: featureFlagBuildQuizzes } = useFlags();
  const [estimatedMinutes, setEstimatedMinutes] = useState('');
  const [temporaryLocations, setTemporaryLocations] = useState([]);

  const {
    data: plan,
    isFetching,
    error,
    refetch,
  } = useGetTrainingPlanQuery({
    id: planId,
  });

  useEffect(() => {
    if (plan) {
      setEstimatedMinutes(plan?.estimatedMinutes ?? '');
    }
  }, [plan]);

  const onSaveEstimatedMinutes = () => {
    if (plan) {
      const payload = {
        locations: selectedLocations,
        checklist: {
          ...plan,
          estimatedMinutes: parseInt(estimatedMinutes),
        },
      };
      updateTrainingPlan(payload)
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch(err => {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        });
    }
  };

  const planName = getNameFromLanguage(plan?.name);

  const isValidPlanName = (name, locations) => {
    if (!name || !locations.length) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing locations');
      Bugsnag.notify(
        new Error(
          `Error in training plan: ${JSON.stringify({
            planName,
            locations,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(setHeader(planName));
  }, [dispatch, planName]);

  useEffect(() => {
    if (plan) {
      setSelectedLocations(plan.locations);
      setTemporaryLocations(plan.locations);
    }
  }, [plan, setSelectedLocations]);

  const onRenamePlan = newName => {
    const trimmedName = newName?.trim();
    if (!isValidPlanName(trimmedName, selectedLocations)) {
      return;
    }
    const payload = {
      locations: selectedLocations,
      checklist: {
        ...plan,
        name: {
          ...plan.name,
          [userLanguage]: trimmedName,
        },
      },
    };
    setShowRenamePlanPopup(false);
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.renamed')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onRenamePlanCancel = () => {
    setShowRenamePlanPopup(false);
  };

  const onSaveTranslations = updatedTranslations => {
    const payload = {
      locations: selectedLocations,
      checklist: {
        ...updatedTranslations,
      },
    };
    setShowManageTranslationsPopup(false);
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${
              userLanguage === Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE
                ? Constants.LANGUAGE_OPTIONS.SPANISH
                : Constants.LANGUAGE_OPTIONS.ENGLISH
            }`}
          </ToastMessageBlock>
        ));
        refetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const isTrainingPlanPath = isCurrentPath({
    location,
    isTrainingPlanPath: true,
  });
  const onDeletePlanCancel = () => {
    setShowDeletePlanPopup(false);
  };
  const onShowAddSection = () => {
    setShowAddSection(true);
    setShowAddPlanItemsMenu(false);
  };
  const onShowAddTask = () => {
    setShowAddTask(true);
    setShowAddPlanItemsMenu(false);
  };
  const onShowAddProcedure = () => {
    setShowAddProcedure(true);
    setShowAddPlanItemsMenu(false);
  };
  const onShowAddQuizToPlanOutsideSection = () => {
    setSectionId('');
    setShowAddQuiz(true);
    setShowAddPlanItemsMenu(false);
  };

  const onDeletePlan = () => {
    deleteTrainingPlan(planId)
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
          state: { deletedPlan: planName },
        });
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onDuplicatePlan = name => {
    const locations = temporaryLocations;
    const trimmedName = name?.trim();
    if (!isValidPlanName(trimmedName, locations)) {
      return;
    }
    duplicateTrainingPlan({
      locations,
      checklistId: planId,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
          state: { duplicatedPlan: trimmedName },
        });
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onShowDeletePlanPopup = () => {
    setShowDeletePlanPopup(true);
  };

  const onShowRenamePlanPopup = () => {
    setShowRenamePlanPopup(true);
  };

  const onShowAddTeamMembersPopup = () => {
    setShowAddTeamMembersPopup(true);
  };

  const onShowManageTranslationsPopup = () => {
    setShowManageTranslationsPopup(true);
  };

  useEffect(() => {
    if ((showAddSection || showAddTask) && refContainerEnd?.current) {
      refContainerEnd.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showAddSection, showAddTask, refContainerEnd]);

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onChangeCategory = category => {
    const payload = {
      locations: selectedLocations,
      checklist: {
        ...plan,
        category,
      },
    };
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onAddSection = () => {
    const payload = {
      locations: selectedLocations,
      checklist: {
        ...plan,
        sections: [
          ...plan.sections,
          {
            name: { en: addSectionText, es: addSectionText },
          },
        ],
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        setAddSectionText('');
        setShowRenamePlanPopup(false);
        setShowAddSection(false);
        refetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const renderAddSection = () => {
    return (
      <>
        <TextField
          aria-label={t('TrainingPlans.searchbarPlaceholder')}
          fullWidth
          maxLength={Constants.FORMS.MAX_SECTION_LENGTH}
          onChange={e => setAddSectionText(e.target.value)}
          onKeyDown={e =>
            e.key === 'Enter' &&
            addSectionText?.trim().length > 0 &&
            onAddSection(e.key)
          }
          placeholder={t('TrainingPlans.searchbarPlaceholder')}
          value={addSectionText}
        />
        {addSectionText.length > 0 && (
          <StyledCharacterCountWrapper>
            <StyledCharacterCount>{`${addSectionText.length}/${Constants.FORMS.MAX_SECTION_LENGTH}`}</StyledCharacterCount>
          </StyledCharacterCountWrapper>
        )}
      </>
    );
  };
  const renderAddTask = () => (
    <Surface data-testid="StyledCardBody" ref={addTaskRef}>
      <AddTask
        isInSection={false}
        plan={plan}
        refetch={refetch}
        sectionId={sectionId}
        setShowAddTask={setShowAddTask}
      />
    </Surface>
  );

  const onCancelSection = () => {
    setAddSectionText('');
    setShowAddSection(false);
  };

  const onAddProcedure = id => {
    setIsInSection(true);
    setSectionId(id);
    setShowAddProcedure(true);
  };

  const onAddQuizToSection = id => {
    setIsInSection(true);
    setSectionId(id);
    setShowAddQuiz(true);
  };

  const onCloseProcedurePopUp = () => {
    setIsInSection(false);
    setShowAddProcedure(false);
  };

  const onQuizPreviewBack = () => {
    setQuizForPreview(null);
    setShowAddQuizPreview(false);
    setShowAddQuiz(true);
  };

  const onCloseQuizPopUp = ({ withPreview, quiz } = {}) => {
    if (withPreview) {
      setQuizForPreview(quiz);
      setShowAddQuizPreview(true);
    }
    setIsInSection(false);
    setShowAddQuiz(false);
  };

  const onAssignTeamMembers = (members, dueDate) => {
    const payload = Array.isArray(members)
      ? { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };

    const numberOfTeamMembersToAssign = payload.userIds.length;

    assignUserToChecklist({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${plan.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`,
          state: { refetch: true },
        });
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
    setShowAddTeamMembersPopup(false);
  };

  const handleNextClick = id => {
    setShowAddTeamMembersPopup(false);
    setShowDueDatePopup(true);
    setSelectedId(id);
  };

  const onSave = dueDate => {
    setShowDueDatePopup(false);
    onAssignTeamMembers(
      selectedId.includes(',') ? selectedId.split(',') : selectedId,
      dueDate,
    );
  };

  const onDuplicateName = () => {
    setShowChooseLocationsPopup(false);
    setShowDuplicatePlanPopup(true);
  };

  const handleDuplicatePlan = () => {
    setIsDuplicatePlan(true);
    if (locationsWithAtLeastTrainer.length > 1) {
      setShowChooseLocationsPopup(true);
    } else {
      setShowDuplicatePlanPopup(true);
    }
  };

  const handleCancelChooseLocation = () => {
    setIsDuplicatePlan(false);
    setShowChooseLocationsPopup(false);
  };

  const onUpdateLocations = newLocations => {
    const payload = {
      locations: newLocations,
      checklist: {
        ...plan,
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        setShowChooseLocationsPopup(false);
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  return (
    <>
      {isTrainingPlanPath && (
        <>
          <ConfirmationModal
            bodyText={t('TrainingPlans.deletePlanTextNew', {
              planName: planName ?? '',
            })}
            headerText={t('TrainingPlans.manageThreeDotMenu.delete')}
            isOpen={showDeletePlanPopup}
            onClose={() => setShowDeletePlanPopup(false)}
            primaryButtonHandler={onDeletePlan}
            primaryButtonText={t('Button.yesDelete')}
            primaryButtonVariant="destructive"
            secondaryButtonHandler={onDeletePlanCancel}
            secondaryButtonText={t('Button.cancel')}
          />
          <InputDialog
            headerText={t('TrainingPlans.changePlanName')}
            isOpen={showRenamePlanPopup}
            onClose={onRenamePlanCancel}
            primaryButtonHandler={onRenamePlan}
            primaryButtonText={t('Button.update')}
            secondaryButtonHandler={onRenamePlanCancel}
            secondaryButtonText={t('Button.cancel')}
            textInputDefaultValue={planName}
            textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
          />
          <AddTeamMembersPopUp
            handleNextClick={({ id }) => handleNextClick(id)}
            isOpen={showAddTeamMembersPopup}
            locations={plan?.locations ?? []}
            onClose={() => setShowAddTeamMembersPopup(false)}
            planDetails={plan ?? {}}
            teamMemberQueryOptions={{ refetchOnMountOrArgChange: true }}
          />
          <AddProcedurePopUp
            isInSection={isInSection}
            isOpen={showAddProcedure}
            onClose={onCloseProcedurePopUp}
            plan={plan ?? {}}
            refetch={refetch}
            sectionId={sectionId}
          />
          <QuizLibraryPopUp
            isOpen={showAddQuiz}
            onClose={onCloseQuizPopUp}
            refetch={refetch}
            sectionId={sectionId}
            trainingPlan={plan}
          />
          <QuizPreviewPopUp
            handleQuizBackButton={onQuizPreviewBack}
            isOpen={showAddQuizPreview}
            onClose={() => setShowAddQuizPreview(false)}
            quizToPreview={quizForPreview}
            refetch={refetch}
            sectionId={sectionId}
            trainingPlan={plan}
          />
          <ConfirmationModal
            children={locationsWithAtLeastTrainer.map((id, idx) => (
              <CheckboxList
                id={id}
                idx={idx}
                key={idx}
                selectedLocations={temporaryLocations}
                setSelectedLocations={setTemporaryLocations}
              />
            ))}
            headerText={t('Generic.chooseLocation')}
            isDisabled={!temporaryLocations?.length}
            isOpen={showChooseLocationsPopup}
            onClose={handleCancelChooseLocation}
            primaryButtonHandler={
              isDuplicatePlan
                ? onDuplicateName
                : () => onUpdateLocations(temporaryLocations, plan)
            }
            primaryButtonText={
              isDuplicatePlan ? t('Button.next') : t('Button.save')
            }
            secondaryButtonHandler={handleCancelChooseLocation}
            secondaryButtonText={t('Button.cancel')}
          />
          <InputDialog
            headerText={t('TrainingPlans.givePlanName')}
            isDuplicatePlan={true}
            isOpen={showDuplicatePlanPopup}
            onClose={() => setShowDuplicatePlanPopup(false)}
            primaryButtonHandler={onDuplicatePlan}
            primaryButtonText={t('Button.duplicate')}
            secondaryButtonHandler={() => setShowDuplicatePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
            textInputDefaultValue={
              planName + ` ${t('TrainingPlans.duplicatePlanInputCopy')}`
            }
            textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
          />
          <ManageTranslationsPopup
            isOpen={showManageTranslationsPopup}
            onClose={() => setShowManageTranslationsPopup(false)}
            onSave={onSaveTranslations}
            translatableObject={plan}
          />
          <DueDatePopUp
            isOpen={showDueDatePopup}
            onClose={() => setShowDueDatePopup(false)}
            onSave={dueDate => onSave(dueDate)}
          />
        </>
      )}
      {plan?.locations && locationsWithAtLeastTrainer && (
        <LocationsDisplay
          locations={
            arrayIntersect(plan.locations, locationsWithAtLeastTrainer) ?? []
          }
        />
      )}
      <EditPlanViewContainer>
        <ContainerPlanItems>
          <LoadingOverlay isOpen={isFetching && !isDrop} />
          {!!isSmAndDown && (
            <PopoverMenuButton text={t('TrainingPlans.managePlan')}>
              <PopoverMenuItemButton
                icon={<IconEdit />}
                onClick={onShowRenamePlanPopup}
                testId="ThreeDotMenuPlanPopupRename"
                text={t('TrainingPlans.manageThreeDotMenu.rename')}
              />
              <PopoverMenuItemButton
                icon={<IconUserPlus />}
                onClick={onShowAddTeamMembersPopup}
                testId="ThreeDotMenuAssignPopup"
                text={t('Generic.assign')}
              />
              {locationsWithAtLeastTrainer.length > 1 && (
                <PopoverMenuItemButton
                  icon={<IconBuilding />}
                  onClick={() => setShowChooseLocationsPopup(true)}
                  testId="ThreeDotMenuPlanPopupChooseLocation"
                  text={t('TrainingPlans.manageThreeDotMenu.chooseLocation')}
                />
              )}
              <PopoverMenuItemButton
                icon={<IconWorld />}
                onClick={onShowManageTranslationsPopup}
                testId="ThreeDotMenuManageTranslationsPopup"
                text={t('Generic.editTranslations')}
              />
              <PopoverMenuItemButton
                icon={<IconCopy />}
                onClick={handleDuplicatePlan}
                testId="duplicatePlanMenuItem"
                text={t('TrainingPlans.manageThreeDotMenu.duplicate')}
              />
              <PopoverMenuItemButton
                icon={<IconTrash />}
                isDestructive={true}
                onClick={onShowDeletePlanPopup}
                testId="deletePlanMenuItem"
                text={t('TrainingPlans.manageThreeDotMenu.delete')}
              />
            </PopoverMenuButton>
          )}
          {plan && (
            <CategoryDropdown
              getOptionId={option => option.id}
              getOptionText={option => `${t(option.alias)}`}
              label={t('TrainingPlans.selectCategory')}
              onChange={newVal =>
                onChangeCategory(
                  newVal?.id ?? Constants.PLAN_CATEGORIES.DEFAULT,
                )
              }
              options={Constants.ALL_PLAN_CATEGORY_OBJECTS}
              renderOption={option => `${t(option.alias)}`}
              required
              value={Constants.ALL_PLAN_CATEGORY_OBJECTS.find(
                categoryObj => categoryObj.id === plan?.category,
              )}
            />
          )}
          {plan && (
            <PlanItems
              addProcedure={id => onAddProcedure(id)}
              addQuiz={id => onAddQuizToSection(id)}
              immutablePlan={plan}
              refetch={refetch}
              setIsDrop={setIsDrop}
            />
          )}
          {showAddSection && (
            <SectionCard ref={addSectionRef}>
              <StyledAddSectionContainer>
                <StyledSixDotIcon color={theme.grayScale.gray3} />
                <StyledAddSection>
                  {renderAddSection()}
                  <EditButtons
                    onCancel={onCancelSection}
                    onEdit={onAddSection}
                    primaryButtonText={t('Button.add')}
                    validated={addSectionText.trim().length > 0}
                  />
                </StyledAddSection>
              </StyledAddSectionContainer>
            </SectionCard>
          )}
          {showAddTask && renderAddTask()}

          <div ref={refContainerEnd} />
          {!plan?.sections?.length && !plan?.steps?.length && (
            <Typography variant="body1">
              {t('TrainingPlans.noSections')}
            </Typography>
          )}
          {plan && (
            <EstimatedTimeInput>
              <TimeTextField
                helperText={t(
                  'TrainingPlans.accountability.timeEstimationTooltip',
                )}
                label={t(
                  'TrainingPlans.accountability.estimatedTimeToComplete',
                )}
                onChange={e => setEstimatedMinutes(e.target.value)}
                placeholder={t('TrainingPlans.accountability.enterTime')}
                value={estimatedMinutes}
              />
              <EstimatedTimeSaveButton
                color="secondary"
                disabled={
                  estimatedMinutes?.length <= 0 ||
                  estimatedMinutes === plan?.estimatedMinutes
                }
                onClick={onSaveEstimatedMinutes}
              >
                {t('Button.save')}
              </EstimatedTimeSaveButton>
            </EstimatedTimeInput>
          )}
        </ContainerPlanItems>
        {!isSmAndDown && (
          <StickyMenuCard header={t('TrainingPlans.managePlan')} top="24px">
            <StickyMenuButton
              icon={<IconEdit />}
              onClick={onShowRenamePlanPopup}
              testId="ThreeDotMenuPlanPopupRename"
              text={t('TrainingPlans.manageThreeDotMenu.rename')}
            />
            <StickyMenuButton
              icon={<IconUserPlus />}
              onClick={onShowAddTeamMembersPopup}
              testId="ThreeDotMenuAssignPopup"
              text={t('Generic.assign')}
            />
            {locationsWithAtLeastTrainer.length > 1 && (
              <StickyMenuButton
                icon={<IconBuilding />}
                onClick={() => setShowChooseLocationsPopup(true)}
                testId="ThreeDotMenuChooseLocation"
                text={t('TrainingPlans.manageThreeDotMenu.chooseLocation')}
              />
            )}
            <StickyMenuButton
              icon={<IconWorld />}
              onClick={onShowManageTranslationsPopup}
              testId="ThreeDotMenuManageTranslationsPopup"
              text={t('Generic.editTranslations')}
            />
            <StickyMenuButton
              icon={<IconCopy />}
              onClick={handleDuplicatePlan}
              testId="duplicatePlanMenuItem"
              text={t('TrainingPlans.manageThreeDotMenu.duplicate')}
            />
            <StickyMenuButton
              error
              icon={<IconTrash />}
              onClick={onShowDeletePlanPopup}
              testId="deletePlanMenuItem"
              text={t('TrainingPlans.manageThreeDotMenu.delete')}
            />
          </StickyMenuCard>
        )}
      </EditPlanViewContainer>

      <Fab
        hasMenu={true}
        isOpen={showAddPlanItemsMenu}
        onClick={() => setShowAddPlanItemsMenu(!showAddPlanItemsMenu)}
        onClose={() => setShowAddPlanItemsMenu(false)}
      >
        <Fab.Popup data-testid="AddPlanItemsMenu">
          <Fab.Popup.Item
            icon={<IconNotes />}
            onClick={onShowAddProcedure}
            title={t('Generic.resource')}
          />
          <Fab.Popup.Item
            data-testid="AddTask"
            icon={<IconCircleCheck />}
            onClick={onShowAddTask}
            title={t('Generic.task')}
          />
          {featureFlagBuildQuizzes && (
            <Fab.Popup.Item
              data-testid="AddQuiz"
              icon={<IconFileCertificate />}
              onClick={onShowAddQuizToPlanOutsideSection}
              title={t('Generic.quiz')}
            />
          )}
          <Fab.Popup.Item
            icon={<IconNewSection />}
            onClick={onShowAddSection}
            title={t('Generic.section')}
          />
        </Fab.Popup>
      </Fab>
      <ConfirmationModal
        bodyText={t('InvalidPlanName.paragraphText')}
        headerText={t('InvalidPlanName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

const SectionCard = styled(Card)`
  padding: 16px;
  position: relative;
  margin-bottom: 16px;
`;

const EstimatedTimeSaveButton = styled(Button)`
  margin-top: 8px;
`;
const EstimatedTimeInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const CategoryDropdown = styled(Dropdown)`
  margin: 16px 0;
`;

const TimeTextField = styled(TextField)`
  margin: 16px 0;
`;

const EditPlanViewContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const ContainerPlanItems = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
`;
const StyledAddSectionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.5em;
`;
const StyledSixDotIcon = styled(IconGripVertical)`
  transform: translate(-0.25em, 0.5em);
`;
const StyledAddSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCharacterCountWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: -20px;
  right: 35px;
`;
const StyledCharacterCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  font-style: italic;
  color: ${props => props.theme.grayScale.gray6};
  font-weight: 400;
`;

export default withRoles(EditPlanView, [
  Constants.USER_PERMISSIONS.LEADER,
  Constants.USER_PERMISSIONS.OPERATOR,
]);
