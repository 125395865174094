import Constants from 'constants/index';
import { getCountryFromApiCountry } from 'util/international';
import { persistor } from 'store';
import oktaAuth from 'okta/oktaConfig';
import { formatBugsnagErrorMessage } from 'bugsnag';
import Bugsnag from '@bugsnag/js';

let chosenLanguage = Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
let chosenCountry = Constants.SUPPORTED_COUNTRIES.US;

function getCurrentLanguage() {
  return chosenLanguage;
}

function initializeLanguage() {
  if (Constants.IS_IN_JEST_TEST) {
    chosenLanguage = Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
  } else {
    persistor.flush();
    let locallyStoredLangPref = '';
    try {
      locallyStoredLangPref = JSON.parse(
        JSON.parse(window.localStorage.getItem('persist:root')).user,
      ).language;
    } catch (err) {}
    if (locallyStoredLangPref) {
      chosenLanguage = locallyStoredLangPref;
    } else {
      oktaAuth.authStateManager.subscribe(authState => {
        fetch(`${Constants.PATHWAY_API.BASE_URL}/users/preferences`, {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        })
          .then(data => data.json())
          .then(userPrefs => {
            if (userPrefs?.language) {
              chosenLanguage = userPrefs?.language;
            }
            if (userPrefs?.country) {
              chosenCountry = getCountryFromApiCountry(userPrefs?.country);
            }
            if (
              !userPrefs?.language &&
              chosenCountry === Constants.SUPPORTED_COUNTRIES.PR
            ) {
              chosenLanguage = Constants.LANGUAGE.SPANISH_LANGUAGE_CODE;
            }
          })
          .catch(err => {
            Bugsnag.notify(formatBugsnagErrorMessage(err));
          });
      });
    }
  }
}

export { initializeLanguage, getCurrentLanguage };
