import styled from 'styled-components';
import { IconPrinter } from '@tabler/icons-react';
import { Button } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showPrintReport } from 'store/printReport/slice';
import { showTrainingMode } from 'store/trainingMode/slice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetAssignedStatusQuery } from 'services/pathwayApi';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';

const ManageTeamMembersButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const planId = location.pathname.split('/')?.[2];
  const { data: assignedTeamMemebers } = useGetAssignedStatusQuery({
    checklist: planId,
    location: locationsWithAtLeastTrainer,
  });
  const hasTeamMembersAssigned = assignedTeamMemebers?.status?.length > 0;

  return (
    <TrainingModeWrapper>
      <Button
        color="secondary"
        disabled={!hasTeamMembersAssigned}
        onClick={() => dispatch(showTrainingMode())}
      >
        {t('Button.trainingMode')}
      </Button>
      <IconPrinter onClick={() => dispatch(showPrintReport())} />
    </TrainingModeWrapper>
  );
};

const TrainingModeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
`;

export default ManageTeamMembersButtons;
